import React from 'react';

import { AttachFile, Upload } from '@mui/icons-material';

interface FileButtonProps {
  isRFQFileProcessingEnabled: boolean;
  disabled: boolean;
  openNewFileUploadModal: () => void;
  embedded?: boolean;
}

const FileButton: React.FC<FileButtonProps> = ({
  isRFQFileProcessingEnabled,
  disabled: aiThinking,
  openNewFileUploadModal,
  embedded = true
}) => {
  return (
    <button
      className={`flex items-center gap-1 px-3 py-1.5 mx-1 font-500 rounded-full text-black hover:text-black focus:ring-2 focus:ring-gray-400 transition-all duration-300 ease-in-out hover:scale-110 active:scale-95 cursor-pointer
            disabled:bg-gray-400 disabled:cursor-not-allowed disabled:text-white disabled:hover:scale-100 disabled:hover:bg-gray-400
            ${embedded ? 'bg-[#E5E7EB] hover:bg-[#cccdcf]' : 'bg-p-cavela-blue text-white hover:bg-blue-700 hover:text-white'}`}
      title={
        isRFQFileProcessingEnabled
          ? 'Add file as input to be processed'
          : 'Add file'
      }
      onClick={() => openNewFileUploadModal()}
      disabled={aiThinking}
    >
      {isRFQFileProcessingEnabled && (
        <>
          <Upload fontSize="small" />
          <span>Upload files</span>
        </>
      )}
      {!isRFQFileProcessingEnabled && <AttachFile />}
    </button>
  );
};

export default FileButton;
