import {
  addToast,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  useDisclosure
} from '@heroui/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { useRef } from 'react';

import { motion } from 'framer-motion';
import { useAuthStore } from 'src/entities/user/store/useAuthStore';
import { OrganizationAvatar } from 'src/shared/components';
import { Organization } from 'src/shared/types/user';

import { ButtonV2 } from 'src/shared/components/ui';

import { useUpdateOrganizationAvatar } from '../hooks/useUpdateOrganizationAvatar';

interface UpdateOrganizationAvatarProps {
  selectedOrganization: Organization | null;
}

const UpdateOrganizationAvatar: React.FC<UpdateOrganizationAvatarProps> = ({
  selectedOrganization
}) => {
  const queryClient = useQueryClient();
  const setSelectedOrganization = useAuthStore(
    (state) => state.setSelectedOrganization
  );
  const { isOpen, onOpenChange } = useDisclosure();
  const organizationAvatarMutation = useUpdateOrganizationAvatar();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const onRemoveAvatar = () => {
    // might be a delete endpoint for avatar? need to check backend
    organizationAvatarMutation.mutate(
      { avatar: null, id: selectedOrganization?.id },
      {
        onError: (error) => {
          addToast({
            color: 'danger',
            title: error?.message || 'Failed to remove organization avatar.'
          });
        },
        onSuccess: () => {
          setSelectedOrganization({
            ...(selectedOrganization as Organization)
          });
          onOpenChange();
          addToast({
            color: 'success',
            title: 'Organization avatar has been removed.'
          });
        }
      }
    );
  };

  const onChangeAvatar = (file: File) => {
    organizationAvatarMutation.mutate(
      { avatar: file, id: selectedOrganization?.id },
      {
        onError: (error) => {
          addToast({
            color: 'danger',
            title: error?.message || 'Failed to update organization avatar.'
          });
        },
        onSuccess: (data: Organization) => {
          queryClient.refetchQueries({ queryKey: ['user'] });
          setSelectedOrganization({
            ...(selectedOrganization as Organization),
            avatar: data.avatar
          });
          addToast({
            color: 'success',
            title: 'Organization avatar has been updated.'
          });
        }
      }
    );
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const isValidType = ['image/png', 'image/jpeg', 'image/jpg'].includes(
      file.type
    );
    if (!isValidType) {
      addToast({
        color: 'warning',
        title: 'Only PNG, JPEG, and JPG files are allowed.'
      });
      return;
    }

    onChangeAvatar(file);
  };

  return (
    <>
      <div className="flex max-w-fit relative">
        <OrganizationAvatar
          size="xl"
          name={selectedOrganization?.name as string}
          image={selectedOrganization?.avatar}
        />
        {organizationAvatarMutation.isPending ? (
          <div className="flex flex-col absolute justify-center items-center z-10 inset-0 bg-black/30 rounded-3xl">
            <Spinner color="white" />
          </div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            whileHover={{ opacity: 1 }}
            className="flex flex-col absolute inset-0 p-4 gap-2 z-10 bg-black/30 rounded-3xl"
          >
            <ButtonV2
              color="default"
              size="sm"
              onPress={() => fileInputRef.current?.click()}
            >
              Change
            </ButtonV2>
            <ButtonV2 onPress={onOpenChange} color="default" size="sm">
              Remove
            </ButtonV2>
          </motion.div>
        )}
      </div>

      <input
        ref={fileInputRef}
        type="file"
        accept=".png, .jpeg, .jpg"
        className="hidden"
        onChange={handleFileChange}
      />

      <Modal isOpen={isOpen} onOpenChange={onOpenChange} size="lg">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 p-5 border-b border-gray-4">
                Are you sure you want to remove this avatar?
              </ModalHeader>

              <ModalFooter className="justify-start border-t border-gray-4 p-5">
                <ButtonV2
                  size="sm"
                  color="default"
                  variant="flat"
                  onPress={onClose}
                  isDisabled={organizationAvatarMutation.isPending}
                >
                  No
                </ButtonV2>
                <ButtonV2
                  onPress={onRemoveAvatar}
                  size="sm"
                  color="dark"
                  isLoading={organizationAvatarMutation.isPending}
                  isDisabled={organizationAvatarMutation.isPending}
                >
                  Yes
                </ButtonV2>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpdateOrganizationAvatar;
