import { useState } from 'react';

const useLogic = ({
  removeFile,
  index
}: {
  removeFile: (index: number) => void;
  index: number;
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const startEditMode = () => {
    setIsEditing(true);
  };

  const removeValue = () => {
    removeFile(index);
    setIsEditing(false);
  };

  return {
    removeFile,
    isEditing,
    setIsEditing,
    startEditMode,
    removeValue
  };
};

export default useLogic;
