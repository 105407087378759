import { CheckIcon } from '@heroicons/react/20/solid';

import { Typography } from 'src/shared/components/ui';

const EmptyUpdates = () => {
  return (
    <div className="flex flex-1 flex-col justify-center items-center h-full">
      <div className="mb-4 size-20 bg-green-light-6 rounded-full flex justify-center items-center">
        <div className="size-8 bg-green-dark rounded-full flex justify-center items-center">
          <CheckIcon className="size-5 text-white" />
        </div>
      </div>
      <Typography variant="h6" weight="bold">
        No unresolved updates
      </Typography>
      <Typography
        variant="body-2"
        weight="medium"
        className="text-secondary-text"
      >
        There are no unresolved updates for this product
      </Typography>
    </div>
  );
};

export default EmptyUpdates;
