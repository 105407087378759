import apiService from 'src/shared/config/apiService';
import { Address } from 'src/shared/types/user';
import handleAxiosError from 'src/shared/utils/handleAxiosError';

export type UpdateOrganizationAddressPayload = {
  addresses: Address[];
  id: number;
};
type UpdateOrganizationAddressResponse = any;

export const updateOrganizationAddress = async (
  payload: UpdateOrganizationAddressPayload
): Promise<UpdateOrganizationAddressResponse> => {
  try {
    const { data } = await apiService.patch<UpdateOrganizationAddressResponse>(
      `/organizations/${payload.id}/`,
      { addresses: payload.addresses }
    );

    return data;
  } catch (error) {
    throw handleAxiosError(error);
  }
};
