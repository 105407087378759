import { Bars3Icon } from '@heroicons/react/16/solid';

import useMobileMenu from 'src/shared/store/useMobileMenu';

import { ButtonV2 } from 'src/shared/components/ui';

import UserMenu from '../UserMenu';

const PrivateMobileHeader = () => {
  const toggle = useMobileMenu((state) => state.toggle);

  return (
    <header className="fixed top-0 z-50 left-0 right-0 h-[72px] border-b border-gray-4 flex items-center justify-between px-4 bg-white">
      <ButtonV2 onPress={toggle} isIconOnly color="tertiary">
        <Bars3Icon className="size-4" />
      </ButtonV2>

      <UserMenu isMobile isOpen={false} />
    </header>
  );
};

export default PrivateMobileHeader;
