import { useQuery } from '@tanstack/react-query';

import { setUser as setSentryUser } from 'src/services/reporting';
import { Organization } from 'src/shared/types/user';

import { getUser } from '../api/getUser';
import { useAuthStore } from '../store/useAuthStore';

export const useUser = () => {
  const authToken = useAuthStore((state) => state.authToken);
  const setUser = useAuthStore((state) => state.setUser);
  const selectedOrganization = useAuthStore(
    (state) => state.selectedOrganization
  );
  const setSelectedOrganization = useAuthStore(
    (state) => state.setSelectedOrganization
  );
  const setFallbackToken = useAuthStore((state) => state.setFallBackToken);

  const query = useQuery({
    queryKey: ['user'],
    queryFn: async () => {
      const fetchedUser = await getUser();
      setUser(fetchedUser);
      setSentryUser({
        id: fetchedUser.id,
        email: fetchedUser.email,
        username: fetchedUser.username,
        impersonating: fetchedUser.impersonating
      });
      setFallbackToken(authToken);

      const selectedOrganizationIndex = fetchedUser.organizations?.findIndex(
        (org) => org.id === selectedOrganization?.id
      );

      // fix this, it doesnt update organization on stale user query

      if (fetchedUser.organizations?.length && !selectedOrganization) {
        if (selectedOrganizationIndex === -1) {
          console.log('LOOK FALLBACK', selectedOrganizationIndex);
          setSelectedOrganization(fetchedUser.organizations[0] as Organization);
        } else {
          console.log('LOOK CORRECT', selectedOrganizationIndex);
          setSelectedOrganization(
            fetchedUser.organizations[
              selectedOrganizationIndex as number
            ] as Organization
          );
        }
      }

      return fetchedUser;
    },
    enabled: !!authToken
  });

  return query;
};
