import { useNavigate } from 'react-router-dom';

import { Link } from 'src/pages/NewRfqFlow/props';

import SimpleModal from 'src/components/SimpleModal/SimpleModal';

import ProductInfo from '../ProductInfo/ProductInfo';

interface RFQFinalConfirmationStepProps {
  document: any;
  hasStartedFinalStep: boolean;
  extraQuestions: any;
  rfqId: number;
  rfqName: string;
  rfqLinks:
    | {
        [key: string]: Link[];
      }
    | any;
  rfqFiles: Array<any>;
  rfqAttributeFiles: Array<any>;
  updateFileItem: (id: number, data: any) => void;
  image: string;
  setImage: (image: string) => void;
  initialDocumentData: any;
  updateDocumentItem: (
    parentKey: string | null,
    key: string,
    value: any
  ) => void;
  aiThinking: boolean;
  removeDocumentItem: (parentKey: string | null, key: string) => void;
  isReview: boolean;
  updateLinkItem: (
    key: string,
    index: number,
    url: string,
    caption: string
  ) => void;
  error: boolean;
  setError: (error: string) => void;
  removeFileItem: (id: number) => void;
  setShowFinalScreen: (show: boolean) => void;
  setProductId: (id: string) => void;
  quantity: number;
  setQuantity: (quantity: number) => void;
  showRemoveFileModal: boolean;
  setShowRemoveFileModal: (show: boolean) => void;
  fileToRemove: number | undefined;
  setFileToRemove: (id: number) => void;
  showRemoveLinkModal: boolean;
  setShowRemoveLinkModal: (show: boolean) => void;
  savedRemoveLinkFunction: (() => void) | undefined;
  setSavedRemoveLinkFunction: (fn: () => void) => void;
  openFileUploadModal: () => void;
}

export default function RFQFinalConfirmationStep({
  document,
  hasStartedFinalStep,
  extraQuestions,
  rfqId,
  rfqName,
  rfqLinks,
  rfqFiles,
  rfqAttributeFiles,
  updateFileItem,
  image,
  setImage,
  initialDocumentData,
  updateDocumentItem,
  aiThinking,
  removeDocumentItem,
  isReview,
  updateLinkItem,
  error,
  setError,
  removeFileItem,
  setShowFinalScreen,
  setProductId,
  quantity,
  setQuantity,
  showRemoveFileModal,
  setShowRemoveFileModal,
  fileToRemove,
  setFileToRemove,
  showRemoveLinkModal,
  setShowRemoveLinkModal,
  savedRemoveLinkFunction,
  setSavedRemoveLinkFunction,
  openFileUploadModal
}: RFQFinalConfirmationStepProps) {
  return (
    <div className="bg-black w-screen h-screen absolute top-0 left-0 z-0 overflow-hidden">
      <div className="absolute inset-0 bg-transparent z-20"></div>
      <div className="absolute inset-0 z-30 overflow-auto flex items-center justify-center">
        <div className="w-full h-full flex items-center justify-center">
          <ProductInfo
            showRemoveFileModal={showRemoveFileModal}
            setShowRemoveFileModal={setShowRemoveFileModal}
            fileToRemove={fileToRemove}
            setFileToRemove={setFileToRemove}
            showRemoveLinkModal={showRemoveLinkModal}
            setShowRemoveLinkModal={setShowRemoveLinkModal}
            savedRemoveLinkFunction={savedRemoveLinkFunction}
            setSavedRemoveLinkFunction={setSavedRemoveLinkFunction}
            openFileUploadModal={openFileUploadModal}
            document={document}
            hasStarted={hasStartedFinalStep}
            designTransition={true}
            extraQuestions={extraQuestions}
            rfqId={rfqId}
            rfqName={rfqName}
            rfqLinks={rfqLinks}
            rfqFiles={rfqFiles}
            rfqAttributeFiles={rfqAttributeFiles}
            updateFileItem={updateFileItem}
            image={image}
            setImage={setImage}
            initialDocumentData={initialDocumentData}
            updateDocumentItem={updateDocumentItem}
            aiThinking={aiThinking}
            removeDocumentItem={removeDocumentItem}
            isReview={isReview}
            updateLinkItem={updateLinkItem}
            error={error}
            setError={setError}
            removeFileItem={removeFileItem}
            setShowFinalScreen={setShowFinalScreen}
            setProductId={setProductId}
            quantity={quantity}
            setQuantity={setQuantity}
          />
        </div>
      </div>
    </div>
  );
}
