import { addToast } from '@heroui/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';

import { User } from 'src/shared/types/user';

import {
  ButtonV2,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader
} from 'src/shared/components/ui';

import { useUpdateUserProfile } from '../hooks/useUpdateUserProfile';
import { useUpdateUserProfileForm } from '../hooks/useUpdateUserProfileForm';

interface AccountFormModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  user: User | null;
}

const AccountFormModal: React.FC<AccountFormModalProps> = ({
  isOpen,
  onOpenChange,
  user
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useUpdateUserProfileForm({
    email: user?.email,
    firstName: user?.firstName,
    lastName: user?.lastName,
    username: user?.username
  });

  const queryClient = useQueryClient();
  const userProfileMutation = useUpdateUserProfile();

  useEffect(() => {
    if (user) {
      reset({
        email: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
        username: user?.username
      });
    }
  }, [user, reset]);

  const onSubmit = (data: any) => {
    userProfileMutation.mutate(
      { ...data, id: user?.id },
      {
        onSuccess: () => {
          onOpenChange(false);
          queryClient.refetchQueries({ queryKey: ['user'] });
          addToast({
            color: 'success',
            title: 'User profile has been successfully updated.'
          });
        }
      }
    );
  };

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1 p-5 border-b border-gray-4">
              Edit account
            </ModalHeader>
            <ModalBody className="p-5">
              <form
                className="flex flex-col gap-5"
                id="user-profile-form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Input
                  label="First name"
                  errorMessage={errors.firstName?.message}
                  isInvalid={!!errors.firstName}
                  {...register('firstName')}
                />

                <Input
                  label="Last name"
                  errorMessage={errors.lastName?.message}
                  isInvalid={!!errors.lastName}
                  {...register('lastName')}
                />
                <Input
                  label="Email"
                  errorMessage={errors.email?.message}
                  isInvalid={!!errors.email}
                  {...register('email')}
                />
                <Input
                  label="Username"
                  errorMessage={errors.username?.message}
                  isInvalid={!!errors.username}
                  {...register('username')}
                />
              </form>
            </ModalBody>
            <ModalFooter className="justify-start border-t border-gray-4 p-5">
              <ButtonV2
                form="user-profile-form"
                type="submit"
                size="sm"
                color="dark"
                isLoading={userProfileMutation.isPending}
                isDisabled={userProfileMutation.isPending}
              >
                Save
              </ButtonV2>
              <ButtonV2
                size="sm"
                color="default"
                variant="flat"
                onPress={onClose}
                isDisabled={userProfileMutation.isPending}
              >
                Close
              </ButtonV2>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default AccountFormModal;
