import { Navigate, Outlet } from 'react-router';

import { useAuthStore } from 'src/entities/user/store/useAuthStore';
import { ROUTE_PATHS } from 'src/shared/config/routes';

export const PublicRoute = () => {
  const authToken = useAuthStore((state) => state.authToken);

  if (authToken) {
    return <Navigate to={ROUTE_PATHS.PRODUCTS} replace />;
  }

  return <Outlet />;
};

export default PublicRoute;
