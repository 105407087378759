import { XMarkIcon } from '@heroicons/react/16/solid';
import { Modal as StyledModal } from '@heroui/react';
import React from 'react';

import { useMediaQuery } from 'src/shared/hooks';

import ButtonV2 from '../Button/v2';

interface ModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  size?:
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | 'full'
    | 'xs'
    | '3xl'
    | '4xl'
    | '5xl';
  children: React.ReactNode;
  scrollBehavior?: 'inside' | 'outside' | 'normal';
  hideCloseButton?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  scrollBehavior = 'normal',
  size = 'xl',
  hideCloseButton,
  ...props
}) => {
  const isMobile = useMediaQuery();

  return (
    <StyledModal
      {...props}
      scrollBehavior={scrollBehavior}
      size={isMobile ? 'full' : size}
      closeButton={
        <ButtonV2 isIconOnly color="tertiary">
          <XMarkIcon className="size-4" />
        </ButtonV2>
      }
      classNames={{
        closeButton: 'rounded-xl top-4 right-4',
        body: 'overflow-y-auto max-h-[80vh]'
      }}
      hideCloseButton={hideCloseButton}
    />
  );
};

export default Modal;
