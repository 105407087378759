import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Text } from 'src/shared/components/ui';

const SectionWrapper = ({
  sectionTitle,
  ctaLink,
  ctaLabel,
  children,
  testId
}: {
  sectionTitle?: string;
  ctaLink?: string;
  ctaLabel?: string;
  children: ReactNode;
  testId: string;
}) => (
  <div className="flex flex-col flex-1 py-2 w-full" data-testid={testId}>
    <div className="justify-between">
      {sectionTitle && <Text control>{sectionTitle}</Text>}
      {ctaLink && <Link to={ctaLink}>{ctaLabel}</Link>}
    </div>
    {children}
  </div>
);

export default SectionWrapper;
