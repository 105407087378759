import { cn, Tooltip } from '@heroui/react';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { motion } from 'framer-motion';
import { useMediaQuery } from 'src/shared/hooks';
import useMobileMenu from 'src/shared/store/useMobileMenu';

import { Nav } from '../../ui';

interface SidebarItemProps {
  icon: JSX.Element;
  title: string;
  path: string;
  isOpen: boolean;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  icon,
  title,
  path,
  isOpen
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery();
  const toggleMobileMenu = useMobileMenu((state) => state.toggle);

  const isActive = useMemo(() => {
    const cleanPath = path.split('?')[0];

    return pathname === cleanPath;
  }, [pathname, path]);

  const onNavigate = () => {
    navigate(path);

    if (isMobile) {
      toggleMobileMenu();
    }
  };

  return (
    <Tooltip
      color="secondary"
      content={title}
      placement="right-start"
      classNames={{
        content: 'bg-white rounded-lg !border-none h-8 top-1',
        base: 'rounded-sm !border-none top-1'
      }}
      shadow="lg"
      hidden={isOpen}
      closeDelay={0}
    >
      <Nav
        color={isActive ? 'active' : 'nav'}
        className={cn('justify-start relative p-0 min-w-max')}
        onPress={onNavigate}
        fullWidth
      >
        <div className="absolute left-3">{icon}</div>
        <motion.div
          initial={!isOpen && { maxWidth: 0, opacity: 0 }}
          animate={{ maxWidth: isOpen ? '100%' : 0, opacity: isOpen ? 1 : 0 }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
          className="overflow-hidden left-10 whitespace-nowrap absolute"
        >
          <span>{title}</span>
        </motion.div>
      </Nav>
    </Tooltip>
  );
};

export default SidebarItem;
