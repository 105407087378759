import { Button, extendVariants } from '@heroui/react';

const Nav = extendVariants(Button, {
  variants: {
    color: {
      nav: 'hover:bg-gray-2 hover:text-primary-text text-secondary-text !opacity-100',
      active: 'bg-gray-2 text-primary-text'
    },
    isDisabled: {
      true: '!opacity-60'
    },
    size: {
      lg: 'px-4 py-2 max-h-[40px] font-medium leading-[150%] tracking-[-0.16px] gap-[8px] whitespace-nowrap'
    },
    isIconOnly: {
      true: '' // Placeholder to be used in compoundVariants
    },
    radius: {
      lg: 'rounded-xl'
    }
  },
  compoundVariants: [
    {
      isIconOnly: true,
      size: 'lg',
      class:
        '!w-[40px] !h-[40px] !min-h-[40px] !min-w-[40px] !p-0 flex items-center justify-center'
    }
  ],
  defaultVariants: {
    color: 'nav',
    size: 'lg',
    radius: 'lg'
  }
});

export default Nav;
