import React from 'react';

import { AddRounded } from '@mui/icons-material';

import NewFields from 'src/components/NewFields/NewFields';

import useLogic from './logic';
import { EmptyPropertyFieldProps } from './props';

const EmptyPropertyField = ({
  title,
  aiThinking,
  updateDocumentItem
}: EmptyPropertyFieldProps) => {
  const {
    t,
    newFields,
    handleAddField,
    handleNewFieldRemove,
    handleNewFieldChange,
    handleNewFieldSave,
    isReview
  } = useLogic({ title, aiThinking, updateDocumentItem });

  return (
    <>
      <div className="flex justify-between items-center">
        <h3 className="text-md font-500 text-black">
          {t(
            `${title}.placeholder`,
            title
              .split('_')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')
          )}
        </h3>
        {!isReview && (
          <button
            className="py-1 border border-gray-300 text-black rounded-full flex items-center hover:scale-105 transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed px-4 text-sm whitespace-nowrap"
            onClick={handleAddField}
            disabled={aiThinking}
            title="Add field"
          >
            <AddRounded style={{ fontSize: '16px' }} />
          </button>
        )}
      </div>

      {newFields.length > 0 && (
        <div className="mt-2">
          {newFields.map((field, index) => (
            <React.Fragment key={index}>
              <NewFields
                fieldKey={field.key}
                index={index}
                fieldValue={field.value}
                handleNewFieldChange={handleNewFieldChange}
                handleNewFieldSave={handleNewFieldSave}
                aiThinking={aiThinking || false}
                handleNewFieldRemove={handleNewFieldRemove}
                type={
                  title === 'number_of_units_per_variant' ? 'number' : 'text'
                }
              />
              <hr className="border-gray-200" />
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  );
};

export default EmptyPropertyField;
