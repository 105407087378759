import React, { useEffect, useState } from 'react';

import AnimatedText from 'src/components/AnimatedText/AnimatedText';
import JumpingDots from 'src/components/JumpingDots/JumpingDots';

import './styles.css';

const LMLoading: React.FC<{
  small: boolean;
  color?: string;
  messageOnLongWait?: boolean;
}> = ({ small = false, color = '#E0E0E0', messageOnLongWait = false }) => {
  const [longWaitMessage, setLongWaitMessage] = useState<string | null>(null);
  const [messageAppeared, setMessageAppeared] = useState(false);

  useEffect(() => {
    if (messageOnLongWait) {
      const timer = setTimeout(() => {
        setMessageAppeared(false);
        setLongWaitMessage('Working on it');
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [messageOnLongWait]);

  const dotSize = small ? 'size-1' : 'size-2.5';

  return (
    <>
      {longWaitMessage && (
        <h4 className="text-md text-black font-500 leading-[32px] whitespace-pre-line flex flex-row items-center">
          <AnimatedText
            fullText={longWaitMessage}
            interval={30}
            onEnd={() => setMessageAppeared(true)}
          />
          {messageAppeared && (
            <div className="flex items-center h-full space-x-2 ml-2">
              <JumpingDots size="size-2" color={color} />
            </div>
          )}
        </h4>
      )}
      {!longWaitMessage && (
        <div className="flex items-center h-full space-x-2 mt-2.5">
          <JumpingDots size={dotSize} color={color} />
        </div>
      )}
    </>
  );
};

export default LMLoading;
