import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';

import { RegisterFormValues, registerSchema } from '../model/schema';

export const useRegisterForm = () => {
  return useForm<RegisterFormValues>({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      email: '',
      signup_access_code: ''
    }
  });
};
