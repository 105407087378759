import { useMutation } from '@tanstack/react-query';

import {
  getUserTokenWithCode,
  GetUserTokenWithCodePayload
} from '../api/getUserTokenWithCode';

export const useRegister = () => {
  return useMutation({
    mutationFn: async ({
      email,
      signup_access_code
    }: GetUserTokenWithCodePayload) => {
      return await getUserTokenWithCode({ email, signup_access_code });
    }
  });
};
