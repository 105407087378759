import { useState } from 'react';

import { PreviewLinkProps } from './props';

const useLogic = ({ link, index, removeLink }: PreviewLinkProps) => {
  const [isEditing, setIsEditing] = useState(link.url === '');

  const startEditMode = () => {
    setIsEditing(true);
  };

  const removeValue = () => {
    removeLink(index);
    setIsEditing(false);
  };

  return {
    removeLink,
    isEditing,
    setIsEditing,
    startEditMode,
    removeValue
  };
};

export default useLogic;
