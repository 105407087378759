import React from 'react';

import { Link } from 'src/pages/NewRfqFlow/props';

import EmptyPropertyField from '../EmptyPropertyField/EmptyPropertyField';
import PropertyField from '../PropertyField/PropertyField';

interface ProductInfoPropertyProps {
  attribute: string;
  index: number;
  value: any;
  rfqCurrentFiles: any[];
  rfqLinks: { [key: string]: Link[] };
  aiThinking: boolean;
  updateDocumentItem: (
    parentKey: string | null,
    key: string,
    value: any
  ) => void;
  removeDocumentItem: (parentKey: string | null, key: string) => void;
  updateLinkItem: (
    key: string,
    index: number,
    url: string,
    caption: string
  ) => void;
  updateFileItem: (fileId: number, caption: string) => void;
  removeFileItem: (id: number) => void;
  setShowRemoveFileModal: (show: boolean) => void;
  setFileToRemove: (id: number) => void;
  setShowRemoveLinkModal: (show: boolean) => void;
  setSavedRemoveLinkFunction: (fn: () => void) => void;
  savedRemoveLinkFunction: (() => void) | undefined;
  withCaption: boolean;
}

const ProductInfoProperty: React.FC<ProductInfoPropertyProps> = ({
  index,
  attribute,
  value,
  rfqCurrentFiles,
  rfqLinks,
  aiThinking,
  updateDocumentItem,
  removeDocumentItem,
  updateLinkItem,
  updateFileItem,
  removeFileItem,
  setShowRemoveFileModal,
  setFileToRemove,
  setShowRemoveLinkModal,
  setSavedRemoveLinkFunction,
  savedRemoveLinkFunction,
  withCaption
}) => {
  if ((value && typeof value === 'object') || rfqCurrentFiles?.length > 0) {
    const items = Object.entries(value || {})?.map(([subKey, subValue]) => ({
      category: subKey,
      value: subValue?.toString() ?? ''
    }));

    return (
      <>
        {items?.length > 0 || rfqCurrentFiles?.length > 0 ? (
          <PropertyField
            key={`${index}-${attribute}`}
            title={attribute}
            items={items}
            updateDocumentItem={updateDocumentItem}
            aiThinking={aiThinking}
            removeDocumentItem={removeDocumentItem}
            links={rfqLinks[attribute] || []}
            updateLinkItem={updateLinkItem}
            files={rfqCurrentFiles}
            updateFileItem={updateFileItem}
            removeFileItem={removeFileItem}
            setShowRemoveFileModal={setShowRemoveFileModal}
            setFileToRemove={setFileToRemove}
            showConfirmModal={() => {
              setShowRemoveLinkModal(true);
            }}
            savedRemoveLinkFunction={savedRemoveLinkFunction}
            setSavedRemoveLinkFunction={setSavedRemoveLinkFunction}
            withCaption={withCaption}
          />
        ) : (
          <EmptyPropertyField
            key={`${index}-${attribute}`}
            title={attribute}
            aiThinking={aiThinking}
            updateDocumentItem={updateDocumentItem}
          />
        )}
      </>
    );
  }

  return (
    <EmptyPropertyField
      key={`${index}-${attribute}`}
      title={attribute}
      aiThinking={aiThinking}
      updateDocumentItem={updateDocumentItem}
    />
  );
};

export default ProductInfoProperty;
