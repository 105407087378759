import { addToast, Switch } from '@heroui/react';

import { useAuthStore } from 'src/entities/user/store/useAuthStore';
import { User } from 'src/shared/types/user';

import { Typography } from 'src/shared/components/ui';

import { useUpdateUserNotifications } from '../hooks/useUpdateUserNotifications';

interface AccountNotificationsProps {
  emailNotifications?: boolean;
  id?: string;
}

const AccountNotifications: React.FC<AccountNotificationsProps> = ({
  emailNotifications,
  id
}) => {
  const userNotificationsMutation = useUpdateUserNotifications();
  const setUser = useAuthStore((state) => state.setUser);
  const onChangeEmailNotification = (isSelected: boolean) => {
    userNotificationsMutation.mutate(
      {
        emailNotifications: isSelected,
        id: id as string
      },
      {
        onSuccess: (data: User) => {
          setUser(data);
          addToast({
            color: 'success',
            title: 'Email notification has been successfully updated.'
          });
        }
      }
    );
  };

  return (
    <div className="mb-10">
      <Typography variant="h4" weight="bold">
        Notifications
      </Typography>
      <div className="flex flex-col gap-5 mt-3 border border-gray-4 rounded-xl p-4">
        <div>
          <Typography
            variant="body-1"
            weight="medium"
            as="p"
            className="text-secondary-text"
          >
            Email notification
          </Typography>
          <Switch
            onValueChange={(isSelected) =>
              onChangeEmailNotification(isSelected)
            }
            isSelected={emailNotifications}
            className="mt-1"
            size="sm"
            color="primary"
            classNames={{
              wrapper: 'h-4 w-7 !px-[2px]',
              thumb: 'w-3 h-3 group-data-[selected=true]:!ml-[12px] !ml-[0px]'
            }}
          >
            <Typography variant="body-1" weight="medium">
              On
            </Typography>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default AccountNotifications;
