import React from 'react';

import LinkIcon from '@mui/icons-material/Link';

interface LinkButtonProps {
  isRFQLinkProcessingEnabled: boolean;
  disabled: boolean;
  addNewLink: () => void;
  embedded?: boolean;
}

const LinkButton: React.FC<LinkButtonProps> = ({
  isRFQLinkProcessingEnabled,
  disabled,
  addNewLink,
  embedded = true
}) => {
  return (
    <button
      className={`flex items-center gap-1 px-3 py-1.5 font-500 rounded-full text-black hover:text-black focus:ring-2 focus:ring-gray-400 transition-all duration-300 ease-in-out hover:scale-110 active:scale-95 cursor-pointer
            disabled:bg-gray-400 disabled:cursor-not-allowed disabled:text-white disabled:hover:scale-100 disabled:hover:bg-gray-400
            ${embedded ? 'bg-[#E5E7EB] hover:bg-[#cccdcf]' : 'bg-p-cavela-blue text-white hover:bg-blue-700 hover:text-white'}`}
      title={
        isRFQLinkProcessingEnabled
          ? 'Add link as input to be processed'
          : 'Add link'
      }
      onClick={() => addNewLink()}
      disabled={disabled}
    >
      <LinkIcon />
      {isRFQLinkProcessingEnabled && <span>Add links</span>}
    </button>
  );
};

export default LinkButton;
