// TO DELETE
import React from 'react';

import Typography from 'src/shared/components/ui/Typography';

const colors = {
  'Brand Colors': {
    Primary: 'bg-primary',
    Secondary: 'bg-secondary',
    Tertiary: 'bg-tertiary'
  },
  'Main Colors': {
    'Primary Text': 'bg-primary-text',
    'Secondary Text': 'bg-secondary-text',
    Stroke: 'bg-stroke'
  },
  'Dark Colors': {
    Dark: 'bg-dark',
    'Dark 2': 'bg-dark-2',
    'Dark 3': 'bg-dark-3',
    'Dark 4': 'bg-dark-4',
    'Dark 5': 'bg-dark-5',
    'Dark 6': 'bg-dark-6',
    'Dark 7': 'bg-dark-7',
    'Dark 8': 'bg-dark-8'
  },
  'White Colors': {
    White: 'bg-white'
  },
  'Gray Colors': {
    'Gray 1': 'bg-gray-1',
    'Gray 2': 'bg-gray-2',
    'Gray 3': 'bg-gray-3',
    'Gray 4': 'bg-gray-4',
    'Gray 5': 'bg-gray-5',
    'Gray 6': 'bg-gray-6',
    'Gray 7': 'bg-gray-7'
  },
  'Yellow Colors': {
    Yellow: 'bg-yellow',
    'Yellow Dark': 'bg-yellow-dark',
    'Yellow Dark 2': 'bg-yellow-dark-2',
    'Yellow Light': 'bg-yellow-light',
    'Yellow Light 2': 'bg-yellow-light-2',
    'Yellow Light 3': 'bg-yellow-light-3',
    'Yellow Light 4': 'bg-yellow-light-4'
  },
  'Orange Colors': {
    Orange: 'bg-orange',
    'Orange Dark': 'bg-orange-dark',
    'Orange Light': 'bg-orange-light',
    'Orange Light 2': 'bg-orange-light-2',
    'Orange Light 3': 'bg-orange-light-3',
    'Orange Light 4': 'bg-orange-light-4',
    'Orange Light 5': 'bg-orange-light-5'
  },
  'Red Colors': {
    Red: 'bg-red',
    'Red Dark': 'bg-red-dark',
    'Red Light': 'bg-red-light',
    'Red Light 2': 'bg-red-light-2',
    'Red Light 3': 'bg-red-light-3',
    'Red Light 4': 'bg-red-light-4',
    'Red Light 5': 'bg-red-light-5',
    'Red Light 6': 'bg-red-light-6'
  },
  'Pink Colors': {
    Pink: 'bg-pink',
    'Pink Dark': 'bg-pink-dark',
    'Pink Light': 'bg-pink-light',
    'Pink Light 2': 'bg-pink-light-2',
    'Pink Light 3': 'bg-pink-light-3',
    'Pink Light 4': 'bg-pink-light-4'
  },
  'Purple Colors': {
    Purple: 'bg-purple',
    'Purple Dark': 'bg-purple-dark',
    'Purple Dark 2': 'bg-purple-dark-2',
    'Purple Light': 'bg-purple-light',
    'Purple Light 2': 'bg-purple-light-2',
    'Purple Light 3': 'bg-purple-light-3',
    'Purple Light 4': 'bg-purple-light-4',
    'Purple Light 5': 'bg-purple-light-5'
  },
  'Blue Colors': {
    Blue: 'bg-blue',
    'Blue Dark': 'bg-blue-dark',
    'Blue Light': 'bg-blue-light',
    'Blue Light 2': 'bg-blue-light-2',
    'Blue Light 3': 'bg-blue-light-3',
    'Blue Light 4': 'bg-blue-light-4',
    'Blue Light 5': 'bg-blue-light-5'
  },
  'Cyan Colors': {
    Cyan: 'bg-cyan',
    'Cyan Dark': 'bg-cyan-dark',
    'Cyan Light': 'bg-cyan-light',
    'Cyan Light 2': 'bg-cyan-light-2',
    'Cyan Light 3': 'bg-cyan-light-3'
  },
  'Teal Colors': {
    Teal: 'bg-teal',
    'Teal Dark': 'bg-teal-dark',
    'Teal Light': 'bg-teal-light',
    'Teal Light 2': 'bg-teal-light-2',
    'Teal Light 3': 'bg-teal-light-3'
  },
  'Green Colors': {
    Green: 'bg-green',
    'Green Dark': 'bg-green-dark',
    'Green Light': 'bg-green-light',
    'Green Light 2': 'bg-green-light-2',
    'Green Light 3': 'bg-green-light-3',
    'Green Light 4': 'bg-green-light-4',
    'Green Light 5': 'bg-green-light-5',
    'Green Light 6': 'bg-green-light-6'
  }
};

const variants = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'h7',
  'body-1',
  'body-2',
  'body-3'
];
const weights = ['regular', 'medium', 'bold'];

const TypographyShowcase: React.FC = () => {
  return (
    <div className="mt-8 space-y-4 grid grid-cols-4">
      {variants.map((variant) => (
        <div key={variant} className="space-y-2">
          <Typography variant="h4" className="text-gray-500">
            {variant.toUpperCase()}
          </Typography>
          {weights.map((weight) => (
            <Typography
              key={weight}
              variant={variant as any}
              weight={weight as any}
            >
              {variant} - {weight}
            </Typography>
          ))}
        </div>
      ))}
    </div>
  );
};

const ColorsPage: React.FC = () => {
  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-6">Design System Colors</h1>
      {Object.entries(colors).map(([category, shades]) => (
        <div key={category} className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">{category}</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
            {Object.entries(shades).map(([name, tailwindClass]) => (
              <div
                key={name}
                className="flex border border-gray-2 rounded-lg overflow-hidden flex-col justify-start"
              >
                <div className={`w-full h-20 ${tailwindClass}`}></div>
                <div className="p-2">
                  <p className="text-sm font-600 text-gray-900">{name}</p>
                  <p className="text-xs text-gray-600">{tailwindClass}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}

      <div>
        <h1 className="text-3xl font-bold">Typography</h1>
        <TypographyShowcase />
      </div>
    </div>
  );
};

export default ColorsPage;
