import React from 'react';

import { Text } from 'src/shared/components/ui';

const HORIZONTAL = 'horizontal';
const QUOTING = 'quoting';
const SAMPLING = 'sampling';
const COMPLETE = 'complete';

const Timeline = ({
  small = false,
  stage = 0,
  labels = [QUOTING, SAMPLING, COMPLETE],
  direction = HORIZONTAL,
  style = {}
}) => {
  const isHorizontal = direction === HORIZONTAL;

  const Container = ({ children }) =>
    isHorizontal ? (
      <div className="flex items-center justify-center gap-1 w-full">
        {children}
      </div>
    ) : (
      <div className="flex flex-col items-center justify-center gap-1 h-full">
        {children}
      </div>
    );

  const Line = ({ disabled }) =>
    isHorizontal ? (
      disabled ? (
        <div className="flex-1 bg-[#DEE2E6] w-full min-w-6 h-1 rounded-full" />
      ) : (
        <div className="flex-1 bg-[#000EEF] w-full min-w-6 h-1 rounded-full" />
      )
    ) : disabled ? (
      <div className="flex-1 bg-[#DEE2E6] h-full min-h-6 w-1 rounded-full" />
    ) : (
      <div className="flex-1 bg-[#000EEF] h-full min-h-6 w-1 rounded-full" />
    );

  const SmallLine = ({ disabled }) =>
    isHorizontal ? (
      disabled ? (
        <div className="bg-[#DEE2E6] w-6 h-1 rounded-full" />
      ) : (
        <div className="bg-[#000EEF] w-6 h-1 rounded-full" />
      )
    ) : disabled ? (
      <div className="bg-[#DEE2E6] h-6 w-1 rounded-full" />
    ) : (
      <div className="bg-[#000EEF] h-6 w-1 rounded-full" />
    );

  const Node = ({ disabled }) =>
    disabled ? (
      <div className="bg-[#DEE2E6] w-[.5rem] h-[.5rem] rounded-full" />
    ) : (
      <div className="bg-[#000EEF] w-[.5rem] h-[.5rem] rounded-full" />
    );

  return (
    <div
      className="flex gap-2 items-stretch justify-between w-full h-full"
      style={style}
    >
      {isHorizontal && <span className="text-xs font-500">{labels[0]}</span>}
      <Container>
        {isHorizontal && <Node />}
        {!isHorizontal && (
          <div className="flex items-center justify-center">
            <div className="w-[5rem] ml-[-5rem] capitalize">
              <span className="text-xs">{labels[0]}</span>
            </div>
            <Node />
          </div>
        )}
        {small ? (
          <SmallLine disabled={stage === 0} />
        ) : (
          <Line disabled={stage === 0} />
        )}
        {isHorizontal && <Node disabled={stage === 0} />}
        {!isHorizontal && (
          <div className="flex items-center justify-center">
            <div className="w-[5rem] ml-[-5rem] capitalize">
              <span className="text-xs">{labels[1]}</span>
            </div>
            <Node disabled={stage === 0} />
          </div>
        )}
        {small ? (
          <SmallLine disabled={stage !== 2} />
        ) : (
          <Line disabled={stage !== 2} />
        )}
        {isHorizontal && <Node disabled={stage !== 2} />}
        {!isHorizontal && (
          <div className="flex items-center justify-center">
            <div className="w-[5rem] ml-[-5rem] capitalize">
              <span className="text-xs">{labels[2]}</span>
            </div>
            <Node disabled={stage !== 2} />
          </div>
        )}
      </Container>
    </div>
  );
};

export default Timeline;
