import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import i18next from 'i18next';
import useErrorStore from 'src/hooks/useErrorStore';
import { captureException } from 'src/services/reporting';
import { API_URL, MAX_FILE_UPLOAD_SIZE_MB } from 'src/settings';
import { useImageGenerationStore } from 'src/stores/imageGenerationStore';
import { CustomError } from 'src/types/errorTypes';
import { fetchWithAuth } from 'src/utils/fetchWithAuth';
import { checkIsAnyFileOver, isOverLimitedMb } from 'src/utils/fileSizeHelpers';

import { ExtraQuestion } from 'src/components/RfqCreationFlow/ProductInfo/props';
import { RFQ } from 'src/components/RfqDisplay/props';

import AuthContext from '../../context/Auth';
import { DocumentData, FileItem, Link, Question } from './props';

const { t } = i18next;
const first_step_url = `${API_URL}/api/rfq-steps`;
const get_rfq_data_url = `${API_URL}/api/rfqs`;
const steps_url = `${API_URL}/api/rfqs-steps/`;
const waitTimeWithAiMessage = 5000;

const initialDocumentData: DocumentData = {
  name: '',
  number_of_units_per_variant: {},
  materials: {},
  colors: {},
  sizes: {},
  technical_details: {},
  extra_information: {}
};

const useLogic = () => {
  const from = useLocation();
  const isReview = from.search.includes('review');
  const hasRfqId = from.search.includes('rfq_id');

  const { error, setError } = useErrorStore();

  const { userInfo, isLoading } = useContext(AuthContext);
  const [flowStarted, setFlowStarted] = useState(false);
  const [filesOrLinks, setFilesOrLinks] = useState(true);
  const { setIsGeneratingImage } = useImageGenerationStore();
  const [profileIsConfirmed, setProfileIsConfirmed] = useState(false);
  const [aiThinking, setAiThinking] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [aiWaitingResponse, setAiWaitingResponse] = useState('');
  const [lastAiWaitingResponse, setLastAiWaitingResponse] = useState('');
  const [aiStep, setAiStep] = useState(0);
  const [textareaValue, setTextareaValue] = useState('');
  const [questions, setQuestions] = useState<Question[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [rfq, setRfq] = useState<RFQ | null>(null);
  const [document, setDocument] = useState<DocumentData>(initialDocumentData);
  const [designTransition, setDesignTransition] = useState(isReview);
  const [extraQuestions, setExtraQuestions] = useState<ExtraQuestion[]>([]);
  const [image, setImage] = useState<string>('');
  const [isIncorrect, setIsIncorrect] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showRestartModal, setShowRestartModal] = useState(false);
  const [isAnEdit, setIsAnEdit] = useState(hasRfqId);
  const [links, setLinks] = useState<Link[]>([]);
  const [files, setFiles] = useState<FileItem[]>([]);
  const [showNewFileUploadModal, setShowNewFileUploadModal] = useState(false);
  const [showFinalScreen, setShowFinalScreen] = useState(false);
  const [productId, setProductId] = useState<string | null>(null);
  const [quantity, setQuantity] = useState<number>(500);
  const [showRemoveFileModal, setShowRemoveFileModal] = useState(false);
  const [fileToRemove, setFileToRemove] = useState<number>();
  const [showRemoveLinkModal, setShowRemoveLinkModal] = useState(false);
  const [savedRemoveLinkFunction, setSavedRemoveLinkFunction] =
    useState<() => void>();

  const fetchRfqById = useCallback(
    async (rfqId: string) => {
      if (!isReview) {
        setAiThinking(true);
        setShowSpinner(true);
        setLastAiWaitingResponse('');
      }

      setIsAnEdit(true);
      try {
        const response = await fetchWithAuth(`${get_rfq_data_url}/${rfqId}`, {
          method: 'GET'
        });

        const data = await response.json();
        setShowSpinner(false);
        const { rfq } = data;

        setRfq(rfq);
        setDocument(rfq.document);
        setQuestions(rfq.questions);
        setCurrentQuestionIndex(rfq.questions.length - 1);
        setAiStep(rfq.questions.length - 1);
        setImage(rfq.image);
        setFlowStarted(true);

        setAiThinking(false);
        setError('');

        const lastQuestionIsExtra =
          rfq.questions[rfq.questions.length - 1].question_type ===
            'single_choice' ||
          rfq.questions[rfq.questions.length - 1].question_type ===
            'world_location' ||
          rfq.questions[rfq.questions.length - 1].question_type === 'address';

        if (lastQuestionIsExtra) {
          setDesignTransition(true);
          const rawExtraQuestions = rfq.questions.filter(
            (question: ExtraQuestion) =>
              question.question_type === 'single_choice' ||
              question.question_type === 'world_location' ||
              question.question_type === 'address'
          );

          const rfqExtraQuestions = rawExtraQuestions.map(
            (question: ExtraQuestion) => ({
              question: question.question,
              options: question.options,
              answer: rfq.document[question.attribute],
              attribute: question.attribute,
              question_type: question.question_type
            })
          );

          setExtraQuestions(rfqExtraQuestions);
        }
      } catch (error: any) {
        setError('An error ocurred while fetching data');
        setShowSpinner(false);
        setAiThinking(false);

        const extra = {
          statusCode: error.statusCode
        };
        captureException({ error, extra });
        return null;
      }
    },
    [isReview]
  );

  const isReadOnly = userInfo?.settings?.read_only || false;

  const heading = t('createRFQ');

  const updateQuestions = useCallback((newQuestions: Question[]) => {
    setQuestions((prevQuestions) => [...prevQuestions, ...newQuestions]);
  }, []);

  const fetchRFQ = async (answer: string) => {
    try {
      const formData = new FormData();
      formData.append('answer', answer);

      links.forEach((link) => {
        if (link.url !== '') {
          formData.append('links', JSON.stringify(link));
        }
      });

      files.forEach((file) => {
        if (file.file) {
          formData.append('files', file.file);
        }

        if (file.caption) {
          formData.append('captions', file.caption);
        }
      });

      const response = await fetchWithAuth(first_step_url, {
        method: 'POST',
        body: formData
      });

      if (response.ok === false) {
        setError('An error ocurred while fetching data');
        setShowSpinner(false);
        setAiThinking(false);

        const extra = {
          statusCode: response.status
        };

        const newError = new CustomError({
          message: 'An error ocurred while fetching data',
          statusCode: response.status
        });

        captureException({ error: newError, extra });
        return null;
      }

      const data = await response.json();

      const { rfq } = data;

      if (!rfq) return;

      let isIncorrect = false;

      if (rfq.questions[rfq.questions.length - 1]?.correct === null) {
        if (rfq.questions[rfq.questions.length - 2]?.correct !== null) {
          isIncorrect = !rfq.questions[rfq.questions.length - 2].correct;
        }
      } else {
        isIncorrect = !rfq.questions[rfq.questions.length - 1]?.correct;
      }

      setIsIncorrect(isIncorrect);
      updateQuestions(rfq.questions);

      return data;
    } catch (err: any) {
      setShowSpinner(false);
      setError('Error fetching RFQ');

      const extra = {
        statusCode: err.statusCode
      };

      captureException({ error: err, extra });

      return null;
    }
  };

  const fetchRfqSteps = async (
    id: string,
    attribute: string,
    skipMode: boolean
  ) => {
    const formData = new FormData();
    formData.append('answer', skipMode ? 'skip' : textareaValue);
    formData.append('attribute', attribute);

    links.forEach((link) => {
      if (link.url !== '') {
        formData.append('links', JSON.stringify(link));
      }
    });

    files.forEach((file) => {
      if (file.file) {
        formData.append('files', file.file);
      }

      if (file.caption) {
        formData.append('captions', file.caption);
      }
    });

    try {
      const response = await fetchWithAuth(`${steps_url}${id}`, {
        method: 'PATCH',
        body: formData
      });

      if (response.ok === false) {
        setError('An error ocurred while fetching data');
        setShowSpinner(false);
        setAiThinking(false);

        const extra = {
          statusCode: response.status
        };

        const newError = new CustomError({
          message: 'An error ocurred while fetching data',
          statusCode: response.status
        });

        captureException({ error: newError, extra });
        return null;
      }

      const data = await response.json();

      const { rfq } = data;

      if (!rfq) return;

      let isIncorrect = false;

      if (rfq.questions[rfq.questions.length - 1]?.correct === null) {
        if (rfq.questions[rfq.questions.length - 2]?.correct !== null) {
          isIncorrect = !rfq.questions[rfq.questions.length - 2].correct;
        }
      } else {
        isIncorrect = !rfq.questions[rfq.questions.length - 1]?.correct;
      }

      setIsIncorrect(isIncorrect);
      updateQuestions(rfq.questions);

      return data;
    } catch (err: any) {
      setShowSpinner(false);
      setError('Error fetching RFQ');

      const extra = {
        statusCode: err.statusCode
      };

      captureException({ error: err, extra });

      return null;
    }
  };

  const updateQuestionsIndex = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const textAreaNextButtonFunction = async (
    skipMode: boolean = false,
    options?: { answer?: string }
  ) => {
    setError('');
    setAiThinking(true);
    setShowSpinner(true);
    setLastAiWaitingResponse('');
    let data;

    if (aiStep === 0) {
      setIsGeneratingImage(false);
      data =
        options && options.answer
          ? await fetchRFQ(options.answer)
          : await fetchRFQ(textareaValue);
    } else {
      if (!rfq) return;
      if (!questions[currentQuestionIndex]?.attribute) return;

      data = await fetchRfqSteps(
        rfq.id.toString(),
        questions[currentQuestionIndex].attribute,
        skipMode
      );
    }
    setShowSpinner(false);

    if (!data || !data.rfq) return;

    setRfq(data.rfq);
    const rfqDocument = data.rfq.document;

    setDocument((prevDocument) => ({
      ...prevDocument,
      ...rfqDocument
    }));

    const extraQuestions = data.rfq.questions
      .slice()
      .reverse()
      .filter(
        (question: ExtraQuestion) =>
          (question.question_type === 'single_choice' ||
            question.question_type === 'world_location' ||
            question.question_type === 'address') &&
          !question.answer
      );

    if (extraQuestions.length > 1) {
      setDesignTransition(true);
      setExtraQuestions(extraQuestions.reverse());
    }

    const questionComment =
      data.rfq.questions[data.rfq.questions.length - 2]?.comment;

    const errorQuestionComment =
      data.rfq.questions[data.rfq.questions.length - 1]?.comment;

    if (errorQuestionComment != null) {
      setAiWaitingResponse(errorQuestionComment);
      setLastAiWaitingResponse(errorQuestionComment);
    } else {
      setAiWaitingResponse(questionComment);
      setLastAiWaitingResponse(questionComment);
    }

    setAiThinking(false);

    await new Promise((resolve) => setTimeout(resolve, waitTimeWithAiMessage));

    setQuestions(data.rfq.questions);
    setAiWaitingResponse('');
    setShowSpinner(false);
    setError('');

    setAiThinking(false);

    setTextareaValue('');
    setLinks([]);
    setFiles([]);

    const thereIsAnyIncorrectQuestion = data.rfq.questions.some(
      (question: any) => question.correct === false
    );

    if (errorQuestionComment == null && !thereIsAnyIncorrectQuestion) {
      updateQuestionsIndex();
      setAiStep((prevStep) => prevStep + 1);
    }
  };

  const getCurrentTextareaProps = (title: string) => {
    const currentQuestion = questions[currentQuestionIndex];
    let suggestions: string[] = [];
    let example = '';
    if (currentQuestion?.attribute === 'number_of_units_per_variant') {
      // We don't want suggestions or examples for units
    } else if (currentQuestion?.suggested_answers) {
      suggestions = currentQuestion.suggested_answers;
    } else if (
      currentQuestion?.attribute === 'description' ||
      !currentQuestion
    ) {
      example =
        'For example:\nAn all-season, reversible blanket with a blue waterproof nylon side for outdoor use and a green cozy, fleece side for indoor comfort';
    }
    return {
      title: title,
      example: example,
      suggestions: suggestions,
      skippable: currentQuestion?.attribute !== 'number_of_units_per_variant'
    };
  };

  const saveDocument = async (
    newDocument: DocumentData,
    links: Link[] = [],
    files: FileItem[] = []
  ) => {
    if (!rfq) return;

    const body: any = {
      document: newDocument
    };

    if (links) {
      body.links = links;
    }

    if (files) {
      body.files = files;
    }

    try {
      rfq.document = newDocument as any;

      const response = await fetchWithAuth(`${API_URL}/api/rfqs/${rfq?.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
      setShowSpinner(false);

      const data = await response.json();
      setRfq(data.rfq);
    } catch (err: any) {
      setError(err.statusCode);

      const extra = {
        statusCode: err.statusCode
      };

      captureException({ error: err, extra });
    }
  };

  const updateDocumentItem = (
    parentKey: string | null,
    key: string,
    value: any
  ) => {
    let newDocument: DocumentData;

    if (parentKey) {
      newDocument = {
        ...document,
        [parentKey]: {
          ...(document[parentKey as keyof DocumentData] as DocumentData),
          [key]: value
        }
      };
    } else {
      newDocument = {
        ...document,
        [key]: value
      };
    }

    setDocument(newDocument);
    saveDocument(newDocument);
  };

  const removeDocumentItem = (parentKey: string | null, key: string) => {
    if (parentKey) {
      const parentObject = document[parentKey as keyof DocumentData] as any;
      const { [key]: removedKey, ...restParentObject } = parentObject;
      const newDocument = {
        ...document,
        [parentKey]: restParentObject
      };
      setDocument(newDocument);
      saveDocument(newDocument);
    } else {
      const { [key]: removedKey, ...restDocument } = document as any;
      const newDocument = restDocument;

      setDocument(newDocument);
      saveDocument(newDocument);
    }
  };

  const updateLinkItem = async (
    key: string,
    index: number,
    url: string,
    caption: string
  ) => {
    const rfqLinks: any = rfq?.links || [];
    if (!rfqLinks) return;

    const propertyFieldLinks = rfqLinks[key] || [];
    if (!propertyFieldLinks) return;

    if (url === '' && caption === '') {
      propertyFieldLinks.splice(index, 1);
    } else {
      propertyFieldLinks[index] = {
        url,
        caption
      };
    }

    rfqLinks[key] = propertyFieldLinks;
    saveDocument(document, rfqLinks);
  };

  const updateFileItem = async (fileId: number, caption: string) => {
    try {
      const rfqId = rfq?.id;
      if (!rfqId) return;

      const response = await fetchWithAuth(
        `${API_URL}/api/rfqs/${rfqId}/files/${fileId}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            caption
          })
        }
      );
      setShowSpinner(false);

      if (response.ok === false) {
        setError(response.status.toString());

        const extra = {
          statusCode: response.status
        };

        const newError = new CustomError({
          message: 'Error updating file',
          statusCode: response.status
        });

        captureException({ error: newError, extra });
        return;
      }

      const data = await response.json();

      setRfq({
        ...rfq,
        files: data.files
      });
    } catch (err: any) {
      setShowSpinner(false);
      setError(err.statusCode.toString());

      const extra = {
        statusCode: err.statusCode
      };

      captureException({ error: err, extra });
    }
  };

  const removeFileItem = async (fileId: number) => {
    try {
      const rfqId = rfq?.id;
      if (!rfqId) return;

      await fetchWithAuth(`${API_URL}/api/rfqs/${rfqId}/files/${fileId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setShowSpinner(false);

      const newRfqFiles = rfq?.files.filter((file) => file.id !== fileId);
      setRfq({ ...rfq, files: newRfqFiles });
    } catch (err: any) {
      setShowSpinner(false);
      setError(err.statusCode.toString());

      const extra = {
        statusCode: err.statusCode
      };

      captureException({ error: err, extra });
    }
  };

  const addNewLink = () => {
    setLinks([...links, { url: '', caption: '' }]);
  };

  const updateLink = (index: number, updatedLink: Link) => {
    setLinks((prevLinks) => {
      const newLinks = [...prevLinks];
      newLinks[index] = updatedLink;
      return newLinks;
    });
  };

  const removeLink = (index: number) => {
    setLinks((prevLinks) => {
      const newLinks = [...prevLinks];
      newLinks.splice(index, 1);
      return newLinks;
    });
  };

  const addNewFile = (file: File | File[]) => {
    if (Array.isArray(file)) {
      if (checkIsAnyFileOver({ files: file, setError })) return;
      setFiles([...files, ...file.map((f) => ({ file: f, caption: '' }))]);
    } else {
      if (isOverLimitedMb({ bytes: file.size })) {
        setError(
          `The file ${file.name} is too large. Please upload files smaller than ${MAX_FILE_UPLOAD_SIZE_MB}MB.`
        );
        return;
      }
      setFiles([...files, { file, caption: '' }]);
    }
  };

  const updateFile = (index: number, updatedFile: FileItem) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles[index] = updatedFile;
      return newFiles;
    });
  };

  const removeFile = (index: number) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  const handleSkip = () => {
    textAreaNextButtonFunction(true);
  };

  const checkFilesAndLinksNextButtonDisabled = (withCaption: boolean) => {
    // Enable if there are any files with captions
    const hasValidFiles = files.some((file) => file.caption || !withCaption);

    // Enable if there are any links with both URL and caption
    const hasValidLinks = links?.some(
      (link) => link.url && (link.caption || !withCaption)
    );

    if (aiThinking) {
      return true;
    }

    // Enable if there's text OR valid files OR valid links
    return !(hasValidFiles || hasValidLinks);
  };

  useEffect(() => {
    if (hasRfqId) {
      const rfqId = from.search.split('=')[1];
      setIsAnEdit(true);
      fetchRfqById(rfqId);
    }
  }, [from.search, hasRfqId, fetchRfqById]);

  const restartRfq = () => {
    setShowRestartModal(false);
    setRfq(null);
    setDocument(initialDocumentData);
    setQuestions([]);
    setCurrentQuestionIndex(0);
    setAiStep(0);
    setImage('');
    setFlowStarted(false);
    setAiThinking(false);
    setShowSpinner(false);
    setError('');
    setIsAnEdit(false);

    setLinks([]);
    setFiles([]);
    setFilesOrLinks(true);
    setExtraQuestions([]);
    setDesignTransition(false);

    setShowConfirmModal(false);
    setProfileIsConfirmed(false);
  };

  return {
    heading,
    isReadOnly,
    from,
    error,
    setError,
    isLoading,
    flowStarted,
    setFlowStarted,
    filesOrLinks,
    setFilesOrLinks,
    aiThinking,
    aiStep,
    getCurrentTextareaProps,
    textAreaNextButtonFunction,
    setTextareaValue,
    setImage,
    setRfq,
    showSpinner,
    aiWaitingResponse,
    document,
    designTransition,
    extraQuestions,
    image,
    isIncorrect,
    currentQuestionIndex,
    textareaValue,
    rfq,
    initialDocumentData,
    updateDocumentItem,
    showConfirmModal,
    setShowConfirmModal,
    removeDocumentItem,
    isAnEdit,
    links,
    updateLink,
    addNewLink,
    removeLink,
    updateLinkItem,
    restartRfq,
    handleSkip,
    showRestartModal,
    setShowRestartModal,
    isReview,
    addNewFile,
    updateFile,
    files,
    removeFile,
    showNewFileUploadModal,
    setShowNewFileUploadModal,
    updateFileItem,
    removeFileItem,
    showFinalScreen,
    setShowFinalScreen,
    productId,
    setProductId,
    lastAiWaitingResponse,
    quantity,
    setQuantity,
    checkFilesAndLinksNextButtonDisabled,
    profileIsConfirmed,
    setProfileIsConfirmed,
    showRemoveFileModal,
    setShowRemoveFileModal,
    fileToRemove,
    setFileToRemove,
    showRemoveLinkModal,
    setShowRemoveLinkModal,
    savedRemoveLinkFunction,
    setSavedRemoveLinkFunction
  };
};
export default useLogic;
