import React from 'react';

const appearances = {
  primary: '#000EEF',
  secondary: '#000000',
  success: '#2CD673',
  error: '#F23030',
  caution: '#F59E0B',
  disabled: '#D3D5D9'
};

const variants = {
  solid: {
    color: 'white',
    borderStyle: 'solid',
    borderWidth: '1px'
  },
  outline: {
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: '1px'
  },
  link: {
    backgroundColor: 'transparent',
    borderWidth: 0
  }
};

interface ButtonProps {
  appearance?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'caution'
    | 'disabled';
  variant?: 'solid' | 'outline' | 'link';
  small?: boolean;
  full?: boolean;
  circle?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  type?: 'button' | 'submit' | 'reset';
  children: React.ReactNode;
}

const Button: React.FC<ButtonProps> = (props) => {
  const {
    appearance = 'primary',
    variant = 'solid',
    small = false,
    full = false,
    circle = false,
    onClick = undefined,
    disabled = false,
    style = {},
    type = 'button'
  } = props;

  const color = appearances[appearance];

  return (
    <button
      type={type}
      disabled={disabled}
      className="flex font-display text-white items-center justify-center m-2 border-1 px-6 py-2 rounded-full focus:outline-none hover:opacity-75"
      style={{
        backgroundColor: color,
        borderColor: color,
        color,

        ...(!small
          ? {}
          : {
              fontSize: '.9em',
              padding: '.5rem 1rem'
            }),

        ...(!circle
          ? {}
          : {
              padding: '.5rem',
              width: '2.5rem',
              height: '2.5rem'
            }),

        ...(!full
          ? {}
          : {
              marginLeft: 0,
              marginRight: 0,
              width: '100%'
            }),

        ...variants[variant],

        ...style
      }}
      onClick={onClick}
    >
      {props.children}
    </button>
  );
};

export default Button;
