import React from 'react';

interface BadgeNotificationProps {
  children?: React.ReactNode;
  variant?: 'primary' | 'secondary';
}

const BadgeNotification: React.FC<BadgeNotificationProps> = ({
  children = '',
  variant = 'primary'
}) =>
  children !== 0 && (
    <span className="absolute right-0 top-[calc(50% - .75rem)] flex flex-col items-center justify-center rounded-full bg-[#000EEF] text-white text-center text-sm font-[400] px-2 mx-2 min-w-6 min-h-6 max-w-16 max-h-6">
      {children}
    </span>
  );

export default BadgeNotification;
