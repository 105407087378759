import apiService from 'src/shared/config/apiService';
import handleAxiosError from 'src/shared/utils/handleAxiosError';

export type UpdateOrganizationPayload = {
  name: string;
  id: number;
};
type UpdateOrganizationResponse = any;

export const updateOrganization = async (
  payload: UpdateOrganizationPayload
): Promise<UpdateOrganizationResponse> => {
  try {
    const { data } = await apiService.patch<UpdateOrganizationResponse>(
      `/organizations/${payload.id}/`,
      { name: payload.name }
    );

    return data;
  } catch (error) {
    throw handleAxiosError(error);
  }
};
