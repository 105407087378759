import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { useUser } from 'src/entities/user/hooks/useUser';
import { useAuthStore } from 'src/entities/user/store/useAuthStore';
import { ROUTE_PATHS } from 'src/shared/config/routes';

export const AuthProviderV2 = ({ children }: { children: React.ReactNode }) => {
  const authToken = useAuthStore((state) => state.authToken);
  const setAuthToken = useAuthStore((state) => state.setAuthToken);
  const fallBackToken = useAuthStore((state) => state.fallBackToken);
  const setFallBackToken = useAuthStore((state) => state.setFallBackToken);
  const setSelectedOrganization = useAuthStore(
    (state) => state.setSelectedOrganization
  );
  const newToken = useAuthStore((state) => state.newToken);
  const setNewToken = useAuthStore((state) => state.setNewToken);

  const [shouldRefetch, setShouldRefetch] = useState(false);

  const { isError, refetch, error } = useUser();

  const queryClient = useQueryClient();

  useEffect(() => {
    if (!shouldRefetch) return;

    refetch();
    setShouldRefetch(false);

    // fixes incorrect organization ID when we change user using auth_token in PrivateRoute
    setTimeout(() => {
      queryClient.invalidateQueries({ queryKey: ['products-all'] });
    }, 300);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetch]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const queryAuthToken = searchParams.get('auth_token');

    if (queryAuthToken) {
      if (!fallBackToken) setFallBackToken(queryAuthToken);

      if (!authToken || authToken !== queryAuthToken) {
        setAuthToken(queryAuthToken);
        setNewToken(queryAuthToken);
      }
    }

    if (isError) {
      if (
        error.message === 'You are not authorized to access this organization'
      ) {
        setSelectedOrganization(null);

        setAuthToken(newToken);
        setShouldRefetch(true);
      } else if (error.message === 'Invalid token') {
        if (fallBackToken === authToken && authToken === newToken) {
          setNewToken(null);
          setAuthToken(null);
          setFallBackToken(null);
          window.location.href = ROUTE_PATHS.EXPIRED;
        }
        setAuthToken(fallBackToken);
        setShouldRefetch(true);
      }
    }

    // Clean up auth_token from URL
    const url = new URL(window.location.href);
    url.searchParams.delete('auth_token');
    window.history.replaceState(null, '', url);
  }, [
    authToken,
    fallBackToken,
    isError,
    error,
    setAuthToken,
    setFallBackToken,
    setSelectedOrganization,
    newToken,
    setNewToken
  ]);

  return children;
};

export default AuthProviderV2;
