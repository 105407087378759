const productDraftColumns = [
  {
    key: 'name',
    label: 'Name'
  },
  {
    key: 'stage',
    label: 'Stage'
  },
  {
    key: 'questions_answered',
    label: 'Questions answered'
  },
  {
    key: 'created',
    label: 'Created'
  }
];

export default productDraftColumns;
