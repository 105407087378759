import { Stage } from 'src/shared/types/product';

import Tag from '../ui/Tag';

const STAGE_COLORS = {
  quoting: 'yellow',
  sampling: 'blue',
  ordering: 'green',
  archived: 'red',
  draft: 'default'
} as const;

interface StageTagProps {
  type: Stage;
}

const StageTag: React.FC<StageTagProps> = ({ type }) => {
  return (
    <Tag className="capitalize" color={STAGE_COLORS[type] || 'default'}>
      {type}
    </Tag>
  );
};

export default StageTag;
