import { cn } from '@heroui/react';
import React from 'react';

import { motion, useTime, useTransform } from 'framer-motion';

const AiCardBorder: React.FC = () => {
  const time = useTime();

  const rotate = useTransform(time, [0, 3000], [0, 360], {
    clamp: false
  });

  const rotatingBg = useTransform(rotate, (r) => {
    return `conic-gradient(from ${r}deg, #000eef,
  #0038ef,
  #005cef,
  #007ff0,
  #33b3f8,
  #69dbff,
  #33b3f8,
  #007ff0,
  #005cef,
  #0038ef,
  #000eef)`;
  });
  return (
    <motion.div
      className={cn('absolute -inset-[1.5px] rounded-xl')}
      style={{
        background: rotatingBg
      }}
    ></motion.div>
  );
};

export default AiCardBorder;
