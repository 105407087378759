import apiService from 'src/shared/config/apiService';
import handleAxiosError from 'src/shared/utils/handleAxiosError';

export type UpdateUserAvatarPayload = {
  avatar: File | null;
  id?: string;
};
type UpdateUserAvatarResponse = any;

export const updateUserAvatar = async (
  payload: UpdateUserAvatarPayload
): Promise<UpdateUserAvatarResponse> => {
  try {
    const formData = new FormData();

    if (payload.avatar) {
      formData.append('avatar', payload.avatar);
    }

    const { data } = await apiService.post<UpdateUserAvatarResponse>(
      `/users/${payload.id}/avatar`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    return data;
  } catch (error) {
    throw handleAxiosError(error);
  }
};
