import Tag from '../ui/Tag';

interface SampleTypeTagProps {
  type: string;
}

const SampleTypeTag: React.FC<SampleTypeTagProps> = ({ type }) => {
  return (
    <Tag
      classNames={{ base: 'text-primary-text bg-white border-gray-4' }}
      className="capitalize"
      color="default"
      variant="bordered"
    >
      {type}
    </Tag>
  );
};

export default SampleTypeTag;
