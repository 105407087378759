import { addToast } from '@heroui/react';

import axios from 'axios';
import { useAuthStore } from 'src/entities/user/store/useAuthStore';
import { BACKEND_URL as BASE_URL } from 'src/settings';

const apiService = axios.create({
  baseURL: `${BASE_URL}/api`,
  headers: { 'Content-Type': 'application/json' }
});

apiService.interceptors.request.use(
  (config) => {
    const { authToken, selectedOrganization } = useAuthStore.getState();
    if (authToken) {
      config.headers.Authorization = `Token ${authToken}`;
    }

    if (selectedOrganization && selectedOrganization.id) {
      config.headers['X-Organization-Id'] = selectedOrganization.id.toString();
    }

    return config;
  },
  (error) => Promise.reject(error)
);

apiService.interceptors.response.use(
  (response) => response,
  async (error) => {
    switch (error.response?.status) {
      case 500:
        addToast({
          title: "Oops, an error occurred. We're working on a fix.",
          color: 'danger'
        });
        break;

      default:
        if (error.code === 'ERR_NETWORK') {
          addToast({
            title: 'Network Error. Please check your connection.',
            color: 'danger'
          });
        }
    }

    return Promise.reject(error);
  }
);

export default apiService;
