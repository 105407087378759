import React from 'react';
import { useLocation } from 'react-router-dom';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import { Link } from 'src/pages/NewRfqFlow/props';

import AvatarList from 'src/components/AvatarList/AvatarList';
import ErrorBox from 'src/components/ErrorBox/ErrorBox';
import PreviewFile from 'src/components/PreviewFile/PreviewFile';
import RFQImagesCarousel from 'src/components/RFQImagesCarousel/RFQImagesCarousel';
import SimpleModal from 'src/components/SimpleModal/SimpleModal';

import AiImage from '../AiImage/AiImage';
import ImageUploader from '../CoverArt/ImageUploader';
import ExtraPropertyField from '../ExtraPropertyField/ExtraPropertyField';
import PreviewLink from '../PreviewLink/PreviewLink';
import ProductInfoProperty from '../ProductInfoProperty/ProductInfoProperty';
import useLogic from './logic';
import { DocumentProps, ExtraQuestion } from './props';

const ProductInfo = ({
  rfqId,
  rfqName,
  rfqLinks,
  rfqFiles,
  rfqAttributeFiles,
  document,
  hasStarted,
  designTransition,
  extraQuestions,
  image,
  initialDocumentData,
  setImage,
  updateDocumentItem,
  aiThinking,
  removeDocumentItem,
  isReview,
  updateLinkItem,
  updateFileItem,
  removeFileItem,
  error,
  setError,
  setShowFinalScreen,
  setProductId,
  quantity,
  setQuantity,
  showRemoveFileModal,
  setShowRemoveFileModal,
  fileToRemove,
  setFileToRemove,
  showRemoveLinkModal,
  setShowRemoveLinkModal,
  savedRemoveLinkFunction,
  setSavedRemoveLinkFunction,
  openFileUploadModal
}: DocumentProps) => {
  const {
    createRfq,
    isCreating,
    showConfirmModal,
    setShowConfirmModal,
    fillFixedDocumentKeys,
    getRfqFiles,
    isHovered,
    setIsHovered,
    isEditing,
    setIsEditing,
    newName,
    setNewName,
    saveNewName,
    navigate
  } = useLogic({ rfqId, rfqName, setError, setShowFinalScreen, setProductId });

  const { pathname } = useLocation();

  if (!hasStarted) return <RFQImagesCarousel />;

  return (
    <>
      <div
        className={`md:absolute ${designTransition ? 'md:w-[47%]' : 'w-full'} h-full z-50 text-center flex flex-col animate-fadeIn items-center justify-start ${designTransition ? 'pt-[40px]' : 'pt-[40px] overflow-y-auto'}`}
      >
        <div className="w-[100px] h-[100px] rounded-2xl relative group">
          <AiImage image={image} isLoading={false} />
          <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10">
            <ImageUploader
              rfqId={rfqId}
              onImageUploaded={setImage}
              iconOnly={true}
              buttonText="Replace Image"
              className=""
            />
          </div>
        </div>
        {isEditing ? (
          <div className="relative w-auto mt-4">
            <input
              className="font-medium w-auto font-[700] bg-transparent outline-none px-2 py-1 border border-white rounded pr-20
              font-['Cabinet_Grotesk'] text-[30px] font-extrabold leading-[38px] text-left text-white capitalize cursor-pointer
             "
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  saveNewName();
                }
              }}
              placeholder="Your Product"
              autoFocus
            />
            <button
              className="h-4/6 absolute right-2 top-1/2 transform -translate-y-1/2 text-black hover:scale-105 transition-all duration-300 disabled:cursor-not-allowed disabled:opacity-50"
              onClick={() => {
                saveNewName();
                setIsEditing(false);
                setIsHovered(false);
              }}
              disabled={aiThinking || !newName}
            >
              <span className="h-full aspect-square flex items-center justify-center bg-border-light-gray-2 rounded cursor-pointer hover:bg-[#b0b1b4]">
                <CheckRoundedIcon style={{ fontSize: '16px' }} />
              </span>
            </button>
          </div>
        ) : (
          <h4
            className="text-lg font-600 mt-4 text-left text-white capitalize cursor-pointer"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => setIsEditing(true)}
          >
            {newName}
            {isHovered && (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="#ffff"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-2 inline-block text-white"
              >
                <path
                  d="M2.5 14.5501V17.0835C2.5 17.3168 2.68333 17.5001 2.91667 17.5001H5.45C5.55833 17.5001 5.66667 17.4585 5.74167 17.3751L14.8417 8.28346L11.7167 5.15846L2.625 14.2501C2.54167 14.3335 2.5 14.4335 2.5 14.5501ZM17.2583 5.8668C17.5833 5.5418 17.5833 5.0168 17.2583 4.6918L15.3083 2.7418C14.9833 2.4168 14.4583 2.4168 14.1333 2.7418L12.6083 4.2668L15.7333 7.3918L17.2583 5.8668Z"
                  fill="#ffff"
                />
              </svg>
            )}
          </h4>
        )}

        <div className="space-y-3 animate-fadeIn pb-14 w-[80%] md:w-[80%] lg:w-[80%] sm:w-[80%]">
          <div className="mt-4 w-full space-y-4">
            {!isReview && (
              <button
                className="w-full flex items-center justify-center gap-2 bg-white text-black py-3 px-4 rounded-lg hover:bg-gray-100 transition-colors duration-200 border border-gray-300"
                onClick={() => {
                  openFileUploadModal();
                }}
                disabled={aiThinking}
              >
                <UploadFileIcon style={{ fontSize: '20px' }} />
                <span>Upload Additional Files</span>
              </button>
            )}

            {rfqFiles.map((file: any, index: number) => (
              <PreviewFile
                key={`file-${index}-${file.id}`}
                file={file}
                updateFile={updateFileItem}
                removeFile={removeFileItem}
                showConfirmModal={() => {
                  setShowRemoveFileModal(true);
                  setFileToRemove(file.id);
                }}
                index={index}
                disabled={aiThinking || false}
                fromProductSide={true}
                keyValue=""
                withCaption={true}
              />
            ))}

            {getRfqFiles(rfqAttributeFiles, rfqFiles, 'default')?.map(
              (file: any, index: number) => {
                return (
                  <PreviewFile
                    key={`file-${index}-${file.id}`}
                    file={file}
                    updateFile={updateFileItem}
                    removeFile={removeFileItem}
                    index={index}
                    disabled={aiThinking || false}
                    fromProductSide={true}
                    keyValue=""
                    showConfirmModal={() => {
                      setShowRemoveFileModal(true);
                      setFileToRemove(file.id);
                    }}
                    withCaption={true}
                  />
                );
              }
            )}

            {rfqLinks['default']?.map((link: Link, index: number) => {
              return (
                <PreviewLink
                  key={`link-${index}-${link.url}`}
                  link={link}
                  updateLink={(index: number, link: Link) =>
                    updateLinkItem('default', index, link.url, link.caption)
                  }
                  removeLink={(index: number) =>
                    updateLinkItem('default', index, '', '')
                  }
                  index={index}
                  disabled={aiThinking}
                  fromProductSide={true}
                  showConfirmModal={() => {
                    setShowRemoveLinkModal(true);
                  }}
                  setSavedRemoveLinkFunction={setSavedRemoveLinkFunction}
                />
              );
            })}
          </div>

          {Object.entries(fillFixedDocumentKeys(document)).map(
            ([key, value], index) => {
              if (initialDocumentData && Array.isArray(initialDocumentData)) {
                const initialCategories = initialDocumentData.map(
                  (item) => Object.keys(item)[0]
                );
                if (!initialCategories.includes(key)) {
                  return null;
                }
              }

              const rfqCurrentFiles =
                getRfqFiles(rfqAttributeFiles, rfqFiles, key) || [];

              return (
                <div className="bg-white rounded-lg shadow p-3">
                  <ProductInfoProperty
                    key={`${index}-${key}`}
                    index={index}
                    attribute={key}
                    value={value}
                    rfqCurrentFiles={rfqCurrentFiles}
                    rfqLinks={rfqLinks}
                    aiThinking={aiThinking}
                    updateDocumentItem={updateDocumentItem}
                    removeDocumentItem={removeDocumentItem}
                    updateLinkItem={updateLinkItem}
                    updateFileItem={updateFileItem}
                    removeFileItem={removeFileItem}
                    setShowRemoveFileModal={setShowRemoveFileModal}
                    setFileToRemove={setFileToRemove}
                    setShowRemoveLinkModal={setShowRemoveLinkModal}
                    savedRemoveLinkFunction={savedRemoveLinkFunction}
                    setSavedRemoveLinkFunction={setSavedRemoveLinkFunction}
                    withCaption={true}
                  />
                </div>
              );
            }
          )}

          {extraQuestions &&
            extraQuestions.length > 0 &&
            extraQuestions.map((question: ExtraQuestion, index) => (
              <ExtraPropertyField
                key={index}
                question={question}
                setQuestionValue={(newValue) => {
                  updateDocumentItem(null, question.attribute, newValue);
                }}
              />
            ))}

          {extraQuestions && extraQuestions.length > 0 && (
            <>
              {!isReview && (
                <div className="bg-p-cavela-blue rounded-lg shadow p-4 text-white">
                  <div className="flex justify-center mb-4">
                    <AvatarList />
                  </div>

                  <div className="flex items-center justify-center mt-6 mb-2">
                    <img
                      src="/images/simpleAiStar.svg"
                      alt="AI Star"
                      className="size-[23px] mr-2"
                    />

                    <h4 className="text-white text-xl text-center">
                      I'm ready to start looking for suppliers!
                    </h4>
                  </div>
                  <div className="flex justify-center w-full mt-4">
                    <div className="flex flex-wrap justify-center gap-4">
                      <button
                        className="bg-black text-white py-3 px-8 rounded-full font-['Cabinet_Grotesk'] text-[16px] font-bold leading-[20px] transition-transform duration-300 ease-in-out hover:scale-105 text-center"
                        style={{ fontWeight: 700 }}
                        onClick={() => setShowConfirmModal(true)}
                      >
                        Save as draft
                      </button>

                      <button
                        className="bg-white text-black py-3 px-8 rounded-full font-['Cabinet_Grotesk'] text-[16px] font-bold leading-[20px] transition-transform duration-300 ease-in-out hover:scale-105 text-center whitespace-nowrap overflow-hidden text-overflow-ellipsis"
                        style={{ fontWeight: 700 }}
                        onClick={createRfq}
                        disabled={isCreating}
                      >
                        Start Matching
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {isReview && (
                <button
                  className="bg-white text-black rounded-full font-['Cabinet_Grotesk'] text-[16px] font-bold leading-[20px] transition-transform duration-300 ease-in-out hover:scale-105 w-auto px-3 py-2 text-center"
                  style={{ fontWeight: 700 }}
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>
              )}

              {error && <ErrorBox />}
            </>
          )}
        </div>
      </div>
      {showConfirmModal && (
        <SimpleModal
          title="Back to Home"
          description={
            isReview
              ? 'Do you want to leave this session and return home?'
              : 'Do you want to leave this session? Your draft will be saved and you can come back to finish later.'
          }
          onClose={() => setShowConfirmModal(false)}
          onSave={() => {
            if (pathname.includes('/v2')) {
              navigate('/v2/products?tab=drafts');
            } else {
              navigate('/');
            }
          }}
        />
      )}
    </>
  );
};

export default ProductInfo;
