import apiService from 'src/shared/config/apiService';
import { User } from 'src/shared/types/user';
import handleAxiosError from 'src/shared/utils/handleAxiosError';

import normalizeUserData from '../utils/normalizeUserData';

export const getUser = async () => {
  try {
    const response = await apiService.get<User>('/me');

    const normalized = normalizeUserData(response.data);

    return normalized;
  } catch (error) {
    throw handleAxiosError(error);
  }
};
