import { CheckIcon } from '@heroicons/react/16/solid';
import { cn } from '@heroui/react';
import React, { useMemo } from 'react';

import { format } from 'date-fns';
import { useAuthStore } from 'src/entities/user/store/useAuthStore';
import { UserAvatar } from 'src/shared/components';

import { Typography } from 'src/shared/components/ui';

import getUpdateItemHeader from '../utils/getUpdateItemHeader';

interface ProductUpdatesListProps {
  updates: any[];
}

const ProductUpdatesList: React.FC<ProductUpdatesListProps> = ({ updates }) => {
  const user = useAuthStore((state) => state.user);

  const renderUpdates = () => {
    return updates.map((update) => {
      const clarificationByUser =
        update.clarification?.from_user_id === user?.id;

      const isRelevant = update.relevant;
      const isAnswered = Boolean(update?.question?.answer);

      const headerValues = getUpdateItemHeader(
        update?.update_type,
        isRelevant,
        isAnswered
      );

      return (
        <div
          className="border border-gray-4 rounded-lg overflow-hidden"
          key={update?.id}
        >
          <div
            className={cn('p-2 flex gap-1 items-center', headerValues.styles)}
          >
            {headerValues.icon}
            <Typography
              variant="body-3"
              weight="medium"
              className={headerValues.textStyles}
            >
              {headerValues.text}
            </Typography>
          </div>
          <div className="px-3 py-4 flex gap-2">
            <UserAvatar
              size="lg"
              name={
                clarificationByUser
                  ? update?.clarification?.from_username
                  : 'Cavela'
              }
              image={
                clarificationByUser ? user?.avatar : '/images/defaultCavela.svg'
              }
            />
            <div className="bg-gray-2 flex items-center p-2 rounded-lg flex-grow">
              <Typography>{update?.title}</Typography>
            </div>
            <div className="flex flex-col text-right">
              <Typography
                variant="body-2"
                weight="medium"
                className="text-secondary-text"
              >
                {format(update?.created_at, 'MMM dd, yyyy')}
              </Typography>
              <Typography
                variant="body-2"
                weight="medium"
                className="text-secondary-text"
              >
                {format(update?.created_at, 'hh:mm a')}
              </Typography>
            </div>
          </div>
        </div>
      );
    });
  };

  return <div className="flex flex-col gap-3">{renderUpdates()}</div>;
};

export default ProductUpdatesList;
