import { wrapCreateBrowserRouter } from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';

import * as Reporting from 'src/services/reporting';
import { DEBUG_MODE_ENABLED, ENVIRONMENT } from 'src/settings';
import { ROUTE_PATHS } from 'src/shared/config/routes';

import NewRfqFlow from 'src/pages/NewRfqFlow/NewRfqFlow';
import AccountPage from 'src/pages/v2/account-page/AccountPage';
import ColorsPage from 'src/pages/v2/colors-page/ColorsPage';
import ComponentsPage from 'src/pages/v2/components-page/ComponentsPage';
import ExpiredPage from 'src/pages/v2/expired-page/ExpiredPage';
import LoginPage from 'src/pages/v2/login-page/LoginPage';
import OrganizationPage from 'src/pages/v2/organization-page/OrganizationPage';
import ProductDetailPage from 'src/pages/v2/product-detail-page/ProductDetailPage';
import ProductsPage from 'src/pages/v2/products-page/ProductsPage';
import RegisterPage from 'src/pages/v2/register-page/RegisterPage';

import ErrorBoundaryLayout from '../layouts/ErrorBoundaryLayout';
import PrivateLayout from '../layouts/PrivateLayout';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

Reporting.init({
  environment: ENVIRONMENT,
  debugModeEnabled: DEBUG_MODE_ENABLED as boolean
});

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    element: <ErrorBoundaryLayout />,
    children: [
      {
        element: <PublicRoute />,
        children: [
          {
            path: ROUTE_PATHS.LOGIN,
            element: <LoginPage />
          },
          {
            path: ROUTE_PATHS.EXPIRED,
            element: <ExpiredPage />
          },
          {
            path: ROUTE_PATHS.REGISTER,
            element: <RegisterPage />
          }
        ]
      },
      {
        element: <PrivateRoute />,
        children: [
          {
            element: <PrivateLayout />,
            children: [
              { path: ROUTE_PATHS.PRODUCTS, element: <ProductsPage /> },
              {
                path: ROUTE_PATHS.PRODUCT_DETAIL,
                element: <ProductDetailPage />
              },
              { path: ROUTE_PATHS.ORGANIZATION, element: <OrganizationPage /> },
              { path: ROUTE_PATHS.ACCOUNT, element: <AccountPage /> },
              // TO REMOVE:
              {
                path: ROUTE_PATHS.COLORS,
                element: <ColorsPage />
              },
              {
                path: ROUTE_PATHS.COMPONENTS,
                element: <ComponentsPage />
              }
            ]
          },
          {
            element: <NewRfqFlow />,
            path: ROUTE_PATHS.NEW_RFQ
          }
        ]
      }
    ]
  }
]);

export default router;
