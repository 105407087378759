import { z } from 'zod';

export const organizationAddressFormSchema = z.object({
  name: z.string().nonempty('Name is required'),
  is_default: z.boolean().optional(),
  line_1: z.string().nonempty('Address line 1 is required'),
  line_2: z.string().optional(),
  city: z.string().nonempty('City is required'),
  state: z.string().nonempty('State is required'),
  zip_code: z.string().nonempty('Zipcode is required'),
  country: z.string().nonempty('Country is required'),
  contact_name: z.string().nonempty('Contact name is required'),
  contact_phone: z.string().nonempty('Contact number is required')
});

export type OrganizationAddressFormValues = z.infer<
  typeof organizationAddressFormSchema
>;
