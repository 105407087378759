import apiService from 'src/shared/config/apiService';
import handleAxiosError from 'src/shared/utils/handleAxiosError';

export type UpdateUserProfilePayload = {
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  id: number;
};
type UpdateUserProfileResponse = any;

export const updateUserProfile = async (
  payload: UpdateUserProfilePayload
): Promise<UpdateUserProfileResponse> => {
  try {
    const { id, ...body } = payload;
    const { data } = await apiService.patch<UpdateUserProfileResponse>(
      `/users/${payload.id}/`,
      {
        email: body?.email,
        first_name: body?.firstName,
        last_name: body?.lastName,
        username: body?.username
      }
    );

    return data;
  } catch (error) {
    throw handleAxiosError(error);
  }
};
