import { UserIcon } from '@heroicons/react/16/solid';

import { Typography } from 'src/shared/components/ui';

interface SupplierTabContentProps {
  selectedQuote: any;
}

const SupplierTabContent: React.FC<SupplierTabContentProps> = ({
  selectedQuote
}) => {
  return (
    <div className="px-5 flex flex-col gap-5 pb-24">
      <div className="flex gap-1 items-center">
        <UserIcon className="size-5" />
        <Typography variant="h4" weight="bold">
          {selectedQuote.supplier}
        </Typography>
      </div>

      <div className="flex flex-col">
        <Typography
          variant="body-1"
          weight="medium"
          className="text-secondary-text"
        >
          Country
        </Typography>
        <Typography variant="body-1" weight="medium">
          Mexico
        </Typography>
      </div>

      <div className="grid grid-cols-2 gap-5">
        <div className="flex flex-col">
          <Typography
            variant="body-1"
            weight="medium"
            className="text-secondary-text"
          >
            Past client
          </Typography>
          <ul className="list-disc pl-5">
            <Typography variant="body-1" weight="medium" as="li">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor
            </Typography>
            <Typography variant="body-1" weight="medium" as="li">
              incididunt ut labore et dolore magna aliqua
            </Typography>
            <Typography variant="body-1" weight="medium" as="li">
              ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
              aliquip ex ea
            </Typography>
          </ul>
        </div>
        <div className="flex flex-col">
          <Typography
            variant="body-1"
            weight="medium"
            className="text-secondary-text"
          >
            Certificate
          </Typography>
          <ul className="list-disc pl-5">
            <Typography variant="body-1" weight="medium" as="li">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor
            </Typography>
            <Typography variant="body-1" weight="medium" as="li">
              incididunt ut labore et dolore magna aliqua
            </Typography>
            <Typography variant="body-1" weight="medium" as="li">
              ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
              aliquip ex ea
            </Typography>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SupplierTabContent;
