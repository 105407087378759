import React, { useState } from 'react';

import { API_URL } from 'src/settings';
import { fetchWithAuth } from 'src/utils/fetchWithAuth';

import CoverArt from '../CoverArt/CoverArt';
import NextButton from '../LMTextarea/NextButton';
import RFQAIIcon from '../RFQAIIcon/RFQAIIcon';
import RFQRestart from '../RFQRestart/RFQRestart';
import RFQTitle from '../RFQTitle/RFQTitle';

interface RFQProfileStepProps {
  profileName?: string;
  setProfileIsConfirmed: (confirmed: boolean) => void;
  setFlowStarted: (started: boolean) => void;
  rfqId: number;
  image: string | null;
  setImage: (image: string) => void;
  onRestart: () => void;
  setRfq: (rfq: any) => void;
}

export default function RFQProfileStep({
  profileName = '',
  setProfileIsConfirmed,
  setFlowStarted,
  rfqId,
  image,
  setImage,
  onRestart,
  setRfq
}: RFQProfileStepProps) {
  const [inputValue, setInputValue] = useState(profileName);

  const handleNext = async () => {
    const response = await fetchWithAuth(`${API_URL}/api/rfqs/${rfqId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        document: {
          name: inputValue.trim()
        }
      })
    });
    const data = await response.json();
    setRfq(data.rfq);

    setProfileIsConfirmed(true);
    setFlowStarted(true);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      setFlowStarted(true);
      handleNext();
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full mt-0">
      <div className="flex flex-col items-left justify-left p-4 mb-4 md:mt-24 w-full max-w-[700px] relative">
        <div className="absolute top-4 right-4 h-10">
          <RFQRestart onRestart={onRestart} />
        </div>
        <div className="flex flex-col items-start mt-2 w-full">
          <div className="w-full flex flex-grow flex-row">
            <RFQAIIcon />
            <RFQTitle text="Set up your product profile" minHeight={42} />
          </div>
          <div className="flex flex-col w-full">
            <label
              htmlFor="productName"
              className="mt-4 mb-1 text-sm font-medium text-gray-700"
            >
              Product name
            </label>
            <div className="flex items-center gap-2">
              <input
                id="productName"
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder={profileName}
                className="flex-grow px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-p-cavela-blue"
                autoFocus
              />
            </div>
          </div>
        </div>
        <div className="mt-6 mb-1 text-sm font-medium text-gray-700">
          Cover art
        </div>
        <CoverArt
          rfqId={rfqId}
          image={image}
          setImage={setImage}
          buttonsOnRight={
            <NextButton
              onClick={handleNext}
              disabled={!inputValue.trim()}
              pushToRight={false}
              buttonText="Select"
            />
          }
        />
      </div>
    </div>
  );
}
