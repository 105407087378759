import { ProductLifecycleStage, Stage } from 'src/shared/types/product';

const stageMap: Record<string, string> = {
  rfq_received: 'quoting',
  rfq_sent: 'quoting',
  quotes_received: 'quoting',
  samples_ordered: 'sampling',
  samples_produced: 'sampling',
  order_produced: 'ordering'
};

const getStageFromLifecycle = (lifecycle: ProductLifecycleStage[]): Stage => {
  const activeStatus = lifecycle.find(
    (item) => item.status === 'ongoing' || item.status === 'blocked'
  );

  const pendingStatus = lifecycle.find((item) => item.status === 'pending');

  const relevantStage = activeStatus ?? pendingStatus;

  return relevantStage?.id ? (stageMap[relevantStage.id] as Stage) : 'draft';
};

export default getStageFromLifecycle;
