import { Divider } from '@heroui/react';
import React from 'react';

import { useMediaQuery } from 'src/shared/hooks';

import { Typography } from '../ui';

interface StatItem {
  title: string;
  value: React.ReactNode;
  icon?: React.ReactNode;
}

interface SourcingStatsProps {
  title?: string;
  items: StatItem[];
}

const SourcingStats = ({
  title = 'Sourcing activities',
  items
}: SourcingStatsProps) => {
  const isMobile = useMediaQuery();
  return (
    <div>
      <Typography variant="h4" weight="bold">
        {title}
      </Typography>
      <div className="mobile:h-auto mobile:flex-col flex gap-5 items-center mt-3 border border-gray-4 rounded-xl p-3 h-20">
        {items.map((item, index) => (
          <React.Fragment key={index}>
            {index !== 0 && (
              <Divider orientation={isMobile ? 'horizontal' : 'vertical'} />
            )}
            <div className="flex flex-col items-center justify-center flex-1">
              <Typography
                variant="body-2"
                as="p"
                className="text-secondary-text"
                weight="medium"
              >
                {item.title}
              </Typography>
              <div className="flex items-center gap-1">
                {item.icon}
                <Typography variant="h2" as="span" weight="bold">
                  {item.value}
                </Typography>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default SourcingStats;
