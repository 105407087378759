const Logo = () => {
  return (
    <div
      className="cursor-pointer w-28"
      onClick={() => {
        window.open('https://cavela.com', '_blank');
      }}
    >
      <img src="/logo.svg" alt="cavelaLogo" className="w-full" />
    </div>
  );
};

export default Logo;
