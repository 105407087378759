import { z } from 'zod';

export const userProfileSchema = z.object({
  firstName: z.string().nonempty('First name is required'),
  lastName: z.string().nonempty('Last name is required'),
  email: z.string().email('Invalid email address'),
  username: z.string().nonempty('Username is required')
});

export type UserProfileFormValues = z.infer<typeof userProfileSchema>;
