import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';

import { LoginFormValues, loginSchema } from '../model/schema';

export const useLoginForm = () => {
  return useForm<LoginFormValues>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: ''
    }
  });
};
