import React, { useEffect, useRef, useState } from 'react';

import { InsertDriveFile } from '@mui/icons-material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import DeleteIcon from '@mui/icons-material/Delete';

import TextareaAutoH from 'src/pages/Clarifications/components/AskAnywhere/components/TextareaAutoH/TextareaAutoH';

import useLogic from './logic';
import { AddFileProps } from './props';

const AddFile = ({
  file,
  fileId,
  handleAddFile,
  handleUpdateFile,
  removeFile,
  index,
  fromProductSide,
  captionBase,
  keyValue,
  showConfirmModal,
  setIsEditing
}: AddFileProps) => {
  const { caption, setCaption, saveFile } = useLogic({
    file,
    handleAddFile,
    index,
    removeFile,
    fromProductSide,
    handleUpdateFile,
    captionBase,
    keyValue,
    fileId,
    setIsEditing,
    showConfirmModal
  });

  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target as Node)
      ) {
        saveFile();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [caption, saveFile]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      saveFile();
    }
  };

  return (
    <div
      ref={componentRef}
      className="p-4 flex gap-1 flex-col bg-bg-light-gray rounded-lg border border-gray-300"
    >
      <div className="flex gap-1 items-center">
        <div className="flex items-center justify-center size-4 shrink-0">
          <InsertDriveFile
            style={{
              fontSize: '16px'
            }}
          />
        </div>

        <span className="w-full text-sm text-left truncate" autoFocus>
          <strong>{file.file?.name || 'file name'}</strong>
        </span>

        <button
          className="size-4 text-gray-500 hover:scale-105 transition-all duration-300 flex items-center justify-center "
          onClick={(e) => {
            if (showConfirmModal) {
              showConfirmModal();
            } else {
              e.stopPropagation();
              removeFile(index);
            }
          }}
        >
          <DeleteIcon
            style={{
              fontSize: '16px'
            }}
          />
        </button>
      </div>
      <div className="flex gap-1 w-full">
        <div className="size-4 shrink-0" />
        <div className="h-full w-full relative">
          <TextareaAutoH
            value={caption}
            placeholder="Caption of this upload"
            onChange={(e) => setCaption(e.target.value)}
            onKeyDown={handleKeyDown}
            className="p-1 md:p-2 pr-16 w-full text-sm border border-black bg-bg-light-gray rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <div className="h-full absolute right-0 top-0 flex items-start justify-center">
            <button
              className={`h-6 w-6 ${fromProductSide ? 'mr-4' : ''} mt-1 mr-1 md:mt-2 md:mr-2 pointer-events-auto text-black hover:scale-105 transition-all duration-300 disabled:cursor-not-allowed disabled:opacity-50`}
              onClick={saveFile}
              disabled={!caption}
            >
              <span className="h-full aspect-square flex items-center justify-center bg-border-light-gray-2 rounded cursor-pointer hover:bg-[#b0b1b4]">
                <CheckRoundedIcon
                  style={{ fontSize: '16px' }}
                  /* onClick={handleSave} */
                />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFile;
