import React from 'react';

import {
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded
} from '@mui/icons-material';

import Text from '../Text';

interface BreadcrumbProps {
  links?: { name: string; path: string }[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ links = [] }) => (
  <div className="flex gap-2">
    <Text control>
      {links.length < 2 && (
        <span className="flex items-center">
          <KeyboardArrowLeftRounded />
          <a href="/">Back</a>
        </span>
      )}
      <span className="flex items-center">
        {links.map(({ name, path }, index) => (
          <span
            className="flex items-center"
            style={index < links.length - 1 ? { opacity: 0.5 } : {}}
          >
            <a href={path}>{name}</a>
            {index < links.length - 1 && <KeyboardArrowRightRounded />}
          </span>
        ))}
      </span>
    </Text>
  </div>
);

export default Breadcrumb;
