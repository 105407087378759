import { UserIcon } from '@heroicons/react/16/solid';

import { Tab, TabsV2, Typography } from 'src/shared/components/ui';

import QuotesTable from './QuotesTable';

const ProductDetailQuotes = () => {
  return (
    <div>
      <Typography variant="h4" as="h2" weight="bold">
        Quotes
      </Typography>

      <div className="mt-3">
        <TabsV2 color="dark" variant="light">
          <Tab key="all" title="All" />
          <Tab
            key="jason"
            title={
              <div className="flex items-center gap-1">
                <UserIcon className="size-4" />
                <span>Jason</span>
              </div>
            }
          />
          <Tab
            key="meanie"
            title={
              <div className="flex items-center gap-1">
                <UserIcon className="size-4" />
                <span>Meanie</span>
              </div>
            }
          />
        </TabsV2>
      </div>

      <QuotesTable />
    </div>
  );
};

export default ProductDetailQuotes;
