import { Product } from 'src/shared/types/product';

const getStatus = (product: Product) => {
  if (!product.last_relevant_update) return 'in-progress';

  switch (product.last_relevant_update.update_type) {
    case 'block':
      return 'action-required';
    default:
      return product.last_relevant_update.relevant
        ? 'new-update'
        : 'in-progress';
  }
};

export default getStatus;
