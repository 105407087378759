import { useMutation } from '@tanstack/react-query';

import {
  updateOrganizationAddress,
  UpdateOrganizationAddressPayload
} from '../api/updateOrganizationAddress';

export const useUpdateOrganizationAddress = () => {
  return useMutation({
    mutationFn: async ({ addresses, id }: UpdateOrganizationAddressPayload) => {
      return await updateOrganizationAddress({ addresses, id });
    }
  });
};
