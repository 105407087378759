import React from 'react';

import { motion } from 'framer-motion';

interface PrivateContentProps {
  children: React.ReactNode;
  isOpen: boolean;
}
const PrivateContent: React.FC<PrivateContentProps> = ({
  children,
  isOpen
}) => {
  return (
    <motion.main
      initial={false}
      className="relative flex-grow"
      animate={{ marginLeft: isOpen ? 240 : 72 }}
      transition={{ type: 'tween', duration: 0.3 }}
    >
      {children}
    </motion.main>
  );
};

export default PrivateContent;
