import React from 'react';

import Text from '../Text';

const HORIZONTAL = 'horizontal';
const SOURCING = 'sourcing';
const SAMPLING = 'sampling';
const COMPLETE = 'complete';

interface TimelineProps {
  small?: boolean;
  stage?: number;
  labels?: string[];
  direction?: 'horizontal' | 'vertical';
  style?: React.CSSProperties;
}

interface LineProps {
  disabled: boolean;
  isHorizontal: boolean;
  small?: boolean;
}

interface NodeProps {
  disabled?: boolean;
}

interface ContainerProps {
  isHorizontal: boolean;
  children: React.ReactNode;
}

const Container: React.FC<ContainerProps> = ({ isHorizontal, children }) =>
  isHorizontal ? (
    <div className="flex items-center justify-center gap-1 w-full">
      {children}
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center gap-1 h-full">
      {children}
    </div>
  );

const Line: React.FC<LineProps> = ({ disabled, isHorizontal }) =>
  isHorizontal ? (
    <div
      className={`flex-1 ${disabled ? 'bg-[#DEE2E6]' : 'bg-[#000EEF]'} w-full min-w-6 h-1 rounded-full`}
    />
  ) : (
    <div
      className={`flex-1 ${disabled ? 'bg-[#DEE2E6]' : 'bg-[#000EEF]'} h-full min-h-6 w-1 rounded-full`}
    />
  );

const SmallLine: React.FC<LineProps> = ({ disabled, isHorizontal }) =>
  isHorizontal ? (
    <div
      className={`${disabled ? 'bg-[#DEE2E6]' : 'bg-[#000EEF]'} w-6 h-1 rounded-full`}
    />
  ) : (
    <div
      className={`${disabled ? 'bg-[#DEE2E6]' : 'bg-[#000EEF]'} h-6 w-1 rounded-full`}
    />
  );

const Node: React.FC<NodeProps> = ({ disabled = false }) => (
  <div
    className={`${disabled ? 'bg-[#DEE2E6]' : 'bg-[#000EEF]'} w-[.5rem] h-[.5rem] rounded-full`}
  />
);

const Timeline: React.FC<TimelineProps> = ({
  small = false,
  stage = 0,
  labels = [SOURCING, SAMPLING, COMPLETE],
  direction = HORIZONTAL,
  style = {}
}) => {
  const isHorizontal = direction === HORIZONTAL;

  return (
    <div
      className="flex gap-2 items-stretch justify-between w-full h-full"
      style={style}
    >
      {isHorizontal && (
        <Text small style={{ textTransform: 'capitalize' }}>
          {labels[0]}
        </Text>
      )}
      <Container isHorizontal={isHorizontal}>
        {isHorizontal && <Node />}
        {!isHorizontal && (
          <div className="flex items-center justify-center">
            <div className="w-[5rem] ml-[-5rem] capitalize">
              <Text small>{labels[0]}</Text>
            </div>
            <Node />
          </div>
        )}
        {small ? (
          <SmallLine disabled={stage < 1} isHorizontal={isHorizontal} />
        ) : (
          <Line disabled={stage < 1} isHorizontal={isHorizontal} />
        )}
        <Node disabled={stage < 1} />
        {!isHorizontal && (
          <div className="flex items-center justify-center">
            <div className="w-[5rem] ml-[-5rem] capitalize">
              <Text small>{labels[1]}</Text>
            </div>
            <Node disabled={stage < 1} />
          </div>
        )}
        {small ? (
          <SmallLine disabled={stage < 2} isHorizontal={isHorizontal} />
        ) : (
          <Line disabled={stage < 2} isHorizontal={isHorizontal} />
        )}
        <Node disabled={stage < 2} />
        {!isHorizontal && (
          <div className="flex items-center justify-center">
            <div className="w-[5rem] ml-[-5rem] capitalize">
              <Text small>{labels[2]}</Text>
            </div>
            <Node disabled={stage < 2} />
          </div>
        )}
      </Container>
    </div>
  );
};

export default Timeline;
