import { QueryClientProvider as TanQueryProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ReactNode } from 'react';

import queryClient from 'src/shared/api/queryClient';

type QueryProviderProps = {
  children: ReactNode;
};

const QueryClientProvider = ({ children }: QueryProviderProps) => {
  return (
    <TanQueryProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </TanQueryProvider>
  );
};

export default QueryClientProvider;
