import { useEffect, useState } from 'react';

import { LogicProps } from './props';

export const EXAMPLE_PRODUCTS = [
  {
    title: 'Vegan Candle',
    image: '/images/exampleProducts/veganCandle.png',
    description: `Vegan Candle 
A 100% natural soy wax candle infused with rose and eucalyptus essential oils. It comes in a reusable glass jar with a cork lid. The candle is vegan and free from synthetic fragrances. Candle should be cream colored. Jar should be approximately 4 inches in diameter and taller than it is wide. Minimalist sticker label, textured.
`
  },
  {
    title: 'Plush Toy',
    image: '/images/exampleProducts/plushToy.png',
    description: `Plush toy 
A round plush toy shaped like a coin made from soft orange fabric with a dark brown Bitcoin logo on both sides. Approximately 12 inches in diameter and 6 inches thick. Cotton or polyester filling. Very soft. 
`
  },
  {
    title: 'Yoga Mat',
    image: '/images/exampleProducts/yogaMat.png',
    description: `Yoga Mat
Yoga mat, 1/2 inch thick, standard length and width. Rounded corners. Cream colored on one side, and attached printed design on the other. Recycled or eco-friendly material. 
`
  },
  {
    title: 'Eye Mask',
    image: '/images/exampleProducts/eyeMask.png',
    description: `Eye Mask
A weighted silk or satin eyemask filled with lavender and cooling gel. Gold color. Very soft. Hypoallergenic.
`
  },
  {
    title: 'Dad Hat',
    image: '/images/exampleProducts/dadHat.png',
    description: `A dark grey unstructured, low-profile dad hat. 100% cotton. Leather strap in the back with a sewed patch on the front.`
  },
  {
    title: 'Wooden Coaster',
    image: '/images/exampleProducts/woodenCoaster.png',
    description: `Wooden Coaster
Round, rustic, tree bark coasters made of unfinished wood. Slightly burnt, worn surface with visible grain. 0.5-1 inch thickness.
`
  }
];

const useLogic = ({
  value,
  suggestions,
  autocompleteAvailable,
  autocompleteText,
  setValue,
  nextButtonFunction,
  aiThinking,
  files,
  handleSkip,
  skippable,
  links
}: LogicProps) => {
  const [showLinkInput, setShowLinkInput] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [currentInterval, setCurrentInterval] = useState<NodeJS.Timeout | null>(
    null
  );

  const checkTextAreaButtonDisabled = (withCaption: boolean) => {
    // Enable if there are any files with captions
    const hasValidFiles = files.some((file) =>
      withCaption ? file.caption : true
    );

    // Enable if there are any links with both URL and caption
    const hasValidLinks = links?.some(
      (link) => link.url && (link.caption || !withCaption)
    );

    if (aiThinking) {
      return true;
    }

    // Enable if there's text OR valid files OR valid links
    return !(value || hasValidFiles || hasValidLinks);
  };

  const skipQuestion = () => {
    setIsButtonDisabled(true);
    handleSkip();
  };

  const handleExampleProductClick = (description: string) => {
    if (currentInterval) {
      clearInterval(currentInterval);
    }

    setIsButtonDisabled(true);

    const addCharactersGradually = async (text: string) => {
      let currentIndex = 0;
      const interval = await setInterval(() => {
        if (currentIndex <= text.length) {
          setValue(text.slice(0, currentIndex));
          currentIndex++;
        } else {
          clearInterval(interval);
          setIsButtonDisabled(false);
        }
      }, 10);

      setCurrentInterval(interval);
    };

    addCharactersGradually(description);
  };

  const handleUrlExampleProduct = async () => {
    const url = window.location.href;
    const urlParams = new URL(url);
    const exampleProduct = urlParams.searchParams.get('example_product');
    if (EXAMPLE_PRODUCTS.some((product) => product.title === exampleProduct)) {
      const product = EXAMPLE_PRODUCTS.find(
        (product) => product.title === exampleProduct
      );
      if (product) {
        handleExampleProductClick(product.description);
        //nextButtonFunction({ answer: product.description });
      }
    }
    window.history.replaceState({}, '', url.split('?')[0]);
  };

  useEffect(() => {
    handleUrlExampleProduct();
  }, []);

  return {
    isButtonDisabled,
    showLinkInput,
    setShowLinkInput,
    setIsButtonDisabled,
    checkTextAreaButtonDisabled,
    skipQuestion,
    handleExampleProductClick
  };
};

export default useLogic;
