import { useMutation } from '@tanstack/react-query';

import {
  updateUserProfile,
  UpdateUserProfilePayload
} from '../api/updateUserProfile';

export const useUpdateUserProfile = () => {
  return useMutation({
    mutationFn: async (payload: UpdateUserProfilePayload) => {
      return await updateUserProfile(payload);
    }
  });
};
