import React from 'react';

import useLogic from './logic';
import { UploadFileModalProps } from './props';

const UploadFileModal: React.FC<UploadFileModalProps> = ({
  onClose,
  addNewFile,
  isMultiple,
  isRFQFinalConfirmation,
  rfqId,
  onUploadSuccess
}) => {
  const {
    file,
    isDragOver,
    error,
    handleFileUpload,
    handleOutsideClick,
    handleDragOver,
    handleDragEnter,
    handleDragLeave,
    handleDrop
  } = useLogic({
    onClose,
    addNewFile,
    isRFQFinalConfirmation,
    rfqId,
    onUploadSuccess
  });

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50"
      onClick={handleOutsideClick}
    >
      <div className="bg-white px-2 pt-3 rounded-xl shadow-lg w-[600px] text-center animate-fadeIn">
        <div className="rounded-lg p-2 mb-2">
          <div>
            {file ? (
              <div>
                {Array.isArray(file) ? (
                  file.map((f, index) => (
                    <p key={index} className="text-green-600 font-medium">
                      {f.name}
                    </p>
                  ))
                ) : (
                  <p className="text-green-600 font-medium">{file.name}</p>
                )}
              </div>
            ) : (
              <div
                className="flex flex-col items-center w-full"
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <div
                  className={`w-full flex flex-col justify-center rounded-xl items-center border-[2.5px] p-8
                    ${
                      isDragOver
                        ? 'bg-border-light-gray-2 border-border-light-gray-2'
                        : 'bg-bg-light-gray border-dashed'
                    }
                    transition-all duration-300
                  `}
                >
                  <div className="bg-gray-200 p-2 rounded-full w-[60px] h-[60px] flex items-center justify-center mb-4">
                    <img
                      src="/icons/uploadFile.svg"
                      alt="uploadFile"
                      className="size-5"
                    />
                  </div>
                  <div className="text-body-md font-bold text-black mb-1">
                    Choose a file or drag it here
                  </div>
                  <div className="text-[14px] text-light-gray">
                    PDF, PNG, JPEG, WEBP, up to 25 mb
                  </div>
                  <div className="flex gap-3 mt-4">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onClose();
                      }}
                      className="px-4 py-2 text-p-cavela-blue border border-p-cavela-blue hover:bg-p-cavela-blue hover:text-white rounded-full transition-colors duration-200 font-title"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        document.getElementById('file-upload')?.click();
                      }}
                      className="px-4 py-2 text-white bg-p-cavela-blue border border-p-cavela-blue hover:bg-white hover:text-p-cavela-blue rounded-full transition-colors duration-200 font-title"
                    >
                      Browse file
                    </button>
                  </div>
                  {error && (
                    <p className="text-red-500 text-sm mt-2">{error}</p>
                  )}
                </div>
                <p className="text-light-gray mt-2 text-center text-sm font-400">
                  Other formats can be uploaded and will be shared with the
                  sourcing team.
                  <br />
                  Those files won't be analyzed live in this session.
                </p>
              </div>
            )}
            <input
              id="file-upload"
              type="file"
              className="hidden"
              onChange={handleFileUpload}
              multiple={isMultiple || false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadFileModal;
