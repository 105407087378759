import { useQuery } from '@tanstack/react-query';

import { getProductById } from '../api/getProductById';

export const useProductById = (id: string) => {
  const query = useQuery({
    queryKey: ['product', id],
    queryFn: async () => {
      const response = await getProductById(id);

      return response.data;
    },
    enabled: !!id
  });

  return query;
};
