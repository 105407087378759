import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import useFeatureFlag from 'src/hooks/useFeatureFlag';
import {
  API_URL,
  FF_RFQ_FILE_PROCESSING,
  FF_RFQ_LINK_PROCESSING
} from 'src/settings';
import { fetchWithAuth } from 'src/utils/fetchWithAuth';

import AnimatedText from 'src/components/AnimatedText/AnimatedText';
import CircularSpinner from 'src/components/CircularSpinner/CircularSpinner';
import BackToLinksAndFilesLink from 'src/components/RfqCreationFlow/BackToLinksAndFilesLink/BackToLinksAndFilesLink';
import LMLoading from 'src/components/RfqCreationFlow/LMLoading/LMLoading';
import LMTextarea from 'src/components/RfqCreationFlow/LMTextarea/LMTextarea';
import ProductInfo from 'src/components/RfqCreationFlow/ProductInfo/ProductInfo';
import ProductInfoProperty from 'src/components/RfqCreationFlow/ProductInfoProperty/ProductInfoProperty';
import RFQAIIcon from 'src/components/RfqCreationFlow/RFQAIIcon/RFQAIIcon';
import RFQBackHome from 'src/components/RfqCreationFlow/RFQBackHome/RFQBackHome';
import RFQFilesAndLinksStep from 'src/components/RfqCreationFlow/RFQFilesAndLinksStep/RFQFilesAndLinksStep';
import RFQFinalConfirmationStep from 'src/components/RfqCreationFlow/RFQFinalConfirmationStep/RFQFinalConfirmationStep';
import RFQFinalTransition from 'src/components/RfqCreationFlow/RFQFinalTransition/RFQFinalTransition';
import RFQProfileStep from 'src/components/RfqCreationFlow/RFQProfileStep/RFQProfileStep';
import RFQRestart from 'src/components/RfqCreationFlow/RFQRestart/RFQRestart';
import RFQTitle from 'src/components/RfqCreationFlow/RFQTitle/RFQTitle';
import SimpleModal from 'src/components/SimpleModal/SimpleModal';
import Toast from 'src/components/Toast/Toast';
import UploadFileModal from 'src/components/UploadFileModal/UploadFileModal';

import { Page } from '../../components';
import useLogic from './logic';
import { DocumentData } from './props';

import './styles.css';

type RFQStep =
  | 'FIRST_STEP'
  | 'PROFILE'
  | 'QUESTIONS'
  | 'FINAL_CONFIRMATION_STEP'
  | 'FINAL_STEP';
const TEXTAREA_STEPS: RFQStep[] = ['QUESTIONS'];
// Please note that although FINAL_CONFIRMATION_STEP is not a split step visually, it is from an implementation standpoint
const SPLIT_STEPS: RFQStep[] = [
  'FIRST_STEP',
  'QUESTIONS',
  'FINAL_CONFIRMATION_STEP'
];

export default function NewRfqFlow() {
  const navigate = useNavigate();

  // Add a counter to force re-renders when files change
  const [fileUpdateCounter, setFileUpdateCounter] = useState(0);
  const { pathname } = useLocation();

  const {
    isReadOnly,
    from,
    error,
    setError,
    isLoading,
    flowStarted,
    setFlowStarted,
    filesOrLinks,
    setFilesOrLinks,
    aiThinking,
    aiStep,
    getCurrentTextareaProps,
    textAreaNextButtonFunction,
    setTextareaValue,
    setImage,
    setRfq,
    aiWaitingResponse,
    document,
    designTransition,
    extraQuestions,
    image,
    isIncorrect,
    currentQuestionIndex,
    textareaValue,
    rfq,
    initialDocumentData,
    updateDocumentItem,
    showConfirmModal,
    setShowConfirmModal,
    removeDocumentItem,
    isAnEdit,
    links,
    addNewLink,
    updateLink,
    removeLink,
    updateLinkItem,
    updateFileItem,
    handleSkip,
    restartRfq,
    isReview,
    addNewFile,
    updateFile,
    files,
    removeFile,
    removeFileItem,
    showNewFileUploadModal,
    setShowNewFileUploadModal,
    showFinalScreen,
    setShowFinalScreen,
    productId,
    setProductId,
    lastAiWaitingResponse,
    quantity,
    setQuantity,
    checkFilesAndLinksNextButtonDisabled,
    profileIsConfirmed,
    setProfileIsConfirmed,
    showRemoveFileModal,
    setShowRemoveFileModal,
    fileToRemove,
    setFileToRemove,
    showRemoveLinkModal,
    setShowRemoveLinkModal,
    savedRemoveLinkFunction,
    setSavedRemoveLinkFunction
  } = useLogic();

  // Function to open the file upload modal
  const openFileUploadModal = () => {
    setShowNewFileUploadModal(true);
  };

  const nextButtonFunction = (options?: { answer?: string }) => {
    options && options.answer
      ? textAreaNextButtonFunction(true, {
          answer: options.answer
        })
      : textAreaNextButtonFunction(false);
  };

  const { isFeatureEnabled: isRFQFileProcessingEnabled } = useFeatureFlag(
    FF_RFQ_FILE_PROCESSING
  );
  const { isFeatureEnabled: isRFQLinkProcessingEnabled } = useFeatureFlag(
    FF_RFQ_LINK_PROCESSING
  );
  if (isReadOnly) {
    return <Navigate to="/" state={{ from }} replace />;
  }

  let currentStep: RFQStep = 'FIRST_STEP';
  if (showFinalScreen) {
    currentStep = 'FINAL_STEP';
  } else if (rfq?.document.name && !profileIsConfirmed && !isAnEdit) {
    currentStep = 'PROFILE';
  } else if (currentQuestionIndex >= 0 && !designTransition) {
    currentStep = 'QUESTIONS';
  } else if (designTransition) {
    currentStep = 'FINAL_CONFIRMATION_STEP';
  }

  if (currentStep === 'FINAL_STEP') {
    return (
      <div className="bg-black w-screen h-screen fixed top-0 left-0 flex z-0 overflow-hidden text-base">
        <div className="absolute inset-0 z-10 overflow-auto">
          <div className="flex flex-col text-white items-center justify-center min-h-screen">
            <h1 className="text-3xl font-bold text-center">
              Request Sent 🎉
              <br />
              Quotes will be shared in a few days.
            </h1>

            <div className="flex justify-center w-full mt-4">
              <div className="flex justify-center space-x-4">
                <button
                  className="bg-p-cavela-blue text-white py-2 px-8 rounded-full font-['Cabinet_Grotesk'] text-[16px] font-bold leading-[20px] transition-transform duration-300 ease-in-out hover:scale-105 text-center whitespace-nowrap overflow-hidden text-overflow-ellipsis"
                  style={{ fontWeight: 700 }}
                  onClick={
                    () =>
                      navigate(
                        `/products/${productId}/quotes?a=1`
                      ) /* a=1 starts the animation in the map page */
                  }
                >
                  Track Progress
                </button>
              </div>
            </div>

            {showConfirmModal && (
              <SimpleModal
                title="Back to Home"
                description="Do you want to leave this session? Your draft will be saved and you can come back to finish later."
                onClose={() => setShowConfirmModal(false)}
                onSave={() => {
                  if (pathname.includes('/v2')) {
                    navigate('/v2/products');
                  } else {
                    navigate('/');
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  const showTextArea = !filesOrLinks || currentStep === 'QUESTIONS';
  let descriptionText;
  if (showTextArea) {
    descriptionText = 'Write a description about your product.';
  } else {
    descriptionText =
      "Let's create your product!\nShare your exact product or similar ones.";
  }
  const currentQuestion = rfq?.questions[currentQuestionIndex];
  const title = currentQuestion?.question || descriptionText;
  const initialText = isAnEdit
    ? isReview
      ? "Let's review your quote request!"
      : "Let's finish this!"
    : "Let's get you started!\nShare information about your product or similar products.";
  const creatingNewRFQ = !isAnEdit && !isReview;

  const hasStartedFinalStep = aiStep > 0 || isReview;

  const showSingleAttribute = !isReview && currentQuestion; // && !aiThinking && !aiWaitingResponse;

  const aiErrorMsg = lastAiWaitingResponse
    ? lastAiWaitingResponse
    : 'Error creating the product';

  return (
    <Page loading={isLoading}>
      {currentStep === 'PROFILE' && (
        <div className="left-0 right-0 top-0 bottom-0 bg-white fixed flex flex-col space-between gap-2 text-base bg-white">
          <RFQProfileStep
            profileName={document.name}
            onRestart={restartRfq}
            setProfileIsConfirmed={setProfileIsConfirmed}
            setFlowStarted={setFlowStarted}
            rfqId={Number(rfq?.id)}
            image={image}
            setImage={setImage}
            setRfq={setRfq}
          />
        </div>
      )}

      {SPLIT_STEPS.includes(currentStep) && (
        <div
          className={`left-0 right-0 top-0 bottom-0 bg-white fixed ${!designTransition ? 'md:left-[50%]' : 'md:right-[50%]'}`}
        >
          {error ? (
            <Toast message={error} type="error" setError={setError} />
          ) : null}

          {!designTransition && (
            <div className="overflow-y-auto h-full flex flex-col">
              <div className="left-0 right-0 top-0 p-4 z-50">
                <div className="h-7 flex justify-between items-center mb-2">
                  <RFQBackHome />
                  {currentQuestionIndex > 0 && (
                    <RFQRestart
                      onRestart={restartRfq}
                      disabled={aiThinking || !!aiWaitingResponse}
                    />
                  )}
                </div>
              </div>

              <div className="flex-1 flex flex-col min-h-0">
                <div className="flex-1 flex flex-col items-center justify-center w-full">
                  <div
                    className={`flex ${currentQuestionIndex > 0 ? 'order-2 md:order-1' : ''} items-center justify-center mb-4 w-full max-w-[700px]`}
                  >
                    <div className="flex flex-col md:flex-row items-start mt-2 p-4 w-full">
                      <RFQAIIcon />
                      <div className="w-full flex-grow">
                        <div className="min-h-[250px] flex items-start justify-start lg:max-w-[500px] z-20">
                          <div className="w-full flex flex-col items-start flex-grow transition-opacity duration-300 ease-in-out">
                            {!flowStarted && !aiThinking && (
                              <RFQTitle text={initialText} />
                            )}
                            {filesOrLinks && !flowStarted && (
                              <>
                                <RFQFilesAndLinksStep
                                  files={files}
                                  links={links}
                                  removeFile={removeFile}
                                  updateFile={updateFile}
                                  updateLink={updateLink}
                                  removeLink={removeLink}
                                  aiThinking={aiThinking}
                                  isRFQLinkProcessingEnabled={
                                    isRFQLinkProcessingEnabled
                                  }
                                  isRFQFileProcessingEnabled={
                                    isRFQFileProcessingEnabled
                                  }
                                  addNewLink={addNewLink}
                                  openNewFileUploadModal={() =>
                                    setShowNewFileUploadModal(true)
                                  }
                                  onNext={() => {
                                    nextButtonFunction();
                                  }}
                                  checkFilesAndLinksNextButtonDisabled={
                                    checkFilesAndLinksNextButtonDisabled
                                  }
                                />
                                {isIncorrect === true && (
                                  <div className="w-full my-2 text-red-500 p-4 bg-red-100 rounded-lg animate-fadeIn">
                                    <span className="font-500">
                                      {aiErrorMsg}
                                    </span>
                                  </div>
                                )}
                              </>
                            )}
                            {flowStarted ? (
                              <>
                                {aiThinking ? (
                                  <div className="flex items-start justify-start w-full animate-fadeIn">
                                    <LMLoading
                                      small={false}
                                      messageOnLongWait={true}
                                    />
                                  </div>
                                ) : !!aiWaitingResponse ? (
                                  <h4 className="text-md font-500 text-black leading-[28px] whitespace w-full md:w-[65%]">
                                    <AnimatedText
                                      fullText={aiWaitingResponse}
                                      interval={10}
                                    />
                                  </h4>
                                ) : (
                                  <>
                                    {showTextArea &&
                                      TEXTAREA_STEPS.includes(currentStep) && (
                                        <LMTextarea
                                          {...getCurrentTextareaProps(title)}
                                          nextButtonFunction={
                                            nextButtonFunction
                                          }
                                          value={textareaValue}
                                          setValue={setTextareaValue}
                                          bottomText=""
                                          autocompleteAvailable={
                                            currentQuestionIndex > 0
                                          }
                                          isIncorrect={isIncorrect}
                                          withCaption={false}
                                          links={links}
                                          updateLink={updateLink}
                                          addNewLink={addNewLink}
                                          removeLink={removeLink}
                                          aiThinking={aiThinking}
                                          handleSkip={handleSkip}
                                          skippable={currentQuestionIndex > 0}
                                          showTextarea={true}
                                          error={!!error}
                                          addNewFile={addNewFile}
                                          openNewFileUploadModal={() =>
                                            setShowNewFileUploadModal(true)
                                          }
                                          updateFile={updateFile}
                                          files={files}
                                          removeFile={removeFile}
                                          aiErrorMsg={aiErrorMsg}
                                        >
                                          {currentQuestionIndex === 0 &&
                                            !aiThinking && (
                                              <BackToLinksAndFilesLink
                                                onClick={() => {
                                                  setFilesOrLinks(true);
                                                  setFlowStarted(false);
                                                }}
                                              />
                                            )}
                                        </LMTextarea>
                                      )}
                                  </>
                                )}
                              </>
                            ) : (
                              <div className="w-full">
                                {creatingNewRFQ &&
                                  files.length === 0 &&
                                  links.length === 0 && (
                                    <>
                                      <div>
                                        <button
                                          onClick={() => {
                                            setFlowStarted(true);
                                            setFilesOrLinks(false);
                                          }}
                                          className="text-p-cavela-blue hover:text-blue-700 text-sm font-600 mt-2 animate-fadeIn"
                                        >
                                          I don't have any links or files &gt;
                                        </button>
                                      </div>
                                    </>
                                  )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`w-full ${currentQuestionIndex > 0 ? 'order-1 sm:order-2' : ''}`}
                  >
                    <div className="border-r border-gray-200 bg-white w-auto h-auto rounded-none md:fixed md:left-0 md:right-[50%] md:top-0 md:bottom-0 relative">
                      {showSingleAttribute && (
                        <div className="flex items-center justify-center h-full w-full">
                          <div className="rounded-lg shadow-[0px_12px_80px_0px_rgba(0,0,0,0.08)] border border-border-gray-gray-2 p-5 space-y-3 animate-fadeIn w-[480px]">
                            <ProductInfoProperty
                              index={-1}
                              attribute={currentQuestion?.attribute}
                              value={
                                document[
                                  currentQuestion?.attribute as keyof DocumentData
                                ]
                              }
                              rfqCurrentFiles={
                                rfq?.files.filter((file) =>
                                  rfq?.files_at_attributes[
                                    currentQuestion?.attribute as keyof DocumentData
                                  ]?.includes(file.id)
                                ) || []
                              }
                              rfqLinks={rfq?.links || []}
                              aiThinking={aiThinking}
                              updateDocumentItem={updateDocumentItem}
                              removeDocumentItem={removeDocumentItem}
                              updateLinkItem={updateLinkItem}
                              updateFileItem={updateFileItem}
                              removeFileItem={removeFileItem}
                              setShowRemoveFileModal={setShowRemoveFileModal}
                              setFileToRemove={setFileToRemove}
                              setShowRemoveLinkModal={setShowRemoveLinkModal}
                              savedRemoveLinkFunction={savedRemoveLinkFunction}
                              setSavedRemoveLinkFunction={
                                setSavedRemoveLinkFunction
                              }
                              withCaption={false}
                            />
                          </div>
                        </div>
                      )}

                      {(isReview || !currentQuestion) && (
                        <ProductInfo
                          key={`product-info-${fileUpdateCounter}`}
                          document={document}
                          hasStarted={aiStep > 0}
                          designTransition={false}
                          rfqId={Number(rfq?.id)}
                          rfqName={rfq?.document.name || ''}
                          rfqLinks={rfq?.links || []}
                          rfqFiles={rfq?.files || []}
                          rfqAttributeFiles={rfq?.files_at_attributes || []}
                          updateFileItem={updateFileItem}
                          image={image}
                          setImage={setImage}
                          initialDocumentData={initialDocumentData}
                          updateDocumentItem={updateDocumentItem}
                          aiThinking={aiThinking || !!aiWaitingResponse}
                          removeDocumentItem={removeDocumentItem}
                          isReview={isReview}
                          updateLinkItem={updateLinkItem}
                          error={!!error}
                          setError={setError}
                          removeFileItem={removeFileItem}
                          setShowFinalScreen={setShowFinalScreen}
                          setProductId={setProductId}
                          quantity={quantity}
                          setQuantity={setQuantity}
                          showRemoveFileModal={showRemoveFileModal}
                          setShowRemoveFileModal={setShowRemoveFileModal}
                          fileToRemove={fileToRemove}
                          setFileToRemove={setFileToRemove}
                          showRemoveLinkModal={showRemoveLinkModal}
                          setShowRemoveLinkModal={setShowRemoveLinkModal}
                          savedRemoveLinkFunction={savedRemoveLinkFunction}
                          setSavedRemoveLinkFunction={
                            setSavedRemoveLinkFunction
                          }
                          openFileUploadModal={openFileUploadModal}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {currentStep === 'FINAL_CONFIRMATION_STEP' &&
            !hasStartedFinalStep && <RFQFinalTransition />}

          {currentStep === 'FINAL_CONFIRMATION_STEP' &&
            hasStartedFinalStep &&
            rfq && (
              <RFQFinalConfirmationStep
                key={`rfq-final-step-${fileUpdateCounter}`}
                showRemoveFileModal={showRemoveFileModal}
                setShowRemoveFileModal={setShowRemoveFileModal}
                fileToRemove={fileToRemove}
                setFileToRemove={setFileToRemove}
                showRemoveLinkModal={showRemoveLinkModal}
                setShowRemoveLinkModal={setShowRemoveLinkModal}
                savedRemoveLinkFunction={savedRemoveLinkFunction}
                setSavedRemoveLinkFunction={setSavedRemoveLinkFunction}
                openFileUploadModal={openFileUploadModal}
                document={document}
                hasStartedFinalStep={hasStartedFinalStep}
                extraQuestions={extraQuestions}
                rfqId={Number(rfq?.id)}
                rfqName={rfq?.document.name || ''}
                rfqLinks={rfq?.links || []}
                rfqFiles={rfq.files}
                rfqAttributeFiles={rfq.files_at_attributes}
                updateFileItem={updateFileItem}
                image={image}
                setImage={setImage}
                initialDocumentData={initialDocumentData}
                updateDocumentItem={updateDocumentItem}
                aiThinking={aiThinking || !!aiWaitingResponse}
                removeDocumentItem={removeDocumentItem}
                isReview={isReview}
                updateLinkItem={updateLinkItem}
                error={!!error}
                setError={setError}
                removeFileItem={removeFileItem}
                setShowFinalScreen={setShowFinalScreen}
                setProductId={setProductId}
                quantity={quantity}
                setQuantity={setQuantity}
              />
            )}
        </div>
      )}

      {showNewFileUploadModal && (
        <UploadFileModal
          onClose={() => {
            setShowNewFileUploadModal(false);
          }}
          addNewFile={addNewFile}
          isMultiple={currentQuestionIndex === 0}
          isRFQFinalConfirmation={currentStep === 'FINAL_CONFIRMATION_STEP'}
          rfqId={Number(rfq?.id)}
          onUploadSuccess={(updatedRfq) => {
            if (updatedRfq && rfq) {
              // Make a shallow copy of the current rfq and just update the files array
              const newRfq = { ...rfq };
              newRfq.files = updatedRfq.files || [];
              setRfq(newRfq);

              // Increment the counter to force re-renders
              setFileUpdateCounter((prev) => prev + 1);
            }
          }}
        />
      )}

      {showRemoveFileModal && (
        <SimpleModal
          title="Remove File"
          description="Do you want to remove this file? If yes, we will remove this file from the product request permanently."
          onClose={() => setShowRemoveFileModal(false)}
          onSave={() => {
            if (fileToRemove) {
              removeFileItem(fileToRemove);
            }
            setShowRemoveFileModal(false);
          }}
          leftButtonText="No, keep this file"
          rightButtonText="Yes, remove file"
        />
      )}

      {showRemoveLinkModal && (
        <SimpleModal
          title="Remove Link"
          description="Do you want to remove this link? If yes, we will remove this link from the product request permanently."
          leftButtonText="No, keep this link"
          rightButtonText="Yes, remove link"
          onClose={() => setShowRemoveLinkModal(false)}
          onSave={() => {
            if (savedRemoveLinkFunction) {
              savedRemoveLinkFunction();
            }

            setShowRemoveLinkModal(false);
          }}
        />
      )}
    </Page>
  );
}
