import { ArrowLeftIcon, PlusIcon, XMarkIcon } from '@heroicons/react/16/solid';
import { cn, Divider, Tooltip } from '@heroui/react';
import React, { useState } from 'react';

import { motion } from 'framer-motion';
import { SIDEBAR_NAV } from 'src/shared/config/sidebar';
import useMobileMenu from 'src/shared/store/useMobileMenu';
import useSidebar from 'src/shared/store/useSidebar';

import { ButtonV2, Nav } from 'src/shared/components/ui';

import AiButtonBorder from '../../AiButtonBorder';
import OrganizationMenu from '../OrganizationMenu';
import UserMenu from '../UserMenu';
import ProductsListNav from './ProductsListNav';
import SidebarNav from './SidebarNav';

interface SidebarProps {
  isOpen: boolean;
  isMobile?: boolean;
}

const PrivateSidebar: React.FC<SidebarProps> = ({ isOpen, isMobile }) => {
  const toggle = useSidebar((state) => state.toggle);
  const toggleMobileMenu = useMobileMenu((state) => state.toggle);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={cn(
        'border-r border-gray-3 fixed left-0 top-0 bottom-0 bg-white z-20',
        isMobile && 'relative h-full flex flex-col'
      )}
    >
      <motion.div
        layout
        initial={!isMobile && { width: !isOpen ? 72 : 240 }}
        animate={!isMobile && { width: !isOpen ? 72 : 240 }}
        className="flex flex-col h-full overflow-hidden"
      >
        <div className="flex-1 flex flex-col">
          <div className="p-4">
            <div className={cn(isMobile && 'flex items-start justify-between')}>
              <OrganizationMenu isOpen={isOpen} isMobile={isMobile} />
              {isMobile && (
                <ButtonV2
                  color="tertiary"
                  onPress={toggleMobileMenu}
                  isIconOnly
                >
                  <XMarkIcon className="size-4 text-primary-text" />
                </ButtonV2>
              )}
            </div>

            <ProductsListNav isOpen={isOpen} />
            <SidebarNav navItems={SIDEBAR_NAV} isOpen={isOpen} />

            <Tooltip
              color="secondary"
              content="New product"
              placement="right-start"
              classNames={{
                content: 'bg-white rounded-lg !border-none h-8 top-1',
                base: 'rounded-sm !border-none top-1'
              }}
              shadow="lg"
              hidden={isOpen}
              closeDelay={0}
            >
              <ButtonV2
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                color="tertiary"
                className={cn(
                  'justify-center relative p-0 min-w-max font-medium mt-2 overflow-hidden'
                )}
                fullWidth
              >
                {isHovered && <AiButtonBorder isIconOnly={isOpen} />}

                <motion.div className="absolute inset-0 top-[3px] left-[3px] bottom-[3px] rounded-lg right-[3px] bg-white z-[2]" />

                <div
                  className={cn(
                    'absolute left-[11px] z-[3] transition-left',
                    isOpen && 'left-11'
                  )}
                >
                  <PlusIcon className="size-4" />
                </div>

                <motion.div
                  initial={!isOpen && { maxWidth: 0, opacity: 0 }}
                  animate={{
                    maxWidth: isOpen ? '100%' : 0,
                    opacity: isOpen ? 1 : 0
                  }}
                  transition={{ duration: 0.3, ease: 'easeInOut' }}
                  className="overflow-hidden left-16 whitespace-nowrap absolute z-[3]"
                >
                  New product
                </motion.div>
              </ButtonV2>
            </Tooltip>
          </div>
          {!isMobile && (
            <div className="mt-auto p-4">
              <Tooltip
                color="secondary"
                content="Open panel"
                placement="right-start"
                classNames={{
                  content: 'bg-white rounded-lg !border-none h-8 top-1',
                  base: 'rounded-sm !border-none top-1'
                }}
                shadow="lg"
                hidden={isOpen}
                closeDelay={0}
              >
                <Nav
                  className={cn('justify-start relative p-0 min-w-max')}
                  onPress={toggle}
                  fullWidth
                >
                  <motion.div
                    animate={{ rotate: isOpen ? 0 : -180 }}
                    className="absolute left-3"
                  >
                    <ArrowLeftIcon className="size-4" />
                  </motion.div>
                  <motion.div
                    initial={!isOpen && { maxWidth: 0, opacity: 0 }}
                    animate={{
                      maxWidth: isOpen ? '100%' : 0,
                      opacity: isOpen ? 1 : 0
                    }}
                    transition={{ duration: 0.3, ease: 'easeInOut' }}
                    className="overflow-hidden left-10 whitespace-nowrap absolute"
                  >
                    <span>Close panel</span>
                  </motion.div>
                </Nav>
              </Tooltip>
            </div>
          )}
        </div>

        <Divider className="bg-gray-3" />

        <UserMenu isOpen={isOpen} />
      </motion.div>
    </div>
  );
};

export default PrivateSidebar;
