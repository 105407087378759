import { useEffect, useState } from 'react';

function useMediaQuery(query?: string) {
  const defaultQuery = '(max-width: 500px)';
  const mediaQuery = query || defaultQuery;

  const [matches, setMatches] = useState<boolean | null>(null);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const mediaQueryList = window.matchMedia(mediaQuery);
    const updateMatches = () => setMatches(mediaQueryList.matches);

    updateMatches();
    mediaQueryList.addEventListener('change', updateMatches);

    return () => mediaQueryList.removeEventListener('change', updateMatches);
  }, [mediaQuery]);

  return matches;
}

export default useMediaQuery;
