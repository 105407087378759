import React from 'react';

interface TextProps {
  paragraph?: boolean;
  control?: boolean;
  title?: boolean;
  heading?: boolean;
  small?: boolean;
  children: React.ReactNode;
  color?: string;
  style?: React.CSSProperties;
  className?: string;
}

const Text: React.FC<TextProps> = ({
  paragraph = false,
  control = false,
  title = false,
  heading = false,
  small = false,
  color = 'black',
  style = {},
  className,
  children
}) => (
  <span className={className} style={{ color }}>
    {title ? (
      <h3 className="font-title text-4xl m-0 p-0" style={style}>
        {children}
      </h3>
    ) : heading ? (
      <h3 className="font-display text-3xl m-0 p-0" style={style}>
        {children}
      </h3>
    ) : control ? (
      <p className="font-display m-0 p-0" style={style}>
        {children}
      </p>
    ) : paragraph ? (
      <p className="font-body text-sm m-0 p-0 leading-8" style={style}>
        {children}
      </p>
    ) : small ? (
      <p className="font-body text-sm m-0 p-0" style={style}>
        {children}
      </p>
    ) : (
      <p className="font-body m-0 p-0" style={style}>
        {children}
      </p>
    )}
  </span>
);

export default Text;
