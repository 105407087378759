import React from 'react';

import {
  FormatListBulletedRounded,
  GridViewRounded
} from '@mui/icons-material';

interface ToggleProps {
  toggled: boolean;
}

const Toggle: React.FC<ToggleProps> = ({ toggled = false }) => (
  <div className="flex mx-auto bg-white rounded-lg overflow-hidden h-[3rem] max-w-[7rem]">
    {toggled ? (
      <>
        <button className="flex items-center justify-center m-1 py-1 px-4 rounded-lg bg-transparent focus:outline-none max-w-[3rem]">
          <GridViewRounded fontSize="sm" />
        </button>
        <button className="flex items-center justify-center m-1 py-1 px-4 rounded-lg bg-[#D3D5D9] focus:outline-none max-w-[3rem]">
          <FormatListBulletedRounded fontSize="sm" />
        </button>
      </>
    ) : (
      <>
        <button className="flex items-center justify-center m-1 py-1 px-4 rounded-lg bg-[#D3D5D9] focus:outline-none max-w-[3rem]">
          <GridViewRounded fontSize="sm" />
        </button>
        <button className="flex items-center justify-center m-1 py-1 px-4 rounded-lg bg-transparent focus:outline-none max-w-[3rem]">
          <FormatListBulletedRounded fontSize="sm" />
        </button>
      </>
    )}
  </div>
);

export default Toggle;
