import { useQuery } from '@tanstack/react-query';

import authService from '../services/authService';
import { captureException, setUser } from '../services/reporting';
import { BACKEND_URL } from '../settings';
import { CustomError } from '../types/errorTypes';
import { Organization, OrganizationMember } from '../types/Organization';
import { fetchWithAuth } from '../utils/fetchWithAuth';
import useOrganizationStore from './useOrganizationStore';

export const findUserInOrganization = (
  organization: Organization | null,
  userId: number
): OrganizationMember | null => {
  if (!organization?.members) return null;
  return organization.members.find((m) => m.id === userId) || null;
};

export interface UserInfo {
  id: string;
  email: string;
  emailNotifications: boolean;
  username: string;
  firstName?: string;
  lastName?: string;
  featureFlags: string[];
  impersonating: ImpersonatingInfo | null;
  settings?: {
    [key: string]: any;
  };
  isStaff?: boolean;
  language: string;
  company_name?: string;
  addresses?: Address[];
  avatar?: string;
  organizations?: Organization[];
}

export interface Address {
  name: string;
  line_1: string;
  line_2: string;
  city: string;
  state: string;
  zip_code: string;
  country: string;
  contact_name: string;
  contact_phone: string;
  is_default?: number;
}

export interface ImpersonatingInfo {
  id: string;
  username: string;
}

function userMapper(data: any): UserInfo {
  const uniqueOrganizations =
    data?.organizations?.reduce((acc: Organization[], org: Organization) => {
      const existingOrgIndex = acc.findIndex((o) => o.id === org.id);
      if (existingOrgIndex === -1) {
        // Organization not found, add it
        acc.push(org);
      } else if (
        org.role === 'admin' &&
        acc[existingOrgIndex].role === 'member'
      ) {
        // Replace member role with admin role
        acc[existingOrgIndex] = org;
      }
      return acc;
    }, []) || [];

  // Sort organizations by name
  uniqueOrganizations.sort((a: Organization, b: Organization) =>
    a.name.localeCompare(b.name)
  );

  return {
    id: data.id,
    email: data.email,
    emailNotifications: data.email_notifications,
    username: data.username,
    firstName: data.first_name,
    lastName: data.last_name,
    featureFlags: data.featureflags,
    impersonating: data.impersonating,
    settings: data.settings || {},
    isStaff: data.is_staff || false,
    language: data?.language || 'en',
    company_name: data?.company_name || '',
    addresses: data?.addresses || [],
    avatar: data?.avatar || '',
    organizations: uniqueOrganizations
  };
}

export interface UseUserInfoReturn {
  userInfo: UserInfo | null;
  isLoading: boolean;
  error: string | null;
  refetch: () => void;
}

export const USER_URL = `${BACKEND_URL}/api/me`;

const fetchUserInfo = async () => {
  try {
    const response = await fetchWithAuth(USER_URL);
    if (response.status === 401) {
      await authService.logout();
      throw new CustomError({
        message: `expiredCredentials`,
        statusCode: response.status
      });
    } else if (!response.ok) {
      throw new CustomError({
        message: `generic`,
        statusCode: response.status
      });
    }
    const data = await response.json();
    const mappedUser = userMapper(data);

    setUser({
      id: data.id,
      email: data.email,
      username: data.username,
      impersonating: data.impersonating
    });

    const {
      selectedOrganization,
      setSelectedOrganization,
      initFromUrlOrDefault
    } = useOrganizationStore.getState();

    if (
      !initFromUrlOrDefault(mappedUser.organizations) &&
      selectedOrganization
    ) {
      const updatedOrg = mappedUser.organizations?.find(
        (org) => org.id === selectedOrganization.id
      );
      if (updatedOrg) {
        setSelectedOrganization(updatedOrg);
      }
    }

    return mappedUser;
  } catch (error: any) {
    const extra = {
      statusCode: error.statusCode
    };
    captureException({ error, extra });

    throw error;
  }
};

const useUserInfo = (): UseUserInfoReturn => {
  const { data, isLoading, refetch, error } = useQuery({
    queryKey: ['me'],
    queryFn: async () => {
      try {
        return await fetchUserInfo();
      } catch (error) {
        // this will be better once we apply axios interceptors
        console.error('useUserInfo fetch failed:', error);
        return Promise.reject(error);
      }
    },
    retry: false
  });

  return {
    userInfo: data ? (data as UserInfo) : null,
    isLoading,
    error: error ? error.message : null,
    refetch
  };
};

export default useUserInfo;
