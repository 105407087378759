import React, { useId, useState } from 'react';

import { API_URL } from 'src/settings';
import { fetchWithAuth } from 'src/utils/fetchWithAuth';

const ALLOWED_IMAGE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/gif',
  'image/webp'
];
const MAX_FILE_SIZE_MB = 25;

interface ImageUploaderProps {
  rfqId: number;
  onImageUploaded: (imageUrl: string) => void;
  buttonText?: string;
  className?: string;
  onUploadSuccess?: (imageUrl: string, response: any) => void;
  iconOnly?: boolean;
  position?: string;
}

export default function ImageUploader({
  rfqId,
  onImageUploaded,
  buttonText = 'Upload image',
  className = '',
  onUploadSuccess,
  iconOnly = false,
  position = 'relative'
}: ImageUploaderProps) {
  const [error, setError] = useState<string | null>(null);
  const uniqueId = useId();
  const inputId = `image-uploader-input-${uniqueId}`;

  const validateFile = (file: File): boolean => {
    if (!ALLOWED_IMAGE_TYPES.includes(file.type)) {
      setError('Please upload a PNG, JPEG, GIF, or WEBP image');
      return false;
    }

    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      setError(`File size must be less than ${MAX_FILE_SIZE_MB}MB`);
      return false;
    }

    setError(null);
    return true;
  };

  const handleFileUpload = async (file: File) => {
    if (!validateFile(file)) return;

    try {
      const formData = new FormData();
      formData.append('caption', file.name);
      formData.append('files', file);

      const uploadResponse = await fetchWithAuth(
        `${API_URL}/api/rfqs/${rfqId}/cover-art`,
        {
          method: 'POST',
          body: formData
        }
      );

      if (!uploadResponse.ok) {
        throw new Error('Failed to upload image');
      }

      const uploadData = await uploadResponse.json();

      // Notify parent component about the new image
      onImageUploaded(uploadData.image);

      // Call additional success callback if provided
      if (onUploadSuccess) {
        onUploadSuccess(uploadData.image, uploadData);
      }
    } catch (err) {
      setError('Failed to upload image. Please try again.');
    }
  };

  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      handleFileUpload(file);
    }
    // Reset the input value to allow uploading the same file again if needed
    event.target.value = '';
  };

  return (
    <div className={`flex flex-col ${position}`}>
      <input
        type="file"
        id={inputId}
        accept={ALLOWED_IMAGE_TYPES.join(',')}
        onChange={handleFileInput}
        className="hidden"
      />
      {iconOnly ? (
        <label
          htmlFor={inputId}
          className={`relative w-[36px] h-[36px] bg-white rounded-full shadow-sm cursor-pointer flex items-center justify-center transition-all duration-300 ease-in-out hover:bg-gray-50 hover:shadow hover:scale-105 active:scale-95 ${className}`}
          title={buttonText}
        >
          <span className="absolute inset-[-8px]"></span>
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="36" height="36" rx="18" fill="white" />
            <path
              d="M10.5 22.55V25.0833C10.5 25.3167 10.6833 25.5 10.9167 25.5H13.45C13.5583 25.5 13.6667 25.4583 13.7417 25.375L22.8417 16.2833L19.7167 13.1583L10.625 22.25C10.5417 22.3333 10.5 22.4333 10.5 22.55ZM25.2583 13.8667C25.5833 13.5417 25.5833 13.0167 25.2583 12.6917L23.3083 10.7417C22.9833 10.4167 22.4583 10.4167 22.1333 10.7417L20.6083 12.2667L23.7333 15.3917L25.2583 13.8667Z"
              fill="black"
            />
          </svg>
        </label>
      ) : (
        <label
          htmlFor={inputId}
          className={`px-4 py-2 text-sm border border-p-cavela-blue text-p-cavela-blue rounded-full cursor-pointer flex items-center justify-center transition-all duration-300 ease-in-out hover:scale-110 active:scale-95 ${className}`}
        >
          {buttonText}
        </label>
      )}
      {error && <div className="text-xs text-red-500 mt-1">{error}</div>}
    </div>
  );
}
