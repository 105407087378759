import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import AuthContext from 'src/context/Auth';
import { MappedProductDetail } from 'src/types/ProductTypes';
import { format } from 'timeago.js';

import { Toggle } from 'src/shared/components/ui';

import { SectionWrapper } from '../../components';
import useProductsList from '../../hooks/useProductstList';
import ProductCard from '../ProductCard/ProductCard';
import ProductItem from '../ProductItem/ProductItem';
import useLogic from '../RfqDisplay/logic';
import RfqDisplay from '../RfqDisplay/RfqDisplay';

type StageId =
  | 'rfq_received'
  | 'rfq_sent'
  | 'quotes_received'
  | 'samples_ordered'
  | 'samples_produced'
  | 'order_produced';

const stages = {
  rfq_received: {
    variant: 'in-progress',
    text: 'Quoting'
  },
  rfq_sent: {
    variant: 'in-progress',
    text: 'Quoting'
  },
  quotes_received: {
    variant: 'in-progress',
    text: 'Quoting'
  },
  samples_ordered: {
    variant: 'in-progress',
    text: 'Sampling'
  },
  samples_produced: {
    variant: 'in-progress',
    text: 'Sampling'
  },
  order_produced: {
    variant: 'in-progress',
    text: 'Ordered'
  }
};

const stageNodes = ['Quoting', 'Sampling', 'Ordered'];

const ProductsDisplay = () => {
  const { userInfo } = useContext(AuthContext);

  const [isRfqsEmpty, setIsRfqsEmpty] = useState(false);
  const [isListView, setIsListView] = useState(false);
  const { productsList, loading: productListLoading } = useProductsList();
  const {
    rfqs,
    redirectToRfq,
    isLoading: rfqLoading
  } = useLogic({ setIsRfqsEmpty });

  if (productListLoading || rfqLoading) {
    return null;
  }

  const tomorrow = new Date();

  tomorrow.setDate(tomorrow.getDate() + 1);

  const onClickToggle = () => setIsListView(!isListView);

  const createProductCard = ({
    product,
    isCard = true
  }: {
    product: MappedProductDetail;
    isCard: boolean;
  }) => {
    const { id = 'rfq_received', estimatedDate } =
      product.stages.find(({ status }) => status !== 'done') || {};

    const isComplete = product.stages.every(
      ({ status = '' }) => status === 'done'
    );

    const estDate = new Date(estimatedDate || 0);
    const isValidDate = Date.now() < +estDate;

    const { text } = stages[id as StageId] || {};
    const milestone = isValidDate && estimatedDate && format(estDate);
    const stageLabel = isComplete ? stages.order_produced.text : text;
    const stageIndex = stageNodes.indexOf(stageLabel);

    const getStatus = () => {
      if (!product.last_relevant_update) return 'in-progress';

      switch (product.last_relevant_update.update_type) {
        case 'block':
          return 'action-required';
        default:
          return product.last_relevant_update.relevant
            ? 'new-update'
            : 'in-progress';
      }
    };

    const cardProps = {
      href: `/products/${product.productId}/updates`,
      name: product.productName,
      imgSrc: product.image || '',
      status: getStatus(),
      stage: stageIndex,
      stageLabels: [stageLabel],
      milestone: milestone,
      style: {
        color: 'black'
      },
      createdAt: product.createdAt
    };

    return isCard ? (
      <div key={product.productId} className="hover:animate-hover">
        <ProductCard {...cardProps} />
      </div>
    ) : (
      <Link
        to={`/products/${product.productId}/updates`}
        key={`product-item-link-${product.productId}`}
        className="block transition-colors"
      >
        <ProductItem
          key={`product-item-${product.productId}`}
          {...cardProps}
          href={`/products/${product.productId}/updates`}
          style={{
            ...cardProps.style,
            borderBottom: '1px solid var(--mantine-color-cavela-shadow-2)'
          }}
        />
      </Link>
    );
  };

  const products = productsList.map((product) =>
    createProductCard({ product, isCard: !isListView })
  );

  const isEmpty = products.length === 0 && isRfqsEmpty;
  //const isEmpty = true;

  const cards = isListView ? (
    <div className="w-full">{products}</div>
  ) : (
    <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full max-w-6xl mx-auto">
      {products}
    </div>
  );

  const table = (
    <table className="flex flex-col bg-white w-full min-w-[60rem] max-w-6xl mx-auto rounded-lg shadow-md overflow-hidden">
      <thead>
        <tr
          className="flex gap-4 items-start justify-start text-left min-w-full p-4"
          style={{
            borderBottom: '1px solid var(--mantine-color-cavela-shadow-2)'
          }}
        >
          <th className="min-w-80 uppercase text-xs font-500 text-[var(--mantine-color-cavela-shadow-7)]">
            Product name
          </th>
          <th className="w-24 max-w-[25%] uppercase text-xs font-500 text-[var(--mantine-color-cavela-shadow-7)]">
            Image
          </th>
          <th className="min-w-[25%] uppercase text-xs font-500 text-[var(--mantine-color-cavela-shadow-7)]">
            Stage
          </th>
          <th className="w-24 max-w-[25%] uppercase text-xs font-500 text-[var(--mantine-color-cavela-shadow-7)]">
            Created At
          </th>
          <th className="min-w-40 max-w-[25%] uppercase text-xs font-500 text-[var(--mantine-color-cavela-shadow-7)]">
            Status
          </th>
        </tr>
      </thead>
      <tbody>{products}</tbody>
    </table>
  );

  return (
    <>
      {isEmpty && !productListLoading ? (
        <div
          data-testid="products-display"
          className="size-full flex items-center md:justify-center px-4"
        >
          <div className="h-full md:h-auto flex flex-col w-full md:w-[900px]">
            <div className="h-16 flex items-center shrink-0">
              <span className="text-2xl font-title text-black">
                Welcome to Cavela, {userInfo?.username}👋!
              </span>
            </div>
            <div
              className="h-[460px] w-full md:w-[900px] rounded-xl overflow-hidden text-center shrink-0"
              style={{
                backgroundImage:
                  'url(/images/emptyProductState/background.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <div className="size-full flex flex-col items-center justify-between pt-8 bg-black bg-opacity-20">
                <span className="font-title text-white text-2xl">
                  Let the virtual agent guide you through your sourcing process
                </span>
                <Link
                  to="/new-rfq"
                  className="h-7 shrink-0 px-8 py-5 bg-p-cavela-blue flex items-center justify-center rounded-full mb-[110px]"
                >
                  <span className="font-title text-lg text-white">
                    Create a new product
                  </span>
                </Link>
                <img className="" src="/images/emptyProductState/ios.svg" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="w-full">
            <span className="text-black text-lg font-600">Products</span>
          </div>
          <div className="my-4 self-start" onClick={onClickToggle}>
            <Toggle toggled={isListView} />
          </div>
          <SectionWrapper testId="products-display">
            <h3 className="text-2xl font-extrabold text-black mb-4 grid gap-4 w-full max-w-6xl mx-auto ">
              <span className="text-md text-black font-500">Sourcing</span>
            </h3>
            {!productListLoading && (isListView ? table : cards)}
            <div className="mt-10">
              <RfqDisplay
                isListView={isListView}
                setIsRfqsEmpty={setIsRfqsEmpty}
                rfqs={rfqs}
                redirectToRfq={redirectToRfq}
              />
            </div>
          </SectionWrapper>
        </>
      )}
    </>
  );
};

export default ProductsDisplay;
