'use client';

import { useCallback, useEffect, useRef, useState } from 'react';

import { AnimatedTextProps } from './props';

import './styles.css';

const AnimatedText = ({
  fullText,
  interval = 100,
  showUnderline = false,
  delay = 0,
  onEnd
}: AnimatedTextProps) => {
  const intervalIdRef = useRef<NodeJS.Timeout>();
  const animationCompletedRef = useRef(false);

  const [text, setText] = useState('');

  const addDelay = useCallback(async () => {
    await new Promise((resolve) => setTimeout(resolve, delay));
  }, [delay]);

  const renderFormattedText = (text: string) => {
    return text.split(/(&b&.*?&b&)/).map((part, index) => {
      if (part.startsWith('&b&') && part.endsWith('&b&')) {
        return <b key={index}>{part.slice(3, -3)}</b>;
      }
      return part;
    });
  };

  const startAnimation = useCallback(async () => {
    if (animationCompletedRef.current) {
      setText(fullText);
      return;
    }

    await addDelay();
    let i = 0;
    const intervalId = setInterval(() => {
      const currentText = fullText.slice(0, i);
      const lastOpenTagIndex = currentText.lastIndexOf('&b&');
      const lastCloseTagIndex = currentText.lastIndexOf(
        '&b&',
        lastOpenTagIndex - 1
      );

      if (lastOpenTagIndex > lastCloseTagIndex && lastCloseTagIndex !== -1) {
        setText(currentText.slice(0, lastOpenTagIndex));
      } else {
        setText(currentText);
      }

      i++;
      if (i > fullText.length) {
        clearInterval(intervalId);
        animationCompletedRef.current = true;
        onEnd?.();
      }
    }, interval);
    intervalIdRef.current = intervalId;
  }, [addDelay, interval, fullText, onEnd]);

  useEffect(() => {
    startAnimation();
    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, [startAnimation]);

  return (
    <span>
      {renderFormattedText(text)}
      {showUnderline ? <span className="animate-pulse">|</span> : null}
    </span>
  );
};

export default AnimatedText;
