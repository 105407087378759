import { XMarkIcon } from '@heroicons/react/16/solid';
import { DocumentIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

import ButtonV2 from '../ui/Button/v2';

interface FileAttachmentProps {
  file: File;
  onRemove?: () => void;
}

const FileAttachment: React.FC<FileAttachmentProps> = ({ file, onRemove }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative max-w-fit"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ButtonV2
        className="p-1 pr-2 relative hover:bg-white min-w-36 justify-start"
        startContent={
          <div className="bg-gray-2 size-10 flex items-center justify-center rounded-xl">
            <DocumentIcon className="size-5 text-primary" />
          </div>
        }
        size="xl"
        color="tertiary"
      >
        {file.name}
      </ButtonV2>

      {isHovered && onRemove && (
        <ButtonV2
          onPress={onRemove}
          size="xs"
          className="bg-black absolute -right-2 -top-2 w-5"
          isIconOnly
        >
          <XMarkIcon className="size-4 text-white" />
        </ButtonV2>
      )}
    </div>
  );
};

export default FileAttachment;
