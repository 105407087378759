import { useQuery } from '@tanstack/react-query';

import { getProducts } from '../api/getProducts';

export const useProducts = () => {
  const query = useQuery({
    queryKey: ['products-all'],
    queryFn: async () => {
      const response = await getProducts();

      const sortedProducts = response.data.sort((a, b) =>
        new Date(a.detail.created_at) < new Date(b.detail.created_at) ? 1 : -1
      );

      return sortedProducts;
    }
  });

  return query;
};
