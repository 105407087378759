import { Link as RouterLink } from 'react-router-dom';

import { Text } from 'src/shared/components/ui';

import { Text as MantineText, Title } from '@mantine/core';

import { Link } from '../components';

export const pRegular = (props: any) => <MantineText {...props} />;
export const pSmall = (props: any) => (
  <MantineText component="p" size="sm" {...props} />
);

// Helper function to process internal URLs
const processInternalUrl = (url: string): string => {
  try {
    const urlObj = new URL(url);
    const currentOrigin = window.location.origin;

    // Check if it's an internal URL (same origin as current window)
    if (urlObj.origin === currentOrigin) {
      // Create new URLSearchParams without auth_token
      const searchParams = new URLSearchParams(urlObj.search);
      searchParams.delete('auth_token');

      // Combine pathname with filtered search params
      const newSearch = searchParams.toString();
      return `${urlObj.pathname}${newSearch ? `?${newSearch}` : ''}`;
    }
  } catch (e) {
    // If URL parsing fails, return original URL
    return url;
  }

  return url;
};

export const customAnchor = (props: any) => {
  const processedHref = processInternalUrl(props.href);

  return (
    <Link
      underline="none"
      className="text-sm"
      {...props}
      href={processedHref}
      target="_self"
    >
      {props.children}
    </Link>
  );
};

export const quoteMarkdown = (props: any) => <Text small {...props} />;
export const h3Title = (props: any) => (
  <Title order={3} {...props} textWrap="pretty" />
);
export const mdNavLink = (props: any) => (
  <RouterLink to={processInternalUrl(props.href)} {...props} />
);
export const mdTitle = (props: any) => <Title {...props} textWrap="pretty" />;
