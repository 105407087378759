import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import i18next from 'i18next';

import { ExtraPropertyFieldProps } from './props';

const useLogic = ({ question, setQuestionValue }: ExtraPropertyFieldProps) => {
  const from = useLocation();
  const { t } = i18next;
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const isReview = from.search.includes('review');

  useEffect(() => {
    if (question?.answer) {
      setSelectedOptions(
        Array.isArray(question?.answer) ? question.answer : [question.answer]
      );
    }
  }, [question]);

  const handleOptionChange = (option: string) => {
    if (
      question?.question_type === 'single_choice' ||
      question?.question_type === 'address'
    ) {
      setSelectedOptions([option]);
      setQuestionValue(option);
    } else {
      const updatedOptions = selectedOptions.includes(option)
        ? selectedOptions.filter((item) => item !== option)
        : [...selectedOptions, option];
      setSelectedOptions(updatedOptions);
      setQuestionValue(updatedOptions);
    }
  };

  return {
    t,
    selectedOptions,
    handleOptionChange,
    isReview
  };
};

export default useLogic;
