import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { KeyboardArrowLeftRounded } from '@mui/icons-material';

import SimpleModal from 'src/components/SimpleModal/SimpleModal';

interface RFQBackHomeProps {
  className?: string;
}

export default function RFQBackHome({ className = '' }: RFQBackHomeProps) {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <>
      <span
        className={`flex items-center hover:underline cursor-pointer ${className}`}
        onClick={() => setShowConfirmModal(true)}
      >
        <KeyboardArrowLeftRounded />
        <span>Back to Home</span>
      </span>

      {showConfirmModal && (
        <SimpleModal
          title="Back to Home"
          description="Do you want to leave this session? Your draft will be saved and you can come back to finish later."
          onClose={() => setShowConfirmModal(false)}
          onSave={() => {
            if (pathname.includes('/v2')) {
              navigate('/v2/products');
            } else {
              navigate('/');
            }
          }}
        />
      )}
    </>
  );
}
