import { useQuery } from '@tanstack/react-query';

import getProductUpdates from '../api/getProductUpdates';

const useProductUpdates = (id: string, product_id: string) => {
  const query = useQuery({
    queryKey: ['product-updates', id, product_id],
    queryFn: async () => {
      const response = await getProductUpdates(id, product_id);

      return response.data;
    },
    enabled: !!id && !!product_id
  });

  return query;
};

export default useProductUpdates;
