import {
  ArchiveBoxIcon,
  GlobeAmericasIcon,
  InformationCircleIcon,
  ShoppingCartIcon
} from '@heroicons/react/20/solid';
import { useDisclosure } from '@heroui/react';

import { format } from 'date-fns';
import { useAuthStore } from 'src/entities/user/store/useAuthStore';
import UpdateAccountAvatar from 'src/features/account/account-avatar-edit/ui/UpdateAccountAvatar';
import AccountFormModal from 'src/features/account/account-edit/ui/AccountFormModal';
import AccountNotifications from 'src/features/account/account-notifications/ui/AccountNotifications';
import { SourcingStats } from 'src/shared/components';

import { ButtonV2, Typography } from 'src/shared/components/ui';

// needs to come from backend
const sourcingItems = [
  {
    title: 'Products created',
    value: 5,
    icon: <ArchiveBoxIcon className="size-5" />
  },
  {
    title: 'Orders created',
    value: 0,
    icon: <ShoppingCartIcon className="size-5" />
  },
  {
    title: 'Sourcing from',
    value: 2,
    icon: <GlobeAmericasIcon className="size-5" />
  }
];

const AccountPage = () => {
  const user = useAuthStore((state) => state.user);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return (
    <div className="h-full relative">
      <div className="border-b border-gray-4 bg-white">
        <div className="py-4 px-6 max-w-[1200px] mx-auto">
          <Typography as="h1" variant="h2" weight="bold">
            Account
          </Typography>
        </div>
      </div>
      <div className="flex flex-col gap-9 py-4 px-6 max-w-[1200px] mx-auto">
        <div className="flex flex-col gap-5">
          <UpdateAccountAvatar user={user} />

          <Typography variant="h2" as="h2" weight="bold">
            {(user?.firstName &&
              user?.lastName &&
              `${user?.firstName} ${user?.lastName}`) ||
              user?.username}
          </Typography>

          <div className="mobile:grid grid-cols-2 mobile:items-start mobile:gap-4 flex items-center gap-12">
            <div>
              <Typography
                variant="body-2"
                as="p"
                className="text-secondary-text"
              >
                Created on
              </Typography>
              <Typography
                className="mt-[2px]"
                variant="body-2"
                as="p"
                weight="medium"
              >
                {format(new Date(), 'MMM dd, yyyy')}
              </Typography>
            </div>

            <div>
              <Typography
                variant="body-2"
                as="p"
                className="text-secondary-text"
              >
                User ID
              </Typography>
              <Typography
                className="mt-[2px]"
                variant="body-2"
                as="p"
                weight="medium"
              >
                {user?.id}
              </Typography>
            </div>

            <div>
              <Typography
                variant="body-2"
                as="p"
                className="text-secondary-text"
              >
                Organizations
              </Typography>
              <Typography
                className="mt-[2px]"
                variant="body-2"
                as="p"
                weight="medium"
              >
                {user?.organizations?.length}
              </Typography>
            </div>
          </div>
        </div>

        <SourcingStats items={sourcingItems} />

        <div>
          <Typography variant="h4" weight="bold">
            Profile
          </Typography>
          <div className="relative flex flex-col gap-5 mt-3 border border-gray-4 rounded-xl p-4">
            <ButtonV2
              variant="flat"
              color="default"
              size="sm"
              className="absolute top-4 right-4"
              onPress={onOpen}
            >
              Edit
            </ButtonV2>
            <div>
              <Typography
                variant="body-1"
                weight="medium"
                as="p"
                className="text-secondary-text"
              >
                First name
              </Typography>
              <Typography
                variant="body-1"
                weight="medium"
                className="mt-[2px]"
                as="p"
              >
                {user?.firstName || '--'}
              </Typography>
            </div>
            <div>
              <Typography
                variant="body-1"
                weight="medium"
                as="p"
                className="text-secondary-text"
              >
                Last name
              </Typography>
              <Typography
                variant="body-1"
                weight="medium"
                className="mt-[2px]"
                as="p"
              >
                {user?.lastName || '--'}
              </Typography>
            </div>
            <div>
              <Typography
                variant="body-1"
                weight="medium"
                as="p"
                className="text-secondary-text"
              >
                Email
              </Typography>
              <Typography
                variant="body-1"
                weight="medium"
                className="mt-[2px]"
                as="p"
              >
                {user?.email || '--'}
              </Typography>
            </div>
            <div>
              <Typography
                variant="body-1"
                weight="medium"
                as="p"
                className="text-secondary-text"
              >
                Username
              </Typography>
              <Typography
                variant="body-1"
                weight="medium"
                className="mt-[2px]"
                as="p"
              >
                {user?.username || '--'}
              </Typography>
            </div>

            <div className="flex items-center gap-2">
              <InformationCircleIcon className="size-4 text-secondary-text" />
              <Typography
                variant="body-1"
                weight="medium"
                as="p"
                className="text-secondary-text"
              >
                You can log in with both your email and username.
              </Typography>
            </div>
          </div>
        </div>

        <AccountNotifications
          id={user?.id}
          emailNotifications={user?.emailNotifications}
        />
      </div>

      <AccountFormModal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        user={user}
      />
    </div>
  );
};

export default AccountPage;
