import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type MobileMenuStore = {
  isOpen: boolean;
  toggle: () => void;
};

const useMobileMenu = create<MobileMenuStore>()(
  persist(
    (set) => ({
      isOpen: false,
      toggle: () => set((state) => ({ isOpen: !state.isOpen }))
    }),
    {
      name: 'sidebar',
      storage: createJSONStorage(() => localStorage)
    }
  )
);

export default useMobileMenu;
