import apiService from 'src/shared/config/apiService';
import handleAxiosError from 'src/shared/utils/handleAxiosError';

export type UpdateOrganizationAvatarPayload = {
  avatar: File | null;
  id?: number;
};
type UpdateOrganizationAvatarResponse = any;

export const updateOrganizationAvatar = async (
  payload: UpdateOrganizationAvatarPayload
): Promise<UpdateOrganizationAvatarResponse> => {
  try {
    const formData = new FormData();

    if (payload.avatar) {
      formData.append('avatar', payload.avatar);
    }

    const { data } = await apiService.post<UpdateOrganizationAvatarResponse>(
      `/organizations/${payload.id}/avatar/`,
      formData, // send raw FormData, not wrapped in an object
      {
        headers: {
          'Content-Type': 'multipart/form-data' // optional, axios will usually handle this
        }
      }
    );

    return data;
  } catch (error) {
    throw handleAxiosError(error);
  }
};
