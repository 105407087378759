import { PlusIcon } from '@heroicons/react/20/solid';
import { Tab } from '@heroui/react';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import ProductDraftsTable from 'src/features/product/product-list/ui/ProductDraftsTable';
import ProductsTable from 'src/features/product/product-list/ui/ProductsTable';

import { ButtonV2, TabsV2, Typography } from 'src/shared/components/ui';

const ProductsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = useMemo(() => {
    return searchParams.get('tab') || 'sourcing';
  }, [searchParams]);

  const renderTabContent = () => {
    switch (activeTab) {
      case 'sourcing':
        return <ProductsTable />;
      case 'drafts':
        return <ProductDraftsTable />;
      // case 'archive':
      //   return <ProductsTable />;
      default:
        return null;
    }
  };

  return (
    <div data-testid="products-display" className="h-full py-4">
      <div
        aria-label="test"
        className="flex items-center justify-between mb-2 px-6"
      >
        <Typography as="h1" variant="h2" weight="bold">
          Products
        </Typography>

        <ButtonV2
          size="md"
          color="primary"
          startContent={<PlusIcon className="size-4" />}
        >
          New Product
        </ButtonV2>
      </div>

      <div className="px-3">
        <TabsV2
          selectedKey={activeTab}
          onSelectionChange={(key) => setSearchParams({ tab: `${key}` })}
        >
          <Tab key="sourcing" title="Sourcing" />
          <Tab key="drafts" title="Drafts" />
          {/* <Tab key="archive" title="Archive" /> */}
        </TabsV2>
      </div>
      <div className="w-full h-[1px] bg-gray-4 relative top-[-4px]" />
      <div className="py-4 px-6">{renderTabContent()}</div>
    </div>
  );
};

export default ProductsPage;
