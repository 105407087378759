import React from 'react';

import Checkbox from '@mui/material/Checkbox';

import LMLoading from '../LMLoading/LMLoading';

interface AiImageProps {
  image: string | null;
  isLoading: boolean;
  isSelectable?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
  isUpdating?: boolean;
}

const AiImage = ({
  image,
  isLoading,
  isSelectable = false,
  isSelected = false,
  onClick,
  isUpdating = false
}: AiImageProps) => {
  return (
    <div
      className={`w-full h-full relative rounded-lg overflow-hidden ${isSelectable ? 'group' : ''}`}
      onClick={isSelectable && onClick ? onClick : undefined}
    >
      {isLoading ? (
        <div
          className="w-full h-full flex items-center justify-center"
          title="Generating image..."
        >
          <LMLoading small={false} />
        </div>
      ) : image ? (
        <>
          <img
            src={image}
            alt="AI Generated"
            className={`w-full h-full object-cover ${isSelected ? 'rounded-lg border-[3px] border-p-cavela-blue' : ''}`}
          />
          {isSelectable && !isSelected && (
            <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-20 transition-opacity duration-200 flex items-center justify-center rounded-lg"></div>
          )}
          {isSelected && (
            <div className="absolute -top-1 -right-1 z-10">
              <Checkbox
                checked={true}
                size="small"
                icon={
                  <div className="w-4 h-4 rounded-full bg-white border-2 border-p-cavela-blue" />
                }
                checkedIcon={
                  <div className="w-5 h-5 bg-p-cavela-blue border-2 border-white rounded-full flex items-center justify-center">
                    <svg
                      className="w-3 h-3 text-white"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                }
              />
            </div>
          )}
          {isUpdating && (
            <div className="absolute inset-0 bg-black bg-opacity-20 flex items-center justify-center z-5 rounded-lg">
              <div className="w-8 h-8 border-t-2 border-blue-500 rounded-full animate-spin"></div>
            </div>
          )}
        </>
      ) : (
        <div className="w-full h-full flex items-center justify-center rounded-lg">
          <span className="text-xs text-gray-500">No image</span>
        </div>
      )}
    </div>
  );
};

export default AiImage;
