import { Navigate, Outlet } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { useUser } from 'src/entities/user/hooks/useUser';
import { useAuthStore } from 'src/entities/user/store/useAuthStore';
import { ROUTE_PATHS } from 'src/shared/config/routes';

const PrivateRoute = () => {
  const [searchParams] = useSearchParams();
  const authTokenQuery = searchParams.get('auth_token');
  const authToken = useAuthStore((state) => state.authToken);

  // calls /me if needed
  useUser();

  if (authTokenQuery) {
    return <Outlet />;
  }

  if (!authToken) {
    return <Navigate to={ROUTE_PATHS.LOGIN} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
