import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import i18next from 'i18next';

import { Link } from 'src/pages/NewRfqFlow/props';

import { PropertyFieldProps } from './props';

const { t } = i18next;

const useLogic = ({
  title,
  items,
  updateDocumentItem,
  removeDocumentItem,
  updateLinkItem,
  updateFileItem,
  removeFileItem
}: PropertyFieldProps) => {
  const from = useLocation();
  const [editingItem, setEditingItem] = useState<string | null>(null);
  const [editedValues, setEditedValues] = useState<{ [key: string]: string }>(
    {}
  );
  const [newFields, setNewFields] = useState<
    Array<{ key: string; value: string }>
  >([]);
  const isReview = from.search.includes('review');
  const [showRemoveFileModal, setShowRemoveFileModal] = useState(false);

  const handleEdit = (category: string) => {
    setEditingItem(category);
    setEditedValues((prev) => ({
      ...prev,
      [category]: items.find((item) => item.category === category)?.value || ''
    }));
  };

  const handleSave = (category: string) => {
    setEditingItem(null);
    updateDocumentItem(title, category, editedValues[category]);
  };

  const handleCancel = (category: string) => {
    setEditingItem(null);
    updateDocumentItem(title, category, null);
    setEditedValues((prev) => {
      const newValues = { ...prev };
      delete newValues[category];
      return newValues;
    });

    removeDocumentItem(title, category);
  };

  const handleChange = (category: string, value: string) => {
    setEditedValues((prev) => ({ ...prev, [category]: value }));
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    category: string
  ) => {
    if (e.key === 'Enter') {
      handleSave(category);
    }
  };

  const handleAddField = () => {
    setNewFields([...newFields, { key: '', value: '' }]);
  };

  const handleNewFieldChange = (
    index: number,
    field: 'key' | 'value',
    value: string
  ) => {
    const updatedFields = [...newFields];
    updatedFields[index][field] = value;
    setNewFields(updatedFields);
  };

  const handleNewFieldSave = (index: number) => {
    const newField = newFields[index];
    if (newField.key && newField.value) {
      updateDocumentItem(title, newField.key, newField.value);
      setNewFields(newFields.filter((_, i) => i !== index));
    }
  };

  const handleNewFieldRemove = (index: number) => {
    setNewFields(newFields.filter((_, i) => i !== index));
  };

  const itemCategoryText = (item: { category: string }) => {
    const firstCheck =
      item.category === 'default'
        ? t(`${title}.default`).charAt(0).toUpperCase() +
          t(`${title}.default`).slice(1)
        : item.category.charAt(0).toUpperCase() + item.category.slice(1);

    const splittedByUnderscore = firstCheck.split('_');

    if (splittedByUnderscore.length > 1) {
      return splittedByUnderscore
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }

    return firstCheck;
  };

  const handleUpdateLink = (index: number, link: Link) => {
    updateLinkItem(title, index, link.url, link.caption);
  };

  const handleRemoveLink = (index: number) => {
    updateLinkItem(title, index, '', '');
  };

  return {
    t,
    editingItem,
    handleEdit,
    handleSave,
    handleCancel,
    handleChange,
    handleKeyDown,
    handleAddField,
    handleNewFieldChange,
    handleNewFieldSave,
    handleNewFieldRemove,
    editedValues,
    newFields,
    itemCategoryText,
    handleUpdateLink,
    handleRemoveLink,
    isReview,
    showRemoveFileModal,
    setShowRemoveFileModal
  };
};

export default useLogic;
