import React from 'react';

import { Link } from 'src/pages/NewRfqFlow/props';

import PreviewLink from '../PreviewLink/PreviewLink';

interface LinksListProps {
  links: Link[];
  updateLink: (index: number, link: Link) => void;
  removeLink: (index: number) => void;
  withCaption?: boolean;
}

const RFQBuilderLinksList: React.FC<LinksListProps> = ({
  links,
  updateLink,
  removeLink,
  withCaption = true
}) => {
  return (
    <>
      {links.map((link: Link, index: number) => {
        return (
          <PreviewLink
            key={index}
            link={link}
            updateLink={updateLink}
            removeLink={removeLink}
            index={index}
            withCaption={withCaption}
          />
        );
      })}
    </>
  );
};

export default RFQBuilderLinksList;
