import { useRef, useState } from 'react';

const useLogic = ({
  handleNewFieldSave,
  index,
  type
}: {
  handleNewFieldSave: (index: number) => void;
  index: number;
  type: string;
}) => {
  const valueInputRef = useRef<HTMLInputElement>(null);
  const [typeError, setTypeError] = useState(false);

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    inputType: 'key' | 'value'
  ) => {
    if (e.key === 'Enter') {
      if (inputType === 'key') {
        valueInputRef.current?.focus();
      } else if (inputType === 'value') {
        if (type === 'number') {
          if (isNaN(Number(valueInputRef.current?.value))) {
            setTypeError(true);
          } else {
            setTypeError(false);
          }
        }
        handleNewFieldSave(index);
      }
    }
  };

  return {
    handleKeyDown,
    valueInputRef,
    typeError
  };
};

export default useLogic;
