import React from 'react';

import { AddRounded } from '@mui/icons-material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import { Link } from 'src/pages/NewRfqFlow/props';

import NewFields from 'src/components/NewFields/NewFields';
import PreviewFile from 'src/components/PreviewFile/PreviewFile';

import PreviewLink from '../PreviewLink/PreviewLink';
import useLogic from './logic';
import { PropertyFieldProps } from './props';

const PropertyField: React.FC<PropertyFieldProps> = ({
  title,
  items,
  updateDocumentItem,
  aiThinking,
  removeDocumentItem,
  links,
  updateLinkItem,
  files,
  updateFileItem,
  removeFileItem,
  setShowRemoveFileModal,
  setFileToRemove,
  showConfirmModal,
  savedRemoveLinkFunction,
  setSavedRemoveLinkFunction,
  withCaption
}) => {
  const {
    t,
    editingItem,
    handleEdit,
    handleSave,
    handleCancel,
    handleChange,
    handleKeyDown,
    handleAddField,
    handleNewFieldChange,
    handleNewFieldSave,
    handleNewFieldRemove,
    editedValues,
    newFields,
    itemCategoryText,
    handleUpdateLink,
    handleRemoveLink,
    isReview
  } = useLogic({
    title,
    items,
    updateDocumentItem,
    removeDocumentItem,
    links,
    updateLinkItem,
    files,
    updateFileItem,
    removeFileItem,
    setShowRemoveFileModal,
    setFileToRemove,
    showConfirmModal,
    savedRemoveLinkFunction,
    setSavedRemoveLinkFunction,
    withCaption
  });

  return (
    <>
      <div className="flex justify-between items-center">
        <h3 className="text-md font-500 text-black text-start">
          {t(
            `${title}.placeholder`,
            title
              .split('_')
              .join(' ')
              .replace(/^\w/, (c) => c.toUpperCase())
          )}
        </h3>
        {!isReview && (
          <button
            className="py-1 border border-gray-300 text-black rounded-full flex items-center hover:scale-105 transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed px-4 text-sm whitespace-nowrap"
            onClick={handleAddField}
            disabled={aiThinking}
            title="Add field"
          >
            <AddRounded style={{ fontSize: '16px' }} />
          </button>
        )}
      </div>
      <div>
        {items.map((item) => {
          const isEditing = editingItem === item.category;
          return (
            <React.Fragment key={item.category}>
              <div className="flex items-center py-3 text-left gap-4">
                <span className="text-light-gray w-1/3 font-bold">
                  {itemCategoryText(item)}
                </span>
                <div className="w-2/3 flex items-center">
                  {isEditing && !aiThinking ? (
                    <div className="relative w-full">
                      <input
                        className="text-black font-medium w-full font-500 outline-none px-2 py-1 border border-gray-300 rounded pr-20"
                        value={editedValues[item.category]}
                        onChange={(e) =>
                          handleChange(item.category, e.target.value)
                        }
                        onKeyDown={(e) => handleKeyDown(e, item.category)}
                        autoFocus
                        type={
                          title === 'number_of_units_per_variant'
                            ? 'number'
                            : 'text'
                        }
                      />
                      <button
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 text-black hover:scale-105 transition-all duration-300 disabled:cursor-not-allowed disabled:opacity-50"
                        onClick={() => handleSave(item.category)}
                        disabled={aiThinking || !editedValues[item.category]}
                      >
                        <span className="h-full aspect-square flex items-center justify-center bg-border-light-gray-2 rounded cursor-pointer hover:bg-[#b0b1b4]">
                          <CheckRoundedIcon style={{ fontSize: '16px' }} />
                        </span>
                      </button>
                    </div>
                  ) : (
                    <span
                      className={`text-black font-medium w-full font-500 inline-block break-words ${
                        aiThinking
                          ? 'opacity-50 cursor-not-allowed'
                          : isReview
                            ? 'cursor-default'
                            : 'cursor-pointer'
                      }`}
                      onClick={() =>
                        !aiThinking && !isReview && handleEdit(item.category)
                      }
                    >
                      {item.value.charAt(0).toUpperCase() + item.value.slice(1)}
                    </span>
                  )}
                  {isEditing && (
                    <button
                      className="ml-2 text-gray-500 px-2 hover:scale-105 transition-all duration-300"
                      onClick={() => handleCancel(item.category)}
                    >
                      <img
                        src="/icons/remove.svg"
                        alt="remove"
                        title="Remove"
                        className="mr-2"
                        width="20"
                        height="20"
                      />
                    </button>
                  )}
                </div>
              </div>
              <hr className="border-gray-200" />
            </React.Fragment>
          );
        })}
        {newFields.map((field, index) => (
          <React.Fragment key={index}>
            <NewFields
              fieldKey={field.key}
              index={index}
              fieldValue={field.value}
              handleNewFieldChange={handleNewFieldChange}
              handleNewFieldSave={handleNewFieldSave}
              aiThinking={aiThinking || false}
              handleNewFieldRemove={handleNewFieldRemove}
              type={title === 'number_of_units_per_variant' ? 'number' : 'text'}
            />
            <hr className="border-gray-200" />
          </React.Fragment>
        ))}

        <div className="mt-4 w-full space-y-4">
          {files.map((file: any, index: number) => {
            return (
              <PreviewFile
                file={file}
                updateFile={updateFileItem}
                removeFile={() => removeFileItem(file.id)}
                index={index}
                disabled={aiThinking || false}
                fromProductSide={true}
                keyValue={title}
                showConfirmModal={() => {
                  setShowRemoveFileModal(true);
                  setFileToRemove(file.id);
                }}
                withCaption={withCaption}
              />
            );
          })}

          {links.map((link: Link, index: number) => {
            return (
              <PreviewLink
                link={link}
                updateLink={handleUpdateLink}
                removeLink={handleRemoveLink}
                index={index}
                disabled={aiThinking}
                fromProductSide={true}
                showConfirmModal={() => {
                  showConfirmModal();
                }}
                setSavedRemoveLinkFunction={setSavedRemoveLinkFunction}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default PropertyField;
