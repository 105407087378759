import { extendVariants, Tabs as StyledTabs } from '@heroui/react';

const ExtendedTab = extendVariants(StyledTabs, {
  variants: {
    color: {
      primary: {
        tab: [
          'max-w-fit px-2 font-semibold !rounded-xl px-3 py-2',
          '!px-2 h-10 pt-0 pb-4'
        ],
        cursor: 'h-[4px]'
      },
      dark: {
        tab: [
          'text-primary-text',
          'bg-gray-2',
          'max-w-fit px-2 font-semibold !rounded-xl px-3 py-2'
        ],
        tabContent: [
          'text-primary-text',
          'group-data-[selected=true]:text-white'
        ],
        cursor: ['bg-black', 'text-white', '!rounded-xl']
      }
    }
  },
  defaultVariants: {
    color: 'primary',
    size: 'lg',
    variant: 'underlined'
  }
});

export default ExtendedTab;
