import React from 'react';

import AnimatedText from 'src/components/AnimatedText/AnimatedText';

interface RFQTitleProps {
  text: string;
  minHeight?: number;
}

export default function RFQTitle({ text, minHeight = 84 }: RFQTitleProps) {
  return (
    <h4
      className={`text-black text-md leading-[28px] font-500 whitespace-pre-line min-h-[${minHeight}px] max-w-[380px] mb-4`}
    >
      <AnimatedText fullText={text} interval={10} />
    </h4>
  );
}
