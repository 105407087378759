import { Chip, extendVariants } from '@heroui/react';

const Tag = extendVariants(Chip, {
  variants: {
    color: {
      yellow: {
        base: ['bg-yellow-1 text-yellow-dark-2']
      },
      blue: {
        base: ['bg-cyan-light-3 text-cyan-dark']
      },
      green: {
        base: ['text-green-dark bg-green-light-6']
      },
      red: {
        base: ['text-red-9 bg-red-0']
      },
      default: {
        base: ['bg-gray-3 text-secondary-text']
      },
      none: {
        base: ['bg-transparent text-primary-text']
      },
      flat: {
        base: ['bg-violet-0 text-primary']
      },
      no_action: {
        base: ['bg-gray-2 text-secondary-text']
      }
    },
    size: {
      md: {
        base: ['font-medium rounded-lg h-[24px] px-0']
      }
    },
    radius: {
      full: {
        base: ['!rounded-full']
      }
    }
  },

  defaultVariants: {
    variant: 'solid',
    size: 'md',
    color: 'default'
  }
});

export default Tag;
