import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow
} from '@heroui/react';
import { Key, useCallback, useMemo } from 'react';

import { format } from 'date-fns';
import { useAuthStore } from 'src/entities/user/store/useAuthStore';

import { ButtonV2, Typography } from 'src/shared/components/ui';

import { membersListColumns } from '../constants/membersListColumns';
import MemberNameCell from './MemberNameCell';

const MembersList = () => {
  const selectedOrganization = useAuthStore(
    (state) => state.selectedOrganization
  );

  const total = useMemo(() => {
    return selectedOrganization?.members?.length;
  }, [selectedOrganization]);

  const data = useMemo(() => {
    return selectedOrganization?.members;
  }, [selectedOrganization]);

  // look - user proper member type - see if it's equal to User
  const renderCell = useCallback((item: any, columnKey: Key) => {
    switch (columnKey) {
      case 'name':
        return <MemberNameCell name={item?.email} image={item.avatar} />;

      case 'role':
        // look - role is not available
        return (
          <Typography variant="body-1" weight="medium">
            Member
          </Typography>
        );

      case 'joined_on':
        // look - joined on is not available
        return (
          <Typography variant="body-1" weight="medium">
            {format(new Date(), 'MMM dd, yyyy')}
          </Typography>
        );

      case 'last_login':
        // look - last log in is not available
        return (
          <Typography variant="body-1" weight="medium">
            {format(new Date(), 'MMM dd, yyyy')}
          </Typography>
        );

      default:
        return '';
    }
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center">
        <Typography variant="h4" weight="bold">
          {total} Members
        </Typography>
        <ButtonV2 color="dark">Invite new member</ButtonV2>
      </div>

      <Table
        aria-label="Products table"
        className="table-auto mt-2"
        removeWrapper
      >
        <TableHeader columns={membersListColumns}>
          {(column) => (
            <TableColumn
              className="bg-white border-b border-gray-4"
              key={column.key}
            >
              <Typography
                variant="body-2"
                weight="medium"
                className="text-secondary-text"
              >
                {column.label}
              </Typography>
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={data ?? []}>
          {(item) => (
            <TableRow
              className="cursor-pointer h-16 hover:bg-gray-2 transition-background border-b border-gray-4"
              key={item.id}
            >
              {(columnKey) => (
                <TableCell>{renderCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default MembersList;
