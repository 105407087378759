import { useQuery } from '@tanstack/react-query';

import { getProductDrafts } from '../api/getProductDrafts';

export const useProductDrafts = () => {
  const query = useQuery({
    queryKey: ['products-drafts'],
    queryFn: async () => {
      const response = await getProductDrafts();

      return response.data;
    }
  });

  return query;
};
