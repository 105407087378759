import React from 'react';

interface PrivateContentMobileProps {
  children: React.ReactNode;
}
const PrivateContentMobile: React.FC<PrivateContentMobileProps> = ({
  children
}) => {
  return <main className="relative flex-grow mt-[72px]">{children}</main>;
};

export default PrivateContentMobile;
