import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';

import {
  organizationAddressFormSchema,
  OrganizationAddressFormValues
} from '../model/schema';

export const organizationAddressFormDefaultValues = {
  name: '',
  line_1: '',
  line_2: '',
  city: '',
  state: '',
  zip_code: '',
  country: '',
  contact_name: '',
  contact_phone: '',
  is_default: false
};

export const useOrganizationAddressForm = (
  defaultValues?: Partial<OrganizationAddressFormValues>
) => {
  return useForm<OrganizationAddressFormValues>({
    resolver: zodResolver(organizationAddressFormSchema),
    defaultValues: {
      ...organizationAddressFormDefaultValues,
      ...defaultValues
    }
  });
};
