import { useState } from 'react';

import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';

import SimpleModal from 'src/components/SimpleModal/SimpleModal';

interface RFQRestartProps {
  onRestart: () => void;
  disabled?: boolean;
  className?: string;
}

export default function RFQRestart({
  onRestart,
  disabled = false,
  className = ''
}: RFQRestartProps) {
  const [showRestartModal, setShowRestartModal] = useState<boolean>(false);

  return (
    <>
      <div className="text-base py-2">
        <button
          className={`h-full border border-p-bg-p-cavela-blue text-p-bg-p-cavela-blue rounded-full px-4 flex gap-1 items-center hover:scale-105 transition-all duration-300
        disabled:opacity-50 disabled:cursor-not-allowed text-base py-2 ${className}`}
          onClick={() => setShowRestartModal(true)}
          disabled={disabled}
        >
          <RefreshRoundedIcon style={{ fontSize: '12px' }} />
          <span className="font-c font-700">Restart</span>
        </button>

        {showRestartModal && (
          <SimpleModal
            title="Restart Session"
            description="Do you want to restart your quote request? This action will remove the steps you have accomplished before."
            onClose={() => setShowRestartModal(false)}
            onSave={onRestart}
            leftButtonText="No, cancel"
            rightButtonText="Yes, restart"
          />
        )}
      </div>
    </>
  );
}
