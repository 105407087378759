export const membersListColumns = [
  {
    key: 'name',
    label: 'Name'
  },
  {
    key: 'role',
    label: 'Role'
  },
  {
    key: 'joined_on',
    label: 'Joined on'
  },
  {
    key: 'last_login',
    label: 'Last log in'
  }
];
