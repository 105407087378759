import { useMutation } from '@tanstack/react-query';

import {
  updateOrganization,
  UpdateOrganizationPayload
} from '../api/updateOrganization';

export const useUpdateOrganization = () => {
  return useMutation({
    mutationFn: async ({ name, id }: UpdateOrganizationPayload) => {
      return await updateOrganization({ name, id });
    }
  });
};
