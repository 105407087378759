import React from 'react';

import { SimpleModalProps } from './types';

const SimpleModal: React.FC<SimpleModalProps> = ({
  title,
  description,
  onClose,
  onSave,
  leftButtonText,
  rightButtonText,
  redColor
}) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
      <div className="w-[445px] transform transition-all">
        <div className="bg-white rounded-xl shadow-2xl overflow-hidden animate-fadeIn">
          <div className="flex items-center justify-between px-4 pt-4 w-full">
            <h3
              className="text-2xl font-bold text-black font-['Cabinet_Grotesk'] text-[24px] leading-[30px] text-left"
              style={{ fontFamily: 'Cabinet Grotesk Bold' }}
            >
              {title}
            </h3>

            <button
              className="text-gray-500 px-2 hover:scale-105 transition-all duration-300 ease-in-out"
              onClick={onClose}
            >
              <img
                src="/icons/close.svg"
                alt="close"
                className="mr-2"
                width="15"
                height="15"
              />
            </button>
          </div>
          <div className="px-4 py-4">
            <p className="text-black font-['Poppins'] text-sm font-medium leading-[18px] text-left">
              {description}
            </p>
          </div>
          <div className="flex justify-center space-x-4 pb-4">
            <button
              className={`bg-white ${redColor ? 'text-[#E10E0E] border-[#E10E0E]' : 'text-p-cavela-blue border-p-cavela-blue'} border  py-2 px-9 rounded-full font-['Cabinet_Grotesk'] text-[16px] font-bold leading-[20px] transition-transform duration-300 ease-in-out hover:scale-105 w-[200px] text-center whitespace-nowrap`}
              style={{ fontWeight: 700 }}
              onClick={onClose}
            >
              {leftButtonText || 'No, stay in session'}
            </button>

            <button
              className={`${redColor ? 'bg-[#E10E0E]' : 'bg-p-cavela-blue'} text-white py-2 px-8 rounded-full font-['Cabinet_Grotesk'] text-[16px] font-bold leading-[20px] transition-transform duration-300 ease-in-out hover:scale-105 w-[200px] text-center whitespace-nowrap overflow-hidden text-overflow-ellipsis`}
              style={{ fontWeight: 700 }}
              onClick={onSave}
            >
              {rightButtonText || 'Yes, back home'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleModal;
