import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type SidebarStore = {
  isOpen: boolean;
  toggle: () => void;
};

const useSidebar = create<SidebarStore>()(
  persist(
    (set) => ({
      isOpen: false,
      toggle: () => set((state) => ({ isOpen: !state.isOpen }))
    }),
    {
      name: 'sidebar',
      storage: createJSONStorage(() => localStorage)
    }
  )
);

export default useSidebar;
