import { useState } from 'react';

import {
  CustomMarkdownWithGallery,
  DynamicHtmlContent,
  FileUpload,
  QuestionForm
} from '..';
import { customAnchor, quoteMarkdown } from 'src/styles/markdownMapper';

import { Text } from 'src/shared/components/ui';

import { Flex } from '@mantine/core';

import useFiles from '../../hooks/useFiles';
import {
  MappedQuestion,
  SendQuestionResponse
} from '../../types/QuestionTypes';

import styles from './Questions.module.css';

interface QuestionContentProps {
  questionContent: MappedQuestion;
  stageId: string;
  questionId: number;
  productId: string | null;
  onQuestionSend: (props: any) => Promise<SendQuestionResponse>;
}

export default function QuestionContent({
  questionContent,
  stageId,
  questionId,
  productId,
  onQuestionSend
}: QuestionContentProps) {
  const [isAnswered, setIsAnswered] = useState(!!questionContent.answer);
  const [isChanged, setIsChanged] = useState(false);

  const [stagedFiles, setStagedFiles] = useState([]);
  const [stagedCaptions, setStagedCaptions] = useState([]);

  const { uploadFiles, setFiles } = useFiles();

  const isApproval =
    !isAnswered && questionContent?.questionType === 'approval';

  const onFileSubmit = async (formValues: {
    files: File[];
    captions?: string[];
  }) => {
    if (!productId) return;

    const response = await uploadFiles({
      files: formValues.files,
      captions: formValues.captions,
      productId,
      stageId,
      questionId
    });

    if (response && !response.error) {
      setFiles(undefined);
    }
  };

  const onSend = async (event: Event) => {
    if (stagedFiles) {
      await onFileSubmit({
        files: stagedFiles,
        captions: stagedCaptions || []
      });
    }

    return onQuestionSend(event);
  };

  return (
    <Flex
      className={`${styles.questionContentWrapper} ${
        isAnswered ? styles.answered : ''
      } ${isApproval || (!isAnswered && isChanged) ? styles.changed : ''}`}
      gap="md"
      data-testid="question-content"
    >
      <Flex
        direction="column"
        w="100%"
        className={styles.questionTitle}
        c={isAnswered ? 'cavela-shadow.5' : 'cavela-shadow.10'}
        data-testid="question-title"
      >
        {/* For retrocompatibility early questions don't have markdown */}
        {questionContent.questionMarkdown ? (
          <CustomMarkdownWithGallery
            galleryProps={{ dimmed: isAnswered }}
            components={{
              p: (props: any) => <Text color="black">{props.children}</Text>,
              a: customAnchor
            }}
          >
            {questionContent.questionMarkdown}
          </CustomMarkdownWithGallery>
        ) : (
          <DynamicHtmlContent content={questionContent.question} />
        )}
      </Flex>
      <QuestionForm
        onAnswered={() => setIsAnswered(true)}
        onQuestionSend={onSend}
        questionId={questionId}
        productId={productId || ''}
        questionContent={questionContent}
        stageId={stageId}
        answered={isAnswered}
        setChanged={setIsChanged}
      />
      {!isAnswered && questionContent?.fileUploadsEnabled && (
        <FileUpload
          noSubmit
          onSubmit={onFileSubmit}
          setStagedFiles={setStagedFiles}
          setStagedCaptions={setStagedCaptions}
        />
      )}
    </Flex>
  );
}
