import { useCallback, useEffect, useRef, useState } from 'react';

import { failure } from 'src/services/logger';
import { API_URL } from 'src/settings';
import { useImageGenerationStore } from 'src/stores/imageGenerationStore';
import { fetchWithAuth } from 'src/utils/fetchWithAuth';

interface UseCoverArtLogicProps {
  rfqId: number;
  setImage: (image: string) => void;
  useAiImage: boolean;
}

const useCoverArtLogic = ({
  rfqId,
  setImage,
  useAiImage
}: UseCoverArtLogicProps) => {
  const { isGeneratingImage, setIsGeneratingImage } = useImageGenerationStore();
  const [additionalImages, setAdditionalImages] = useState<string[]>([]);
  const generationAttemptedRef = useRef<Record<number, boolean>>({});

  const generateAiImage = useCallback(
    async (id: number) => {
      if (isGeneratingImage) return; // Prevent concurrent calls
      if (generationAttemptedRef.current[id]) return; // Prevent multiple generation attempts for same rfqId

      generationAttemptedRef.current[id] = true;

      try {
        setIsGeneratingImage(true);
        const res = await fetchWithAuth(
          `${API_URL}/api/rfqs/${id}/regenerate-image`,
          {
            method: 'POST'
          }
        );

        const data = await res.json();
        if (data?.rfq?.image) {
          setImage(data.rfq.image);

          // Handle additional images if they exist
          if (
            data?.rfq?.additional_images &&
            Array.isArray(data.rfq.additional_images)
          ) {
            setAdditionalImages([
              data.rfq.image,
              ...data.rfq.additional_images
            ]);
          }
        } else {
          failure('No image data received from API');
        }
      } catch (error) {
        failure('Failed to generate AI image:', error);
        // Reset the flag in case of error so we can try again
        generationAttemptedRef.current[id] = false;
      } finally {
        setIsGeneratingImage(false);
      }
    },
    [isGeneratingImage, setImage, setIsGeneratingImage]
  );

  useEffect(() => {
    let mounted = true;

    if (
      rfqId &&
      typeof rfqId === 'number' &&
      !isGeneratingImage &&
      useAiImage &&
      !generationAttemptedRef.current[rfqId]
    ) {
      generateAiImage(rfqId).then(() => {
        if (!mounted) {
          return;
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, [rfqId, isGeneratingImage, generateAiImage, useAiImage]);

  return {
    generateAiImage,
    isGeneratingImage,
    additionalImages
  };
};

export default useCoverArtLogic;
