import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';

import AddLink from '../AddLink/AddLink';
import useLogic from './logic';
import { PreviewLinkProps } from './props';

const PreviewLink = ({
  link,
  index,
  removeLink,
  updateLink,
  fromProductSide,
  disabled = false,
  showConfirmModal,
  setSavedRemoveLinkFunction,
  withCaption
}: PreviewLinkProps) => {
  const { isEditing, setIsEditing, startEditMode, removeValue } = useLogic({
    link,
    index,
    updateLink,
    removeLink
  });

  if (isEditing) {
    return (
      <AddLink
        index={index}
        fromProductSide={fromProductSide}
        link={link}
        isEditing={true}
        setIsEditing={setIsEditing}
        removeLink={removeLink}
        handleAddLink={updateLink}
        showConfirmModal={showConfirmModal}
        setSavedRemoveLinkFunction={setSavedRemoveLinkFunction}
      />
    );
  }

  return (
    <div
      className={`left-4 right-4 p-4 bg-bg-light-gray border border-gray-300 rounded-lg
        hover:shadow-md transition-all duration-300 ease-in-out
        ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
    `}
      onClick={() => {
        if (disabled) return;
        startEditMode();
      }}
    >
      <div className="flex items-center justify-between">
        <div className="flex gap-1 items-center">
          <div className="flex items-center justify-center size-4 shrink-0">
            <LinkIcon
              style={{
                fontSize: '16px'
              }}
            />
          </div>
          <strong className="text-md text-black truncate">
            {link.url.length > 40 ? link.url.slice(0, 40) + '...' : link.url}
          </strong>
        </div>
        <button
          className="size-4 text-gray-500 hover:scale-105 transition-all duration-300 z-10 flex items-center justify-center"
          onClick={(e) => {
            if (disabled) return;
            e.stopPropagation();
            if (showConfirmModal) {
              showConfirmModal();

              if (setSavedRemoveLinkFunction) {
                setSavedRemoveLinkFunction(() => removeValue);
              }
            } else {
              removeValue();
            }
          }}
          disabled={disabled}
        >
          <DeleteIcon
            style={{
              fontSize: '16px'
            }}
          />
        </button>
      </div>

      {withCaption && (
        <div className="flex gap-1">
          <div className="size-4 shrink-0" />
          <div
            className={`mt-1 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} text-left w-full`}
            onClick={() => {
              if (disabled) return;
              startEditMode();
            }}
          >
            <span className="text-md w-full text-black break-words">
              {link.caption}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default PreviewLink;
