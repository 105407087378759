import { Spinner } from '@heroui/react';
import { Key, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { format } from 'date-fns';
import { StageTag, TaskStatusTag } from 'src/shared/components';
import { Product } from 'src/shared/types/product';

import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Typography
} from 'src/shared/components/ui';

import { columns } from '../constants/productColumns';
import { useProducts } from '../hooks/useProducts';
import getStageFromLifecycle from '../utils/getStageFromLifecycle';
import getStatus from '../utils/getStatus';
import ProductNameCell from './ProductNameCell';

const ProductsTable = () => {
  const { isLoading, data } = useProducts();
  const navigate = useNavigate();

  const total = data?.length;

  const renderCell = useCallback((item: Product, columnKey: Key) => {
    switch (columnKey) {
      case 'name':
        return <ProductNameCell name={item.detail.name} image={item.image} />;

      case 'update':
        return <TaskStatusTag type="task" />;

      case 'stage':
        return <StageTag type={getStageFromLifecycle(item.detail.lifecycle)} />;

      case 'status':
        return <TaskStatusTag type={getStatus(item)} />;

      case 'active_suppliers':
        return (
          <Typography variant="body-1" weight="medium">
            3
          </Typography>
        );

      case 'samples':
        return (
          <Typography variant="body-1" weight="regular">
            12
          </Typography>
        );

      case 'orders':
        return (
          <Typography variant="body-1" weight="medium">
            3
          </Typography>
        );

      case 'total_spend':
        return (
          <Typography variant="body-1" weight="medium">
            $2,500
          </Typography>
        );

      case 'created':
        return (
          <Typography variant="body-1" weight="medium">
            {format(item.detail.created_at, 'MMM dd, yyyy')}
          </Typography>
        );

      default:
        return '';
    }
  }, []);

  const onClickRow = (id: string) => {
    navigate(`/v2/products/${id}`);
  };

  return (
    <div className="flex flex-col">
      <div>
        <Typography variant="h4" weight="bold">
          {total} Sourcing
        </Typography>
      </div>

      <Table aria-label="Products table">
        <TableHeader columns={columns}>
          {(column) => (
            <TableColumn key={column.key}>{column.label}</TableColumn>
          )}
        </TableHeader>
        <TableBody
          items={data ?? []}
          emptyContent={isLoading ? <Spinner /> : 'No quotes found'}
        >
          {(item) => (
            <TableRow onClick={() => onClickRow(item.id)} key={item.id}>
              {(columnKey) => (
                <TableCell>{renderCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default ProductsTable;
