import React from 'react';

interface NextButtonProps {
  onClick: () => void;
  disabled: boolean;
  pushToRight?: boolean;
  buttonText?: string;
}

const NextButton: React.FC<NextButtonProps> = ({
  onClick,
  disabled,
  pushToRight,
  buttonText = '➔'
}) => {
  const style = pushToRight ? { marginLeft: 'auto' } : {};

  // Determine if the button should be circular (for single characters) or pill-shaped
  const isCompact = buttonText.length <= 1;

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      style={style}
      className={`mx-1 text-white font-medium text-sm focus:outline-none focus:ring-2 focus:bg-p-cavela-blue transition-all duration-300 ease-in-out flex items-center justify-center ${
        isCompact
          ? 'rounded-full w-10 h-10 px-0 py-0'
          : 'rounded-full px-4 py-2 min-w-[80px]'
      } ${
        disabled
          ? 'bg-gray-400 cursor-not-allowed'
          : 'bg-p-cavela-blue hover:bg-p-cavela-blue/90 hover:scale-110 active:scale-95 cursor-pointer'
      }`}
    >
      {buttonText}
    </button>
  );
};

export default NextButton;
