import { useRef, useState } from 'react';

import { FileItem } from 'src/pages/NewRfqFlow/props';

import { AddFileProps } from './props';

const useLogic = ({
  file,
  fileId,
  handleAddFile,
  index,
  captionBase,
  handleUpdateFile,
  keyValue,
  fromProductSide
}: AddFileProps) => {
  const [caption, setCaption] = useState(file.caption || captionBase || '');

  const saveFile = () => {
    const newFile: FileItem = { file: file.file, caption };

    handleUpdateFile
      ? !fromProductSide
        ? handleUpdateFile(index, newFile, keyValue || '')
        : handleUpdateFile(fileId, { caption })
      : handleAddFile(index, newFile);
  };

  return {
    caption,
    setCaption,
    saveFile
  };
};

export default useLogic;
