import React, { useState } from 'react';

import { ContentCopyRounded } from '@mui/icons-material';

import useFeatureFlag from 'src/hooks/useFeatureFlag';
import { FF_RFQ_FILE_PROCESSING, FF_RFQ_LINK_PROCESSING } from 'src/settings';

import AnimatedText from 'src/components/AnimatedText/AnimatedText';

import FileButton from './FileButton';
import FilesAndLinksList from './FilesAndLinksList';
import LinkButton from './LinkButton';
import useLogic from './logic';
import NextButton from './NextButton';
import { LMTextareaProps } from './props';

const LMTextarea: React.FC<
  LMTextareaProps & { children?: React.ReactNode }
> = ({
  title,
  example,
  suggestions,
  showTextarea,
  nextButtonFunction,
  bottomText,
  value,
  setValue,
  autocompleteAvailable = false,
  autocompleteText = null,
  isIncorrect = false,
  withCaption = true,
  addNewLink,
  updateLink,
  links,
  removeLink,
  openNewFileUploadModal,
  updateFile,
  files,
  removeFile,
  aiThinking,
  handleSkip,
  skippable,
  aiErrorMsg,
  children
}) => {
  const { checkTextAreaButtonDisabled, skipQuestion } = useLogic({
    value,
    suggestions,
    autocompleteAvailable,
    autocompleteText,
    setValue,
    nextButtonFunction,
    aiThinking,
    files,
    handleSkip,
    skippable,
    links
  });
  const { isFeatureEnabled: isRFQFileProcessingEnabled } = useFeatureFlag(
    FF_RFQ_FILE_PROCESSING
  );
  const { isFeatureEnabled: isRFQLinkProcessingEnabled } = useFeatureFlag(
    FF_RFQ_LINK_PROCESSING
  );
  const [lmTextareaAnimationDone, setLMTextareaAnimationDone] = useState(false);
  return (
    <div className="flex w-full items-start font-sans max-w-[420px] lg:max-w-[550px] z-10">
      <div className="w-full flex flex-col items-start flex-grow">
        <div className="mb-4 max-w-[384px] ">
          <h4 className="text-md text-black font-500 leading-[32px] whitespace-pre-line">
            <AnimatedText
              fullText={title}
              interval={10}
              onEnd={() => setLMTextareaAnimationDone(true)}
            />
          </h4>
        </div>

        {lmTextareaAnimationDone && (
          <>
            <div className="relative w-full animate-fadeIn">
              {showTextarea && (
                <>
                  {example && (
                    <div className="text-gray-600 mb-4 flex flex-col items-left justify-between">
                      {example}
                    </div>
                  )}
                  {suggestions && suggestions.length > 0 && (
                    <div className="text-black mb-4 flex flex-col items-left justify-between">
                      <span className="text-gray-600">Some ideas:</span>
                      <ul className="list-disc list-inside text-gray-600 mb-4">
                        {suggestions.map((suggestion) => (
                          <li key={suggestion} className="relative group">
                            {suggestion}
                            <span className="inline rounded-full ml-2 opacity-0 transition-opacity duration-200 group-hover:opacity-100">
                              <button
                                onClick={() =>
                                  setValue(
                                    value
                                      ? value + '\n' + suggestion
                                      : suggestion
                                  )
                                }
                                title="Copy suggestion"
                                className="bg-gray-100 rounded-full p-1 hover:bg-gray-200 w-6 h-6"
                              >
                                <ContentCopyRounded
                                  style={{
                                    fontSize: '14px',
                                    position: 'relative',
                                    top: '-4px'
                                  }}
                                />
                              </button>
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <textarea
                    value={value}
                    placeholder="Enter your response."
                    onChange={(e) => setValue(e.target.value)}
                    className={`w-full p-4 text-sm ${isIncorrect ? 'border-2 border-red-500' : 'border border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 pr-12 resize-vertical`}
                    rows={window.innerWidth <= 768 ? 8 : 14}
                    style={{
                      minHeight: window.innerWidth <= 768 ? '4em' : '14em',
                      height: 'auto',
                      overflowY: 'hidden'
                    }}
                    onInput={(e) => {
                      const target = e.target as HTMLTextAreaElement;
                      target.style.height = 'auto';
                      target.style.height = `${target.scrollHeight}px`;
                    }}
                    autoFocus
                  />
                </>
              )}
              {!showTextarea && (
                <FilesAndLinksList
                  files={files}
                  links={links}
                  removeFile={removeFile}
                  updateFile={updateFile}
                  updateLink={updateLink}
                  removeLink={removeLink}
                  aiThinking={aiThinking}
                  withCaption={withCaption}
                />
              )}
              <div
                className={`${showTextarea ? 'absolute bottom-3' : 'mt-4'} flex w-full px-1`}
              >
                <LinkButton
                  isRFQLinkProcessingEnabled={isRFQLinkProcessingEnabled}
                  disabled={aiThinking}
                  addNewLink={addNewLink}
                  embedded={showTextarea}
                />
                <FileButton
                  isRFQFileProcessingEnabled={isRFQFileProcessingEnabled}
                  disabled={aiThinking}
                  openNewFileUploadModal={openNewFileUploadModal}
                  embedded={showTextarea}
                />

                <NextButton
                  onClick={nextButtonFunction}
                  disabled={
                    !skippable && checkTextAreaButtonDisabled(withCaption)
                  }
                  pushToRight={true}
                />
              </div>
            </div>
          </>
        )}

        {isIncorrect === true && lmTextareaAnimationDone && (
          <div className="w-full my-2 text-red-500 p-4 bg-red-100 rounded-lg animate-fadeIn">
            <span className="font-500">{aiErrorMsg}</span>
          </div>
        )}

        {lmTextareaAnimationDone && (
          <p className="text-base text-gray-500 mt-1 leading-6 ml-1 font-medium animate-fadeIn w-[91.5%] md:w-full">
            {bottomText}
          </p>
        )}

        {showTextarea && (
          <FilesAndLinksList
            files={files}
            links={links}
            removeFile={removeFile}
            updateFile={updateFile}
            updateLink={updateLink}
            removeLink={removeLink}
            aiThinking={aiThinking}
            withCaption={withCaption}
          />
        )}

        {lmTextareaAnimationDone && children && (
          <div className="w-full mt-4 animate-fadeIn">{children}</div>
        )}
      </div>
    </div>
  );
};

export default LMTextarea;
