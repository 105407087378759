import {
  BreadcrumbItem,
  Breadcrumbs as StyledBreadcrumbs
} from '@heroui/react';
import React from 'react';

interface BreadcrumbsProps {
  paths: {
    path: string;
    title: string;
  }[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ paths }) => {
  const renderPaths = () => {
    return paths.map((path) => (
      <BreadcrumbItem className="font-medium">{path.title}</BreadcrumbItem>
    ));
  };

  return <StyledBreadcrumbs>{renderPaths()}</StyledBreadcrumbs>;
};

export default Breadcrumbs;
