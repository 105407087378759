import apiService from 'src/shared/config/apiService';
import { Product } from 'src/shared/types/product';
import handleAxiosError from 'src/shared/utils/handleAxiosError';

export const getProducts = async () => {
  try {
    const response = await apiService.get<Product[]>('/products');

    return response;
  } catch (error) {
    throw handleAxiosError(error);
  }
};
