import { Alert } from '@heroui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { motion } from 'framer-motion';
import { SUPPORT_EMAIL } from 'src/settings';
import { ROUTE_PATHS } from 'src/shared/config/routes';

import { ButtonV2, Input, Typography } from 'src/shared/components/ui';

import { useRegister } from '../hooks/useRegister';
import { useRegisterForm } from '../hooks/useRegisterForm';

const RegisterForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues
  } = useRegisterForm();
  const registerMutation = useRegister();
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const onSubmit = (data: any) => {
    registerMutation.mutate(data, {
      onError: (error) => {
        setError(error.message);
      }
    });
  };

  if (registerMutation.isSuccess) {
    return (
      <motion.div
        initial={{ y: 80, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ ease: 'easeInOut', duration: 1 }}
        data-testid="auth-form-confirmation"
        className="mt-10"
      >
        <img src="/icons/mail.svg" alt="mail" className="mb-4 size-16" />
        <Typography variant="h2" weight="bold">
          Your sign-in link has been mailed to{' '}
          <span className="text-p-cavela-blue">{getValues().email}</span>
        </Typography>

        <Typography className="text-black mt-6" variant="body-1">
          Please check your inbox and use the link in the e-mail to access your
          account. If you don't see the e-mail, check your spam folder or{' '}
          <Typography
            as="a"
            variant="body-1"
            onClick={() => window.open(`mailto:${SUPPORT_EMAIL}`, '_blank')}
            className="text-primary hover:opacity-40 cursor-pointer inline-block"
          >
            contact us.
          </Typography>
        </Typography>
      </motion.div>
    );
  }

  return (
    <div className="flex flex-col justify-center mt-10">
      <Typography variant="h1" weight="bold">
        Sign up
      </Typography>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-6 mt-6 items-start"
      >
        <Input
          label="Email"
          placeholder="name@company.com"
          errorMessage={errors.email?.message}
          isInvalid={!!errors.email}
          type="email"
          {...register('email')}
        />

        <Input
          placeholder="Your code (sent in the invite email)"
          label="Access code"
          errorMessage={errors.signup_access_code?.message}
          isInvalid={!!errors.signup_access_code}
          {...register('signup_access_code')}
        />

        <ButtonV2
          isLoading={registerMutation.isPending}
          isDisabled={registerMutation.isPending}
          type="submit"
        >
          Sign up
        </ButtonV2>
      </form>

      {error && (
        <motion.div
          initial={{ y: 80, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ ease: 'easeInOut', duration: 1 }}
        >
          <Alert hideIcon className="mt-6" color="danger" title={error} />
        </motion.div>
      )}

      <div className="mt-6 flex items-center gap-1">
        <Typography as="span" variant="body-1">
          Don't have an access code?
        </Typography>
        <Typography
          onClick={() => window.open(`https://www.cavela.com/signup`, '_blank')}
          as="a"
          variant="body-1"
          weight="bold"
          className="text-primary hover:opacity-40 cursor-pointer"
        >
          Join waitlist
        </Typography>
      </div>

      <div className="mt-2 flex items-center gap-1">
        <Typography as="span" variant="body-1">
          Already have an account?
        </Typography>
        <Typography
          onClick={() => navigate(ROUTE_PATHS.LOGIN)}
          as="a"
          variant="body-1"
          weight="bold"
          className="text-primary hover:opacity-40 cursor-pointer"
        >
          Sign in
        </Typography>
      </div>
    </div>
  );
};

export default RegisterForm;
