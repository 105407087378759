import SidebarItem from './SidebarItem';

interface SidebarItemProps {
  icon: JSX.Element;
  title: string;
  path: string;
  isOpen: boolean;
}

const SidebarNav: React.FC<{
  navItems: Omit<SidebarItemProps, 'isOpen'>[];
  isOpen: boolean;
}> = ({ navItems, isOpen }) => {
  return (
    <nav className="w-full flex flex-col gap-2">
      {navItems.map(({ path, title, icon }) => (
        <SidebarItem
          isOpen={isOpen}
          key={path}
          path={path}
          title={title}
          icon={icon}
        />
      ))}
    </nav>
  );
};

export default SidebarNav;
