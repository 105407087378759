import apiService from 'src/shared/config/apiService';
import handleAxiosError from 'src/shared/utils/handleAxiosError';

export type UpdateUserNotificationsPayload = {
  emailNotifications: boolean;
  id: string;
};

type UpdateUserNotificationsResponse = any;

export const updateUserNotifications = async (
  payload: UpdateUserNotificationsPayload
): Promise<UpdateUserNotificationsResponse> => {
  try {
    const { id, emailNotifications } = payload;
    const { data } = await apiService.patch<UpdateUserNotificationsResponse>(
      `/users/${id}/`,
      {
        email_notifications: emailNotifications
      }
    );

    return data;
  } catch (error) {
    throw handleAxiosError(error);
  }
};
