import { Image } from '@heroui/react';
import React from 'react';

import { FileAttachment } from 'src/shared/components';

import { Typography } from 'src/shared/components/ui';

interface QuoteDetailTabContentProps {
  selectedQuote: any;
}

interface LabelValueProps {
  label: string;
  value: React.ReactNode | string;
}

const LabelValue: React.FC<LabelValueProps> = ({ label, value }) => {
  return (
    <div className="flex flex-col">
      <Typography
        variant="body-1"
        weight="medium"
        className="text-secondary-text"
      >
        {label}
      </Typography>
      <Typography variant="body-1" weight="medium">
        {value}
      </Typography>
    </div>
  );
};

const AddressBlock = () => {
  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center gap-2">
        <Typography variant="body-1" weight="bold">
          Office address
        </Typography>
        <div className="px-[6px] py-[2px] bg-gray-2 rounded-md">
          <Typography variant="body-3" weight="medium">
            Default
          </Typography>
        </div>
      </div>
      <Typography variant="body-1" weight="medium">
        45 Rockefeller Plaza, New York, NY 10111
      </Typography>
      <Typography variant="body-1" weight="medium">
        Lena Christian
      </Typography>
      <Typography variant="body-1" weight="medium">
        000-000-0000
      </Typography>
    </div>
  );
};

const QuoteDetailTabContent: React.FC<QuoteDetailTabContentProps> = ({
  selectedQuote
}) => {
  return (
    <div className="flex flex-col gap-5 pb-24">
      <div className="border-b border-gray-4 pb-7">
        <div className="px-5 flex flex-col gap-5">
          <Typography variant="h4" weight="bold">
            Similar products done
          </Typography>

          <div className="flex items-center justify-between gap-2">
            <Image
              className="w-32 rounded-xl bg-gray-2 border border-gray-4 mr-3 ml-2"
              src="/images/no-image.svg"
            />
            <Image
              className="w-32 rounded-xl bg-gray-2 border border-gray-4 mr-3 ml-2"
              src="/images/no-image.svg"
            />
            <Image
              className="w-32 rounded-xl bg-gray-2 border border-gray-4 mr-3 ml-2"
              src="/images/no-image.svg"
            />
            <Image
              className="w-32 rounded-xl bg-gray-2 border border-gray-4 mr-3 ml-2"
              src="/images/no-image.svg"
            />
            <Image
              className="w-32 rounded-xl bg-gray-2 border border-gray-4 mr-3 ml-2"
              src="/images/no-image.svg"
            />
          </div>
        </div>
      </div>

      <div className="border-b border-gray-4 pb-7">
        <div className="px-5 flex flex-col gap-5">
          <Typography variant="h4" weight="bold">
            Additional files
          </Typography>

          <div className="flex gap-2 items-center">
            <FileAttachment
              file={new File(['Test file'], 'File.pdf', { type: 'text/plain' })}
            />
            <FileAttachment
              file={new File(['Test file'], 'File.pdf', { type: 'text/plain' })}
            />
            <FileAttachment
              file={new File(['Test file'], 'File.pdf', { type: 'text/plain' })}
            />
            <FileAttachment
              file={new File(['Test file'], 'File.pdf', { type: 'text/plain' })}
            />
          </div>
        </div>
      </div>

      <div className="border-b border-gray-4 pb-7">
        <div className="px-5 flex flex-col gap-5">
          <Typography variant="h4" weight="bold">
            Product cost
          </Typography>

          <div className="grid grid-cols-2 gap-5">
            <LabelValue label="Type" value="Embroidery letters" />
            <LabelValue label="Quantity" value="500" />
            <LabelValue label="EXW unit cost" value="$4.5" />
            <LabelValue label="DDP unit cost" value="From $4.2" />
            <LabelValue label="Production time" value="120 days" />
            <LabelValue
              label="Payment terms"
              value="50% upfront, 50% after reception"
            />
          </div>

          <div className="flex flex-col">
            <Typography
              variant="body-1"
              weight="medium"
              className="text-secondary-text"
            >
              Notes
            </Typography>
            <ul className="list-disc pl-5">
              <Typography variant="body-1" weight="medium" as="li">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor
              </Typography>
              <Typography variant="body-1" weight="medium" as="li">
                incididunt ut labore et dolore magna aliqua
              </Typography>
              <Typography variant="body-1" weight="medium" as="li">
                ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                ut aliquip ex ea
              </Typography>
            </ul>
          </div>
        </div>
      </div>

      <div className="border-b border-gray-4 pb-7">
        <div className="px-5 flex flex-col gap-5">
          <Typography variant="h4" weight="bold">
            Product shipping
          </Typography>

          <div className="grid grid-cols-2 gap-5">
            <LabelValue label="Shipping method" value="Sea" />
            <LabelValue label="Shipping time" value="5 days" />
            <LabelValue label="Shipping cost" value="$35.00" />
            <LabelValue label="Shipping cost" value={<AddressBlock />} />
          </div>
        </div>
      </div>

      <div className="border-b border-gray-4 pb-7">
        <div className="px-5 flex flex-col gap-5">
          <Typography variant="h4" weight="bold">
            Sample cost
          </Typography>

          <div className="grid grid-cols-2 gap-5">
            <LabelValue label="Production cost" value="$90.00" />
            <LabelValue
              label="Payment terms"
              value="50% upfront, 50% after reception"
            />
            <LabelValue label="Production time" value="120 days" />
          </div>
        </div>
      </div>

      <div className="border-b border-gray-4 pb-7">
        <div className="px-5 flex flex-col gap-5">
          <Typography variant="h4" weight="bold">
            Sample shipping
          </Typography>

          <div className="grid grid-cols-2 gap-5">
            <LabelValue label="Shipping method" value="Sea" />
            <LabelValue label="Shipping time" value="5 days" />
            <LabelValue label="Shipping cost" value="$35.00" />
            <LabelValue label="Shipping cost" value={<AddressBlock />} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteDetailTabContent;
