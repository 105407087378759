import React, { useEffect, useRef } from 'react';

interface TextareaAutoHProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  focus?: boolean;
}

const TextareaAutoH: React.FC<TextareaAutoHProps> = ({ focus, ...props }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const adjustHeight = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const element = e.currentTarget;
    element.style.height = 'auto';
    element.style.height = `${element.scrollHeight}px`;
    element.style.resize = 'none';
    element.style.overflow = 'hidden';
  };

  const adjustHeightOnLoad = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      textareaRef.current.style.resize = 'none';
      textareaRef.current.style.overflow = 'hidden';
    }
  };

  useEffect(() => {
    adjustHeightOnLoad();
    focus && textareaRef.current?.focus();
  }, [focus]);

  return (
    <textarea
      rows={1}
      ref={textareaRef}
      className="w-full h-auto overflow-hidden border-none resize-none focus:outline-none "
      {...props}
      onInput={adjustHeight}
    />
  );
};

export default TextareaAutoH;
