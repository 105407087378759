import { addToast } from '@heroui/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';

import { useAuthStore } from 'src/entities/user/store/useAuthStore';
import { Organization } from 'src/shared/types/user';

import {
  ButtonV2,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader
} from 'src/shared/components/ui';

import { useOrganizationForm } from '../hooks/useOrganizationForm';
import { useUpdateOrganization } from '../hooks/useUpdateOrganization';

interface OrganizationFormModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  selectedOrganization: Organization | null;
}
const OrganizationFormModal: React.FC<OrganizationFormModalProps> = ({
  isOpen,
  onOpenChange,
  selectedOrganization
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors }
  } = useOrganizationForm({ name: selectedOrganization?.name });
  const setSelectedOrganization = useAuthStore(
    (state) => state.setSelectedOrganization
  );
  const organizationMutation = useUpdateOrganization();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (selectedOrganization) {
      reset({
        name: selectedOrganization.name || ''
      });
    }
  }, [selectedOrganization, reset]);

  const onSubmit = (data: any) => {
    organizationMutation.mutate(
      { ...data, id: selectedOrganization?.id },
      {
        onError: (error) => {
          const { name } = JSON.parse(error.message);
          setError('name', { message: name });
        },
        onSuccess: () => {
          setSelectedOrganization({ ...selectedOrganization, ...data });
          queryClient.invalidateQueries({ queryKey: ['user'] });
          onOpenChange(false);
          addToast({
            color: 'success',
            title: 'Organization has been successfully updated.'
          });
        }
      }
    );
  };

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1 p-5 border-b border-gray-4">
              Edit organization
            </ModalHeader>
            <ModalBody className="p-5 flex flex-col gap-5">
              <form id="organization-form" onSubmit={handleSubmit(onSubmit)}>
                <Input
                  label="Name"
                  errorMessage={errors.name?.message}
                  isInvalid={!!errors.name}
                  {...register('name')}
                />
              </form>
            </ModalBody>
            <ModalFooter className="justify-start border-t border-gray-4 p-5">
              <ButtonV2
                size="sm"
                color="default"
                variant="flat"
                onPress={onClose}
                isDisabled={organizationMutation.isPending}
              >
                Close
              </ButtonV2>
              <ButtonV2
                form="organization-form"
                type="submit"
                size="sm"
                color="dark"
                isDisabled={organizationMutation.isPending}
                isLoading={organizationMutation.isPending}
              >
                Save
              </ButtonV2>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default OrganizationFormModal;
