import LMLoading from '../LMLoading/LMLoading';
import FileButton from '../LMTextarea/FileButton';
import FilesAndLinksList from '../LMTextarea/FilesAndLinksList';
import LinkButton from '../LMTextarea/LinkButton';
import NextButton from '../LMTextarea/NextButton';

interface RFQFilesAndLinksStepProps {
  files: Array<any>;
  links: Array<any>;
  removeFile: (id: number) => void;
  updateFile: (id: number, data: any) => void;
  updateLink: (id: number, data: any) => void;
  removeLink: (id: number) => void;
  aiThinking: boolean;
  isRFQLinkProcessingEnabled: boolean;
  isRFQFileProcessingEnabled: boolean;
  addNewLink: () => void;
  openNewFileUploadModal: () => void;
  onNext: () => void;
  checkFilesAndLinksNextButtonDisabled: (isEmbedded: boolean) => boolean;
}

export default function RFQFilesAndLinksStep({
  files,
  links,
  removeFile,
  updateFile,
  updateLink,
  removeLink,
  aiThinking,
  isRFQLinkProcessingEnabled,
  isRFQFileProcessingEnabled,
  addNewLink,
  openNewFileUploadModal,
  onNext,
  checkFilesAndLinksNextButtonDisabled
}: RFQFilesAndLinksStepProps) {
  return (
    <>
      {aiThinking && (
        <div className="flex items-start justify-start w-full animate-fadeIn">
          <LMLoading small={false} messageOnLongWait={true} />
        </div>
      )}
      {!aiThinking && (
        <>
          <FilesAndLinksList
            files={files}
            links={links}
            removeFile={removeFile}
            updateFile={updateFile}
            updateLink={updateLink}
            removeLink={removeLink}
            aiThinking={aiThinking}
            withCaption={false}
          />
          <div className="flex gap-2 mb-4 animate-fadeIn w-full">
            <LinkButton
              isRFQLinkProcessingEnabled={isRFQLinkProcessingEnabled}
              disabled={aiThinking}
              addNewLink={addNewLink}
              embedded={false}
            />
            <FileButton
              isRFQFileProcessingEnabled={isRFQFileProcessingEnabled}
              disabled={aiThinking}
              openNewFileUploadModal={openNewFileUploadModal}
              embedded={false}
            />
            {(links.length > 0 || files.length > 0) && (
              <NextButton
                onClick={onNext}
                disabled={checkFilesAndLinksNextButtonDisabled(false)}
                pushToRight={true}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}
