import { lazy } from 'react';

import Home from './pages/Home/Home';
import ProductsPage from './pages/ProductsHome/ProductsPage';

import ProtectedRoute from './components/ProtectedRoute';

const Updates = lazy(() => import('./pages/UpdatesPage/UpdatesPage'));
const ProductDetail = lazy(() => import('./pages/ProductDetail/ProductDetail'));
const NewProductFlow = lazy(
  () => import('./pages/NewProductFlow/NewProductFlow')
);
const NewRfqFlow = lazy(() => import('./pages/NewRfqFlow/NewRfqFlow'));
const Profile = lazy(() => import('./pages/Profile/Profile'));
const Login = lazy(() => import('./pages/Authentication/Authentication'));
const Register = lazy(() => import('./pages/Authentication/Authentication'));
const Clarifications = lazy(
  () => import('./pages/Clarifications/Clarifications')
);

export const PATHS = {
  HOME: '/',
  LOGIN: 'login',
  REGISTER: 'register',
  PROFILE: 'profile',
  QUOTES: ':productId/quotes',
  UPDATES: 'updates',
  EXPIRED: 'expired',
  NEW_PRODUCT: 'new-product',
  PRODUCTID: ':productId',
  NEW_RFQ: 'new-rfq',
  CLARIFICATIONS: 'products'
};

export type RoutePath =
  | '/'
  | 'login'
  | 'expired'
  | 'register'
  | 'profile'
  | 'quotes/:productId'
  | 'updates'
  | 'new-product'
  | 'new-rfq'
  | 'products'
  | 'products/:productId';

export const nonAuthenticatedRoutes = [
  {
    path: PATHS.LOGIN,
    element: <Login />
  },
  {
    path: PATHS.EXPIRED,
    element: <Login />
  },
  {
    path: PATHS.REGISTER,
    element: <Register />
  }
];

export const authenticatedRoutes = [
  {
    index: true,
    element: (
      <ProtectedRoute>
        <Home />
      </ProtectedRoute>
    ),
    strict: true
  },

  {
    path: PATHS.PROFILE,
    element: (
      <ProtectedRoute>
        <Profile />
      </ProtectedRoute>
    )
  },
  {
    path: PATHS.UPDATES,
    element: (
      <ProtectedRoute>
        <Updates />
      </ProtectedRoute>
    )
  },
  {
    path: PATHS.NEW_PRODUCT,
    element: (
      <ProtectedRoute>
        <NewProductFlow />
      </ProtectedRoute>
    )
  },
  {
    path: PATHS.NEW_RFQ,
    element: (
      <ProtectedRoute>
        <NewRfqFlow />
      </ProtectedRoute>
    )
  },

  {
    path: PATHS.CLARIFICATIONS + '/:productId',
    element: (
      <ProtectedRoute>
        <Clarifications />
      </ProtectedRoute>
    )
  },
  {
    path: PATHS.CLARIFICATIONS + '/:productId/:tab',
    element: (
      <ProtectedRoute>
        <Clarifications />
      </ProtectedRoute>
    )
  }
];
