import React from 'react';

interface BackToLinksAndFilesLinkProps {
  onClick: () => void;
}

const BackToLinksAndFilesLink: React.FC<BackToLinksAndFilesLinkProps> = ({
  onClick
}) => {
  return (
    <div className="animate-fadeIn">
      <button
        onClick={onClick}
        className="text-p-cavela-blue hover:text-blue-700 text-sm font-600"
      >
        &lt; Back to add links &amp; files
      </button>
    </div>
  );
};

export default BackToLinksAndFilesLink;
