import apiService from 'src/shared/config/apiService';
import { ProductById } from 'src/shared/types/product';
import handleAxiosError from 'src/shared/utils/handleAxiosError';

export const getProductById = async (id: string) => {
  try {
    const response = await apiService.get<ProductById>(`/products/${id}`);

    return response;
  } catch (error) {
    throw handleAxiosError(error);
  }
};
