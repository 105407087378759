import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import i18next from 'i18next';

import { EmptyPropertyFieldProps } from './props';

const useLogic = ({
  title,
  aiThinking,
  updateDocumentItem
}: EmptyPropertyFieldProps) => {
  const from = useLocation();
  const { t } = i18next;
  const [newFields, setNewFields] = useState<
    Array<{ key: string; value: string }>
  >([]);
  const isReview = from.search.includes('review');

  const handleAddField = () => {
    setNewFields([...newFields, { key: '', value: '' }]);
  };

  const handleNewFieldRemove = (index: number) => {
    setNewFields(newFields.filter((_, i) => i !== index));
  };

  const handleNewFieldChange = (
    index: number,
    field: 'key' | 'value',
    value: string
  ) => {
    const updatedFields = [...newFields];
    updatedFields[index][field] = value;
    setNewFields(updatedFields);
  };

  const handleNewFieldSave = (index: number) => {
    const newField = newFields[index];
    if (newField.key && newField.value) {
      updateDocumentItem(title, newField.key, newField.value);
      setNewFields(newFields.filter((_, i) => i !== index));
    }
  };

  return {
    t,
    newFields,
    handleAddField,
    handleNewFieldRemove,
    handleNewFieldChange,
    handleNewFieldSave,
    isReview
  };
};

export default useLogic;
