import { Cog8ToothIcon, UsersIcon } from '@heroicons/react/16/solid';

// import { ArchiveBoxIcon } from '@heroicons/react/20/solid';
import { ROUTE_PATHS } from 'src/shared/config/routes';

export const SIDEBAR_NAV = [
  // {
  //   title: 'Products',
  //   path: ROUTE_PATHS.PRODUCTS,
  //   icon: <ArchiveBoxIcon className="size-4" />
  // },
  {
    title: 'Organization',
    path: ROUTE_PATHS.ORGANIZATION,
    icon: <UsersIcon className="size-4" />
  },
  {
    title: 'Account',
    path: ROUTE_PATHS.ACCOUNT,
    icon: <Cog8ToothIcon className="size-4" />
  }
];
