import apiService from 'src/shared/config/apiService';
import handleAxiosError from 'src/shared/utils/handleAxiosError';

export type GetUserTokenPayload = { email: string };
type GetUserTokenResponse = any;

export const getUserToken = async (
  payload: GetUserTokenPayload
): Promise<GetUserTokenResponse> => {
  try {
    const { data } = await apiService.post<GetUserTokenResponse>(
      '/users',
      payload
    );

    return data;
  } catch (error) {
    throw handleAxiosError(error);
  }
};
