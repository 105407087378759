import { cn } from '@heroui/react';
import React, { ElementType, ReactNode } from 'react';

type TypographyProps = {
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'h7'
    | 'body-1'
    | 'body-2'
    | 'body-3';
  as?: ElementType;
  weight?: 'regular' | 'medium' | 'bold';
  className?: string;
  children: ReactNode;
  onClick?: () => void;
};

const typographyStyles: Record<string, string> = {
  h1: 'text-[28px] font-semibold tracking-[-0.28px]',
  h2: 'text-[26px] font-semibold tracking-[-0.26px]',
  h3: 'text-[24px] font-semibold tracking-[-0.24px]',
  h4: 'text-[22px] font-semibold tracking-[-0.22px]',
  h5: 'text-[20px] font-semibold tracking-[-0.2px]',
  h6: 'text-[18px] font-semibold tracking-[-0.18px]',
  h7: 'text-[16px] font-semibold tracking-[-0.16px]',
  'body-1': 'text-[16px] tracking-[-0.16px] leading-[150%]',
  'body-2': 'text-[14px] tracking-[-0.14px] leading-[150%]',
  'body-3': 'text-[12px] tracking-[-0.12px] leading-[150%]'
};

const fontWeights: Record<string, string> = {
  regular: 'font-normal',
  medium: 'font-medium',
  bold: 'font-semibold'
};

const Typography: React.FC<TypographyProps> = ({
  variant = 'body-1',
  as = 'p',
  weight = 'regular',
  className = '',
  onClick,
  children
}) => {
  const Component = as;
  const typographyClass = typographyStyles[variant];
  const weightClass = fontWeights[weight];

  return (
    <Component
      onClick={onClick}
      className={cn(
        'font-figtree text-primary-text',
        typographyClass,
        weightClass,
        className
      )}
    >
      {children}
    </Component>
  );
};

export default Typography;
