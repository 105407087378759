import { wrapCreateBrowserRouter } from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';

import { authenticatedRoutes, nonAuthenticatedRoutes } from 'src/routes';
import * as Reporting from 'src/services/reporting';
import { DEBUG_MODE_ENABLED, ENVIRONMENT } from 'src/settings';

import Layout from 'src/components/Layout/Layout';
import PageLayout from 'src/components/Layout/PageLayout';
import NotAccessible from 'src/components/NotAccessible/NotAccessible';

import '@mantine/core/styles.css';

import '@mantine/carousel/styles.css';
import 'src/styles/tailwind.css';

Reporting.init({
  environment: ENVIRONMENT,
  debugModeEnabled: DEBUG_MODE_ENABLED as boolean
});

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const oldRouter = sentryCreateBrowserRouter([
  {
    id: 'not-authenticated',
    errorElement: <NotAccessible variant="text404" />,
    element: <PageLayout />,
    children: nonAuthenticatedRoutes
  },
  {
    id: 'root',
    path: '/',
    element: <Layout />,
    errorElement: <NotAccessible variant="text404" />,
    children: authenticatedRoutes
  }
]);
