import React, { useEffect, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

const images = [
  '/images/rfq_builder/cap.jpeg',
  '/images/rfq_builder/jacket.jpeg'
];

const RFQImagesCarousel = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000); // Switch every 4 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative w-full h-screen overflow-hidden">
      {images.map((image, index) => (
        <motion.div
          key={index}
          className="absolute inset-0 w-full h-full"
          initial={false}
          animate={{
            opacity: index === currentImageIndex ? 1 : 0
          }}
          transition={{
            opacity: { duration: 1, ease: 'easeOut' }
          }}
        >
          <motion.img
            src={image}
            alt="RFQ Product"
            className="w-full h-full object-cover"
            initial={{ scale: 1 }}
            animate={{
              scale: index === currentImageIndex ? 1.1 : 1
            }}
            transition={{
              scale: { duration: 4, ease: 'easeOut' }
            }}
          />
        </motion.div>
      ))}
    </div>
  );
};

export default RFQImagesCarousel;
