const AiIcon = ({ isColored = false }: { isColored?: boolean }) => {
  if (isColored) {
    return (
      <svg
        width="36"
        height="30"
        viewBox="0 0 36 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_169_187)">
          <rect
            x="29.4639"
            y="14.7922"
            width="9.24304"
            height="9.24304"
            rx="2.04597"
            transform="rotate(45 29.4639 14.7922)"
            fill="#000EEF"
          />
          <path
            d="M12.6624 5.90253C13.4127 3.87498 16.2804 3.87497 17.0307 5.90253L18.4848 9.83231C18.7207 10.4698 19.2233 10.9724 19.8607 11.2082L23.7905 12.6624C25.8181 13.4127 25.8181 16.2804 23.7905 17.0307L19.8607 18.4848C19.2233 18.7207 18.7207 19.2233 18.4848 19.8607L17.0307 23.7905C16.2804 25.8181 13.4127 25.8181 12.6624 23.7905L11.2082 19.8607C10.9724 19.2233 10.4698 18.7207 9.83231 18.4848L5.90253 17.0307C3.87498 16.2804 3.87497 13.4127 5.90253 12.6624L9.83231 11.2082C10.4698 10.9724 10.9724 10.4698 11.2082 9.83231L12.6624 5.90253Z"
            fill="#69DBFF"
          />
        </g>
        <defs>
          <clipPath id="clip0_169_187">
            <rect width="36" height="29.6931" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
    >
      <g clip-path="url(#clip0_8271_40743)">
        <rect
          x="18.0059"
          y="9.9668"
          width="5.64853"
          height="5.64853"
          rx="1.25031"
          transform="rotate(45 18.0059 9.9668)"
          fill="white"
        />
        <path
          d="M7.73813 4.53386C8.19662 3.2948 9.94913 3.2948 10.4076 4.53386L11.2963 6.93539C11.4404 7.32495 11.7476 7.63209 12.1371 7.77624L14.5387 8.66489C15.7777 9.12338 15.7777 10.8759 14.5387 11.3344L12.1371 12.223C11.7476 12.3672 11.4404 12.6743 11.2963 13.0639L10.4076 15.4654C9.94913 16.7045 8.19662 16.7045 7.73813 15.4654L6.84948 13.0639C6.70533 12.6743 6.39819 12.3672 6.00864 12.223L3.6071 11.3344C2.36804 10.8759 2.36804 9.12338 3.6071 8.66489L6.00864 7.77624C6.39819 7.63209 6.70533 7.32495 6.84948 6.93539L7.73813 4.53386Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_8271_40743">
          <rect
            width="22"
            height="18.1458"
            fill="white"
            transform="translate(0 0.926758)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AiIcon;
