import { cn } from '@heroui/react';
import React from 'react';

import { motion } from 'framer-motion';

interface AiButtonBorderProps {
  isIconOnly?: boolean;
}

const AiButtonBorder: React.FC<AiButtonBorderProps> = ({ isIconOnly }) => {
  return (
    <motion.div
      className={cn(
        'absolute h-[80px] w-[80px] top-[-50%] left-[-50%] right-0 bottom-0 rounded-[8px] overflow-hidden z-[1]',
        isIconOnly && 'h-[200px] w-auto top-[-80px] left-0'
      )}
      style={{
        maskImage: 'linear-gradient(white, white)',
        WebkitMaskImage: 'linear-gradient(white, white)',
        background: 'conic-gradient(from 0deg, #000EEF, #69DBFF)'
      }}
      animate={{
        rotate: [0, 360]
      }}
      transition={{
        duration: 2,
        repeat: Infinity,
        ease: 'linear'
      }}
    ></motion.div>
  );
};

export default AiButtonBorder;
