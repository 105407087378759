import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-places-autocomplete';

import useLogic from './logic';
import { ExtraPropertyFieldProps } from './props';

type AddressComponent = {
  long_name: string;
  short_name: string;
  types: string[];
};

const ExtraPropertyField: React.FC<ExtraPropertyFieldProps> = ({
  question,
  setQuestionValue
}) => {
  const { t, selectedOptions, handleOptionChange, isReview } = useLogic({
    question,
    setQuestionValue
  });

  const handlePlaceSelect = async (place: any) => {
    let finalValue = '';
    if (place && place.value) {
      finalValue = place.label;

      if (place.value.place_id) {
        const results = await geocodeByPlaceId(place.value.place_id);
        const addressComponents: AddressComponent[] =
          results[0].address_components;
        const postalCode = addressComponents.find((component) =>
          component.types.includes('postal_code')
        )?.long_name;
        if (postalCode) {
          finalValue = `${finalValue}, ${postalCode}`;
        }
      }
    }
    setQuestionValue(finalValue);
    handleOptionChange(finalValue);
  };
  return (
    <div>
      <h2
        className="text-lg font-600 font-bold text-white text-center mb-2"
        style={{ lineHeight: '30px' }}
      >
        {
          t(
            `${question?.attribute}.placeholder`,
            question?.attribute
          ) as React.ReactNode
        }
      </h2>
      <div className="bg-white rounded-lg shadow p-3">
        <h3
          className="text-md font-500 font-bold text-black text-left  mb-4"
          style={{ lineHeight: '20px' }}
        >
          {question?.question}
        </h3>
        <div className="mt-2 animate-fadeIn">
          {question?.question_type === 'address' ? (
            <div id={`${question?.attribute}-address`} className="text-left">
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_PUBLIC_API_KEY}
                selectProps={{
                  isDisabled: isReview,
                  onChange: handlePlaceSelect,
                  value: question?.answer
                    ? { label: question.answer, value: question.answer }
                    : undefined,
                  placeholder: 'Enter your shipping address',
                  noOptionsMessage: () => null
                }}
              />
            </div>
          ) : question?.options && question?.options.length > 0 ? (
            question?.options.map((option: string, index: number) => (
              <div
                key={`${index}-${option}`}
                className="flex items-center mb-3"
              >
                {question?.question_type !== 'address' && (
                  <input
                    type={
                      question?.question_type === 'single_choice'
                        ? 'radio'
                        : 'checkbox'
                    }
                    id={`${index}-${option}`}
                    name={question?.attribute}
                    value={question?.answer || option}
                    checked={selectedOptions.includes(option)}
                    onChange={() => {
                      handleOptionChange(option);
                    }}
                    className="mr-2 size-4"
                    disabled={isReview}
                  />
                )}
                <label
                  htmlFor={`${index}-${option}`}
                  className={`text-left text-md ${isReview ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  {option}
                </label>
              </div>
            ))
          ) : (
            <p className="text-gray-500 text-sm">{t('no_options_available')}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExtraPropertyField;
