import React from 'react';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import useLogic from './logic';
import { NewFieldsProps } from './props';

const NewFields = ({
  fieldKey,
  index,
  fieldValue,
  handleNewFieldChange,
  handleNewFieldSave,
  aiThinking,
  handleNewFieldRemove,
  type
}: NewFieldsProps) => {
  const { valueInputRef, handleKeyDown, typeError } = useLogic({
    handleNewFieldSave,
    index,
    type
  });

  return (
    <div className="flex items-center py-3 text-left">
      <div className="w-1/3">
        <input
          className="text-black font-medium font-500 outline-none border px-2 py-1 border-gray-300 rounded w-[60%]"
          value={fieldKey}
          onChange={(e) => handleNewFieldChange(index, 'key', e.target.value)}
          onKeyDown={(e) => handleKeyDown(e, 'key')}
          placeholder="Key"
          autoFocus
        />
      </div>
      <div className="w-2/3 flex items-center">
        <div className="relative w-full">
          <input
            ref={valueInputRef}
            className={`text-black font-medium w-full font-500 outline-none border px-2 py-1 border-gray-300 rounded pr-20 ${
              typeError ? 'border-red-500' : ''
            }`}
            value={fieldValue}
            onChange={(e) =>
              handleNewFieldChange(index, 'value', e.target.value)
            }
            onKeyDown={(e) => handleKeyDown(e, 'value')}
            placeholder="Value"
            type={type}
          />
          <button
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-black disabled:cursor-not-allowed disabled:opacity-50"
            onClick={() => handleNewFieldSave(index)}
            disabled={aiThinking || !fieldKey || !fieldValue || typeError}
          >
            <span className="h-full aspect-square flex items-center justify-center bg-border-light-gray-2 rounded cursor-pointer hover:bg-[#b0b1b4]">
              <CheckRoundedIcon style={{ fontSize: '16px' }} />
            </span>
          </button>
        </div>
        <button
          className="ml-2 text-gray-500 px-2 hover:scale-105 transition-all duration-300 ease-in-out"
          onClick={() => handleNewFieldRemove(index)}
        >
          <img
            src="/icons/remove.svg"
            alt="remove"
            title="Remove"
            className="mr-2"
            width="20"
            height="20"
          />
        </button>
      </div>
    </div>
  );
};

export default NewFields;
