import { UserIcon } from '@heroicons/react/16/solid';
import { Switch } from '@heroui/react';
import { useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { useAuthStore } from 'src/entities/user/store/useAuthStore';
import { ProductById } from 'src/shared/types/product';

import { ButtonV2, Tab, TabsV2, Typography } from 'src/shared/components/ui';

import useProductUpdates from '../../product-updates/hooks/useProductUpdates';
import EmptyUpdates from '../../product-updates/ui/EmptyUpdates';
import ProductUpdatesInput from '../../product-updates/ui/ProductUpdatesInput';
import ProductUpdatesList from '../../product-updates/ui/ProductUpdatesList';

interface ProductDetailUpdatesProps {
  product: ProductById;
}

const ProductDetailUpdates: React.FC<ProductDetailUpdatesProps> = ({
  product
}) => {
  const id = useAuthStore((state) => state.user?.id);
  const { data } = useProductUpdates(id as string, product.id);

  const [isOpenInput, setIsOpenInput] = useState(false);
  const [isResolvedOpen, setIsResolvedOpen] = useState(false);

  console.log('LOOK UPDATES', data);

  const renderSuppliers = () => {
    return product.supplier_cloaks.map((supplier) => (
      <Tab
        key={supplier.id}
        title={
          <div className="flex items-center gap-1">
            <UserIcon className="size-4" />
            <span>
              {supplier.cloak}-{supplier.id}
            </span>
          </div>
        }
      />
    ));
  };

  return (
    <div className="flex flex-col flex-grow gap-3 h-full">
      <div className="flex items-center justify-between">
        <Typography variant="h4" as="h2" weight="bold">
          Updates
        </Typography>
        <Switch
          onValueChange={(isSelected) => setIsResolvedOpen(isSelected)}
          isSelected={isResolvedOpen}
          size="sm"
          color="primary"
          className="flex-row-reverse"
          classNames={{
            label: '!flex-row-reverse mr-2'
          }}
        >
          <Typography variant="body-1" weight="bold" className="text-primary">
            Include resolved updates
          </Typography>
        </Switch>
      </div>

      <div className="flex justify-between items-center">
        <TabsV2 color="dark" variant="light">
          <Tab key="all" title="All" />
          {renderSuppliers()}
        </TabsV2>

        {!isOpenInput && (
          <ButtonV2 onPress={() => setIsOpenInput(!isOpenInput)} size="md">
            Post an update
          </ButtonV2>
        )}
      </div>

      <AnimatePresence>
        {isOpenInput && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <ProductUpdatesInput
              suppliers={product.supplier_cloaks}
              onClose={() => setIsOpenInput(false)}
            />
          </motion.div>
        )}
      </AnimatePresence>

      {isResolvedOpen ? (
        <ProductUpdatesList updates={data as any} />
      ) : (
        <EmptyUpdates />
      )}
    </div>
  );
};

export default ProductDetailUpdates;
