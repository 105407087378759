import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';

import {
  organizationFormSchema,
  OrganizationFormValues
} from '../model/schema';

export const useOrganizationForm = (
  defaultValues?: Partial<OrganizationFormValues>
) => {
  return useForm<OrganizationFormValues>({
    resolver: zodResolver(organizationFormSchema),
    defaultValues: {
      name: '',
      ...defaultValues
    }
  });
};
