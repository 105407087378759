import { RouterProvider } from 'react-router-dom';

import AuthProvider from 'src/providers/AuthProvider';
import { theme } from 'src/styles/theme';

import { MantineProvider } from '@mantine/core';

import AuthProviderV2 from './providers/AuthProviderV2';
import QueryClientProvider from './providers/QueryClientProvider';
import ThemeProvider from './providers/ThemeProvider';
import newRouter from './routes';
import { oldRouter } from './routes/old-routes';

import 'src/shared/styles/global.css';

const isNewRoute = () => window.location.pathname.startsWith('/v2/');

export const App = () => {
  const router = isNewRoute() ? newRouter : oldRouter;

  return (
    <QueryClientProvider>
      {isNewRoute() ? (
        <AuthProviderV2>
          <ThemeProvider>
            {/* // update once we remove old router */}
            <main className="bg-white min-h-screen v2-global">
              <RouterProvider router={router} />
            </main>
          </ThemeProvider>
        </AuthProviderV2>
      ) : (
        <AuthProvider>
          <MantineProvider theme={theme}>
            <RouterProvider router={router} />
          </MantineProvider>
        </AuthProvider>
      )}
    </QueryClientProvider>
  );
};

export default App;
