import AiIcon from '../AIIcon';
import Tag from '../ui/Tag';

// change logic once i integrate with backend data
const MATCH_TAG_COLORS = {
  '15': 'red',
  '50': 'yellow',
  '90': 'green',
  '100': 'flat'
} as const;

interface MatchTagProps {
  type?: keyof typeof MATCH_TAG_COLORS;
}

const MatchTag: React.FC<MatchTagProps> = ({ type }) => {
  if (!type) {
    return (
      <Tag classNames={{ base: 'px-2' }} color="none">
        -
      </Tag>
    );
  }

  if (type === '100') {
    return (
      <Tag
        classNames={{ content: '!pl-1 pr-2' }}
        style={{
          background:
            'linear-gradient(105deg, #69DBFF -22.69%, #000EEF 160.49%)'
        }}
        color="default"
      >
        <div className="text-white flex items-center justify-center font-semibold gap-[2px]">
          <AiIcon /> Top pick
        </div>
      </Tag>
    );
  }

  return (
    <Tag classNames={{ base: 'px-2' }} color={MATCH_TAG_COLORS[type]}>
      {type}%
    </Tag>
  );
};

export default MatchTag;
