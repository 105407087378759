import { CheckIcon } from '@heroicons/react/16/solid';

import Tag from '../ui/Tag';

const QUOTE_STATUS_COLORS = {
  no_action: 'no_action',
  sampled: 'flat',
  ordered: 'flat'
} as const;

interface QuoteStatusTagProps {
  type: keyof typeof QUOTE_STATUS_COLORS;
}

const QuoteStatusTag: React.FC<QuoteStatusTagProps> = ({ type }) => {
  const renderIcon = () => {
    if (type === 'sampled' || type === 'ordered') {
      return <CheckIcon className="size-4" />;
    }

    return null;
  };

  return (
    <Tag
      className="capitalize"
      color={QUOTE_STATUS_COLORS[type] || 'no_action'}
    >
      <div className="flex items-center justify-center gap-1">
        {renderIcon()} {type}
      </div>
    </Tag>
  );
};

export default QuoteStatusTag;
