import apiService from 'src/shared/config/apiService';
import handleAxiosError from 'src/shared/utils/handleAxiosError';

export type GetUserTokenWithCodePayload = {
  email: string;
  signup_access_code: string;
};
type GetUserTokenWithCodeResponse = any;

export const getUserTokenWithCode = async (
  payload: GetUserTokenWithCodePayload
): Promise<GetUserTokenWithCodeResponse> => {
  try {
    const { data } = await apiService.post<GetUserTokenWithCodeResponse>(
      '/users',
      payload
    );

    return data;
  } catch (error) {
    throw handleAxiosError(error);
  }
};
