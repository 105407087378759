import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { create } from 'zustand';

import { Organization } from '../types/Organization';

export const STORAGE_KEY = 'selectedOrganization';

interface OrganizationStore {
  selectedOrganization: Organization | null;
  setSelectedOrganization: (org: Organization | null) => void;
  onOrganizationChange?: () => void;
  setOnOrganizationChange: (callback: () => void) => void;
  initFromUrlOrDefault: (
    organizations: Organization[] | undefined
  ) => Organization | null;
}

const useOrganizationStore = create<OrganizationStore>()((set, get) => ({
  selectedOrganization: JSON.parse(localStorage.getItem(STORAGE_KEY) || 'null'),
  onOrganizationChange: undefined,
  setOnOrganizationChange: (callback) =>
    set({ onOrganizationChange: callback }),
  setSelectedOrganization: (org) => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(org));
    set({ selectedOrganization: org });
    get().onOrganizationChange?.();
  },
  initFromUrlOrDefault: (organizations) => {
    if (!organizations) return null;

    // Get organization ID from URL
    const params = new URLSearchParams(window.location.search);
    const organizationId = params.get('organization');
    if (!organizationId) {
      // Orgs exist and no org is selected, let's select the first one
      if (!get().selectedOrganization) {
        console.log('No organization selected, selecting first one');
        console.log(localStorage.getItem(STORAGE_KEY));
        console.log(get().selectedOrganization);
        get().setSelectedOrganization(organizations[0]);
        return organizations[0];
      }
      return null;
    }

    // Find organization in user's organizations
    const orgId = parseInt(organizationId, 10);
    var organization = organizations.find((org) => org.id === orgId);
    if (!organization) {
      organization = organizations[0];
    }
    get().setSelectedOrganization(organization);
    return organization;
  }
}));

export default useOrganizationStore;
