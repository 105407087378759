import { useMutation } from '@tanstack/react-query';

import {
  updateUserAvatar,
  UpdateUserAvatarPayload
} from '../api/updateUserAvatar';

export const useUpdateUserAvatar = () => {
  return useMutation({
    mutationFn: async (payload: UpdateUserAvatarPayload) => {
      return await updateUserAvatar(payload);
    }
  });
};
