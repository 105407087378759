import { create } from 'zustand';

interface ImageGenerationState {
  isGeneratingImage: boolean;
  setIsGeneratingImage: (isGenerating: boolean) => void;
}

export const useImageGenerationStore = create<ImageGenerationState>()(
  (set) => ({
    isGeneratingImage: false,
    setIsGeneratingImage: (isGenerating) =>
      set({ isGeneratingImage: isGenerating })
  })
);
