import React, { useEffect, useRef } from 'react';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';

import TextareaAutoH from 'src/pages/Clarifications/components/AskAnywhere/components/TextareaAutoH/TextareaAutoH';

import useLogic from './logic';
import { AddLinkProps } from './props';

const AddLink = ({
  link,
  isEditing,
  setIsEditing,
  handleAddLink,
  removeLink,
  index,
  fromProductSide,
  showConfirmModal,
  setSavedRemoveLinkFunction,
  withCaption
}: AddLinkProps) => {
  const {
    linkURLRef,
    linkTextRef,
    handleKeyDown,
    text,
    setText,
    url,
    setUrl,
    error,
    saveLink,
    updateSavedLink
  } = useLogic({
    link,
    isEditing,
    setIsEditing,
    handleAddLink,
    removeLink,
    index
  });

  const componentRef = useRef<HTMLDivElement>(null);
  const [isInvalid, setIsInvalid] = React.useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target as Node)
      ) {
        if (url && (text || !withCaption)) {
          saveLink();
        } else {
          setIsInvalid(true);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [url, text, saveLink, withCaption]);

  return (
    <div
      ref={componentRef}
      className={`flex gap-1 flex-col p-4 bg-bg-light-gray  border-gray-300 rounded-lg ${isInvalid ? 'border-red-500 border-2' : 'border'}`}
    >
      {error && <p className="text-red-500 text-sm ml-8 mb-2">{error}</p>}
      <div className="flex gap-1 items-center">
        <div className="flex items-center justify-center size-4 shrink-0">
          <LinkIcon
            style={{
              fontSize: '16px'
            }}
          />
        </div>

        <input
          type="url"
          ref={linkURLRef}
          value={url}
          onChange={(e) => {
            setUrl(e.target.value);
            setIsInvalid(false);
            if (!withCaption) {
              updateSavedLink(e.target.value);
            }
          }}
          onKeyDown={handleKeyDown}
          placeholder="http://"
          className="w-full p-1 md:p-2 text-sm border border-black bg-bg-light-gray rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          autoFocus
          onBlur={() => {
            if (!withCaption) {
              saveLink();
            }
          }}
          style={{ borderColor: error ? 'red' : 'gray' }}
        />

        <button
          className="size-4 text-gray-500 hover:scale-105 transition-all duration-300 flex items-center justify-center"
          onClick={() => {
            if (showConfirmModal) {
              showConfirmModal();
              if (setSavedRemoveLinkFunction) {
                setSavedRemoveLinkFunction(() => () => removeLink(index));
              }
            } else {
              removeLink(index);
            }
          }}
        >
          <DeleteIcon
            style={{
              fontSize: '16px'
            }}
          />
        </button>
      </div>
      {withCaption && (
        <div className="flex gap-1">
          <div className="size-4 shrink-0" />
          <div className="relative w-full">
            <TextareaAutoH
              value={text}
              placeholder="Caption of this link"
              onChange={(e) => {
                setText(e.target.value);
                setIsInvalid(false);
              }}
              onKeyDown={handleKeyDown}
              className="w-full p-1 md:p-2 text-sm border border-black bg-bg-light-gray rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />

            <div className="h-full absolute right-0 top-0 flex items-start justify-center">
              <button
                className="h-6 w-6 text-black mt-1 mr-1 md:mr-2 md:mt-2 hover:scale-105 transition-all duration-300 disabled:cursor-not-allowed disabled:opacity-50"
                onClick={saveLink}
                disabled={!text || !url}
              >
                <span className="h-full aspect-square flex items-center justify-center bg-border-light-gray-2 rounded cursor-pointer hover:bg-[#b0b1b4]">
                  <CheckRoundedIcon style={{ fontSize: '16px' }} />
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddLink;
