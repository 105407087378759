import React from 'react';

const AvatarList = () => {
  return (
    <div className="flex">
      <img
        className="size-8 rounded-full ring-2 ring-white"
        src="/images/people/avatar1.jpeg"
        alt="Avatar 1"
      />
      <img
        className="size-8 rounded-full ring-2 ring-white -ml-2"
        src="/images/people/avatar2.jpeg"
        alt="Avatar 2"
      />
      <img
        className="size-8 rounded-full ring-2 ring-white -ml-2"
        src="/images/people/avatar3.jpeg"
        alt="Avatar 3"
      />
      <img
        className="size-8 rounded-full ring-2 ring-white -ml-2"
        src="/images/people/avatar4.jpeg"
        alt="Avatar 4"
      />
    </div>
  );
};

export default AvatarList;
