import { useMutation } from '@tanstack/react-query';

import {
  updateUserNotifications,
  UpdateUserNotificationsPayload
} from '../api/updateUserNotifications';

export const useUpdateUserNotifications = () => {
  return useMutation({
    mutationFn: async (payload: UpdateUserNotificationsPayload) => {
      return await updateUserNotifications(payload);
    }
  });
};
