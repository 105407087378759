import { BellIcon, CheckIcon } from '@heroicons/react/16/solid';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';

import toReadableLabel from 'src/shared/utils/toReadableLabel';

import Tag from '../ui/Tag';

// Added these types to cater to V1
// '"in-progress" | "action-required" | "new-update"'

const TASK_TAG_COLORS = {
  'in-progress': 'green',
  'action-required': 'yellow',
  'new-update': 'primary',
  update: 'flat',
  task: 'primary',
  none: 'none'
} as const;

interface TaskStatusTagProps {
  type: keyof typeof TASK_TAG_COLORS;
}

const TaskStatusTag: React.FC<TaskStatusTagProps> = ({ type }) => {
  const renderIcon = () => {
    if (type === 'in-progress') {
      return <CheckIcon className="size-4" />;
    }

    if (type === 'new-update') {
      return <BellIcon className="size-4" />;
    }

    if (type === 'action-required') {
      return <ExclamationTriangleIcon className="size-4" />;
    }

    return null;
  };

  return (
    <Tag
      radius="full"
      className="capitalize"
      color={TASK_TAG_COLORS[type] || 'none'}
    >
      <div className="flex items-center justify-center gap-1">
        {renderIcon()} {toReadableLabel(type)}
      </div>
    </Tag>
  );
};

export default TaskStatusTag;
