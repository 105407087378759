import { HeroUIProvider, ToastProvider } from '@heroui/react';

interface ThemeProviderProps {
  children: React.ReactNode;
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  return (
    <HeroUIProvider>
      <ToastProvider
        toastOffset={0}
        placement="bottom-left"
        toastProps={{
          variant: 'flat',
          size: 'md',
          shadow: 'lg',
          classNames: {
            motionDiv: '!w-[98vw] !h-10 mb-4 !border-none !mr-4', // Full width, no overflow
            base: '!w-full !max-w-none !h-10 !border-none', // Full width, no overflow
            content: '!w-full !gap-1',
            wrapper: '!w-full',
            icon: '!size-5'
          }
        }}
      />
      {children}
    </HeroUIProvider>
  );
};

export default ThemeProvider;
