import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';

import { Nav } from '../ui';

const FabButton = () => {
  return (
    <Nav color="primary" isIconOnly radius="full" className="!shadow-xl">
      <QuestionMarkCircleIcon className="size-4" />
    </Nav>
  );
};

export default FabButton;
