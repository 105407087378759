import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';

import { UserProfileFormValues, userProfileSchema } from '../models/schema';

export const useUpdateUserProfileForm = (
  defaultValues?: Partial<UserProfileFormValues>
) => {
  return useForm<UserProfileFormValues>({
    resolver: zodResolver(userProfileSchema),
    defaultValues: {
      ...defaultValues
    }
  });
};
