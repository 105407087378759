import { useEffect, useRef, useState } from 'react';

import { Link } from 'src/pages/NewRfqFlow/props';

import { AddLinkProps } from './props';

const useLogic = ({
  link,
  isEditing,
  index,
  handleAddLink,
  setIsEditing,
  removeLink
}: AddLinkProps) => {
  const [text, setText] = useState('');
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const linkURLRef = useRef<HTMLInputElement>(null);
  const linkTextRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditing) {
      setUrl(link.url);
      setText(link.caption);
    }
  }, [isEditing, link.caption, link.url]);

  const isValidURL = (url: string) => {
    const urlPattern =
      /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    return urlPattern.test(url);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (e.currentTarget === linkURLRef.current) {
        if (url.trim() === '') {
          setError('Please enter a URL');
          return;
        } else if (!isValidURL(url)) {
          setError('Please enter a valid URL');
          return;
        }
        setError('');
        linkTextRef.current?.focus();
      } else {
        if (url.trim() === '') {
          setError('Please enter a URL');
          linkURLRef.current?.focus();
        } else if (!isValidURL(url)) {
          setError('Please enter a valid URL');
          linkURLRef.current?.focus();
        } else if (text.trim() === '') {
          return;
        } else {
          saveLink();
        }
      }
    }
  };

  const saveLink = () => {
    updateSavedLink();
    setIsEditing?.(false);
  };

  const updateSavedLink = (aUrl?: string) => {
    const newLink: Link = { url: aUrl || url, caption: text };
    handleAddLink(index, newLink);
    setError('');
  };

  return {
    linkURLRef,
    linkTextRef,
    handleKeyDown,
    text,
    setText,
    url,
    setUrl,
    error,
    saveLink,
    updateSavedLink: updateSavedLink
  };
};

export default useLogic;
