import { cn } from '@heroui/react';

import AiIcon from '../AIIcon';

interface AiAvatarProps {
  size?: 'lg' | 'md' | 'sm' | undefined;
}

const sizes = {
  lg: 'size-9',
  md: 'size-7',
  sm: 'h-[22px] w-[22px]'
};

const AiAvatar: React.FC<AiAvatarProps> = ({ size = 'lg' }) => {
  return (
    <div
      className={cn(
        'bg-primary rounded-full p-1 flex items-center justify-center',
        sizes[size]
      )}
    >
      <AiIcon />
    </div>
  );
};

export default AiAvatar;
