import apiService from 'src/shared/config/apiService';
import handleAxiosError from 'src/shared/utils/handleAxiosError';

const getProductUpdates = async (id: string, product_id: string) => {
  try {
    const response = await apiService.get<any[]>(
      `/users/${id}/updates?product_id=${product_id}`
    );

    return response;
  } catch (error) {
    throw handleAxiosError(error);
  }
};

export default getProductUpdates;
