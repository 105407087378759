import React from 'react';

import { InsertDriveFile } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';

import AddFile from '../AddFile/AddFile';
import useLogic from './logic';
import { PreviewFileProps } from './props';

const PreviewFile = ({
  file,
  removeFile,
  updateFile,
  index,
  disabled,
  fromProductSide,
  keyValue,
  showConfirmModal,
  withCaption
}: PreviewFileProps) => {
  const { isEditing, startEditMode, setIsEditing } = useLogic({
    removeFile,
    index
  });

  const finishEditMode = (numberValue: number, file: any) => {
    setIsEditing(false);

    if (fromProductSide) {
      updateFile(numberValue, file.caption);
    } else {
      updateFile(numberValue, file, keyValue || '');
    }
  };

  const downloadFile = () => {
    window.open(file.url, '_blank');
  };

  if (isEditing) {
    return (
      <AddFile
        file={file}
        captionBase={file.caption}
        handleAddFile={finishEditMode}
        handleUpdateFile={finishEditMode}
        removeFile={removeFile}
        index={index}
        fromProductSide={fromProductSide}
        keyValue={keyValue}
        fileId={file.id}
        setIsEditing={setIsEditing}
        showConfirmModal={showConfirmModal}
      />
    );
  }

  return (
    <div
      className={`left-4 flex gap-1 flex-col right-4 p-4 bg-bg-light-gray border border-gray-300 rounded-lg
        hover:shadow-md transition-all duration-300 ease-in-out ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
    >
      <div className="flex gap-1 items-center">
        <div className="flex items-center justify-center size-4 shrink-0">
          <InsertDriveFile
            style={{
              fontSize: '16px'
            }}
          />
        </div>
        <strong
          className="w-full text-md text-black cursor-pointer hover:underline truncate text-left"
          onClick={() => {
            if (disabled) return;
            downloadFile();
          }}
        >
          {file.name || file.file?.name}
        </strong>
        <button
          className="size-4 shrink-0 text-gray-500 hover:scale-105 transition-all duration-300 z-10 flex items-center justify-center"
          onClick={(e) => {
            if (disabled) return;
            if (showConfirmModal) {
              showConfirmModal();
            } else {
              e.stopPropagation();
              removeFile(index);
            }
          }}
          disabled={disabled}
        >
          <DeleteIcon
            style={{
              fontSize: '16px'
            }}
          />
        </button>
      </div>

      {withCaption && file.caption && (
        <div className="flex gap-1">
          <div className="size-4 shrink-0" />
          <div
            className={`${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} text-left`}
            onClick={() => {
              if (disabled) return;
              startEditMode();
            }}
          >
            <span className="text-md w-full text-black">{file.caption}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default PreviewFile;
