import React from 'react';

import { UserAvatar } from 'src/shared/components';

import { Typography } from 'src/shared/components/ui';

interface MemberNameCellProps {
  name: string;
  image: string | null;
}

const MemberNameCell: React.FC<MemberNameCellProps> = ({ name, image }) => {
  return (
    <div className="flex gap-2 items-center">
      <UserAvatar name={name} image={image ? image : ''} />
      <Typography variant="body-1" weight="bold">
        {name}
      </Typography>
    </div>
  );
};

export default MemberNameCell;
