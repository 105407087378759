import {
  BellIcon,
  CheckIcon,
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/20/solid';

interface UpdateItemHeaderProps {
  variant: 'alert' | 'caution' | 'success' | 'info' | 'question' | 'loading';
  text: string;
  icon: any;
  styles: string;
  textStyles: string;
}

const getUpdateItemHeader = (
  update_type: string,
  isRelevant: boolean,
  isAnswered: boolean
): UpdateItemHeaderProps => {
  if (update_type === 'block') {
    return {
      variant: 'caution',
      text: 'Action Required',
      icon: (
        <ExclamationTriangleIcon className="size-4 bg-yellow-1 text-yellow-dark-2" />
      ),
      styles: 'bg-yellow-1',
      textStyles: 'text-yellow-dark-2'
    };
  }

  if (update_type === 'user-question' && isRelevant) {
    return {
      variant: 'question',
      text: 'Question',
      icon: (
        <QuestionMarkCircleIcon className="size-4 bg-purple-light-4 text-purple-dark-2" />
      ),
      styles: 'bg-purple-light-4',
      textStyles: 'text-purple-dark-2'
    };
  }
  if (!isRelevant || isAnswered) {
    return {
      variant: 'success',
      text: 'Resolved',
      icon: <CheckIcon className="size-4 text-green-dark" />,
      styles: 'bg-green-light-6',
      textStyles: 'text-green-dark'
    };
  }

  return {
    variant: 'info',
    text: 'New Update',
    icon: <BellIcon className="size-4 text-primary" />,
    styles: 'bg-bg-light-blue',
    textStyles: 'text-primary'
  };
};

export default getUpdateItemHeader;
