import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import MembersList from 'src/features/organization/member-list/ui/MembersList';
import OrganizationProfile from 'src/features/organization/organization-profile/ui/OrganizationProfile';

import { Tab, TabsV2, Typography } from 'src/shared/components/ui';

const OrganizationPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = useMemo(() => {
    return searchParams.get('tab') || 'profile';
  }, [searchParams]);

  const renderTabContent = () => {
    switch (activeTab) {
      case 'profile':
        return <OrganizationProfile />;
      case 'members':
        return <MembersList />;

      default:
        return null;
    }
  };

  return (
    <div className="h-full relative">
      <div className="bg-white">
        <div className="pt-4 max-w-[1200px] mx-auto">
          <div className="px-6">
            <Typography as="h1" variant="h2" weight="bold">
              Organization
            </Typography>
          </div>
          <div className="px-4 mt-2 relative top-[4px]">
            <TabsV2
              selectedKey={activeTab}
              onSelectionChange={(key) => setSearchParams({ tab: `${key}` })}
            >
              <Tab key="profile" title="Profile" />
              <Tab key="members" title="Members" />
            </TabsV2>
          </div>
        </div>
        <div className="w-full h-[1px] bg-gray-4 relative" />
      </div>

      <div className="py-4 px-6 max-w-[1200px] mx-auto">
        {renderTabContent()}
      </div>
    </div>
  );
};

export default OrganizationPage;
