export default function Banner() {
  return (
    <div className="py-2 flex items-center gap-2 text-xs sm:text-sm md:text-md bg-p-cavela-blue text-white px-3 sm:px-5 justify-center">
      <span className="text-center">
        Eid celebrations will be from Mar 28 to Apr 4. Expect slowdowns in
        Pakistan and Bangladesh before, during, and after these dates.
      </span>
    </div>
  );
}
