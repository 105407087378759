const sampleQuotes = [
  {
    id: 0,
    sampleImages: ['img1.png', 'img2.png'],
    type: 'Embroidery letters',
    unitCost: 4.5,
    quantity: 500,
    supplier: 'Jason',
    match: '100',
    matchConfidence: null,
    status: 'ordered',
    uploaded: '2025-02-12'
  },
  {
    id: 1,
    sampleImages: ['img3.png', 'img4.png'],
    type: 'Embroidery letters',
    unitCost: 4.5,
    quantity: 500,
    supplier: 'Jason',
    match: '100',
    matchConfidence: null,
    status: 'sampled',
    uploaded: '2025-02-12'
  },
  {
    id: 2,
    sampleImages: ['img5.png', 'img6.png'],
    type: 'Embroidery letters',
    unitCost: 4.5,
    quantity: 500,
    supplier: 'Jason',
    match: '100',
    matchConfidence: null,
    status: 'no_action',
    uploaded: '2025-02-12'
  },
  {
    id: 3,
    sampleImages: ['img7.png', 'img8.png'],
    type: 'Embroidery letters',
    unitCost: 4.5,
    quantity: 500,
    supplier: 'Jason',
    match: '90',
    matchConfidence: '90%',
    status: 'no_action',
    uploaded: '2025-02-12'
  },
  {
    id: 4,
    sampleImages: ['img9.png', 'img10.png'],
    type: 'Embroidery letters',
    unitCost: 4.5,
    quantity: 500,
    supplier: 'Jason',
    match: '50',
    matchConfidence: '50%',
    status: 'no_action',
    uploaded: '2025-02-12'
  }
];
export default sampleQuotes;
