import { ChevronDownIcon } from '@heroicons/react/16/solid';
import { ArchiveBoxIcon } from '@heroicons/react/20/solid';
import { cn } from '@heroui/react';
import React, { useEffect, useState } from 'react';
import { useMatch, useSearchParams } from 'react-router-dom';

import { motion } from 'framer-motion';
import { useProducts } from 'src/features/product/product-list/hooks/useProducts';
import { ROUTE_PATHS } from 'src/shared/config/routes';

import { ButtonV2 } from '../../ui';
import SidebarItem from './SidebarItem';

interface ProductsListNavProps {
  isOpen: boolean;
}

const getPath = (id: string, detailTab: string | null) => {
  if (detailTab) {
    return `${ROUTE_PATHS.PRODUCTS}/${id}?detailTab=${detailTab}`;
  }

  return `${ROUTE_PATHS.PRODUCTS}/${id}`;
};

const ProductsListNav: React.FC<ProductsListNavProps> = ({ isOpen }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { data } = useProducts();
  const [searchParams] = useSearchParams();

  const detailTab = searchParams.get('detailTab');

  const match = useMatch(ROUTE_PATHS.PRODUCT_DETAIL);

  useEffect(() => {
    if (!!match) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [match]);

  useEffect(() => {
    if (!isOpen) {
      if (isVisible) {
        setIsVisible(false);
      }
    }
  }, [isOpen, isVisible]);

  const renderProducts = () => {
    if (!data) return;

    return (
      <div className="flex flex-col gap-2">
        {data?.map((product) => (
          <SidebarItem
            key={product.id}
            isOpen={isOpen}
            title={product.detail.name}
            path={getPath(product.id, detailTab)}
            icon={<span className="ml-1">•</span>}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="relative flex flex-col gap-2 mb-2">
      <SidebarItem
        isOpen={isOpen}
        title="Products"
        path={ROUTE_PATHS.PRODUCTS}
        icon={<ArchiveBoxIcon className="size-4" />}
      />
      {isOpen && (
        <ButtonV2
          onPress={() => setIsVisible((prev) => !prev)}
          isIconOnly
          size="xs"
          className="absolute right-3 top-[10px] bottom-0 bg-transparent"
        >
          <ChevronDownIcon
            className={cn(
              'size-4 text-secondary-text transition-all duration-200',
              isVisible ? 'rotate-180' : ''
            )}
          />
        </ButtonV2>
      )}

      <motion.div
        initial={
          isVisible
            ? { maxHeight: 360, opacity: 1 }
            : { maxHeight: 0, opacity: 0 }
        }
        animate={{
          maxHeight: isVisible ? 360 : 0,
          opacity: isVisible ? 1 : 0
        }}
        className={cn('overflow-hidden')}
      >
        <div className="overflow-y-scroll products-list-scrollbar h-full max-h-[360px]">
          {renderProducts()}
        </div>
      </motion.div>
    </div>
  );
};

export default ProductsListNav;
