import { extendVariants, Input as StyledInput } from '@heroui/react';

const Input = extendVariants(StyledInput, {
  variants: {
    color: {
      default: {
        input: '!bg-white h-10 font-medium',
        inputWrapper: [
          'shadow-none',
          '!bg-white border-1 border-gray-4',
          'hover:!bg-white',
          'focus-within:!bg-white focus-within:border-foreground',
          '!cursor-text',
          'rounded-lg'
        ],
        label: '!text-secondary-text'
      }
    },
    isInvalid: {
      true: { base: '' }
    },
    isDisabled: {
      true: {
        base: '!opacity-100',
        input: 'placeholder:text-gray-7 !opacity-100',
        inputWrapper: '!bg-gray-1 !opacity-100'
      }
    }
  },
  defaultVariants: {
    color: 'default',
    textSize: 'base',
    placeholder: ' ',
    labelPlacement: 'outside',
    size: 'lg',
    variant: 'bordered'
  }
});

export default Input;
