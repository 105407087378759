import { motion } from 'framer-motion';
import LoginForm from 'src/features/login/ui/LoginForm';
import { Logo } from 'src/shared/components';

const ExpiredPage = () => {
  return (
    <div className="bg-background">
      <div className="bg-white min-h-screen flex flex-col">
        <div className="grid-cols-2 md:grid flex h-full flex-1 w-full">
          <div className="relative w-full p-10">
            <div className="w-full md:max-w-lg md:mx-auto flex flex-col justify-center h-full">
              <Logo />

              <LoginForm isExpired />
            </div>
          </div>

          <div
            className="hidden items-center justify-center md:flex"
            style={{
              background: 'url(/backgrounds/loginBgBlue.png)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <motion.img
              initial={{ y: 24, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ ease: 'easeInOut', duration: 1 }}
              draggable="false"
              className="w-[60%]"
              alt="ipad"
              src="/backgrounds/ipadwithtextblue.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpiredPage;
