import { useMutation } from '@tanstack/react-query';

import {
  updateOrganizationAvatar,
  UpdateOrganizationAvatarPayload
} from '../api/updateOrganizationAvatar';

export const useUpdateOrganizationAvatar = () => {
  return useMutation({
    mutationFn: async ({ avatar, id }: UpdateOrganizationAvatarPayload) => {
      return await updateOrganizationAvatar({ avatar, id });
    }
  });
};
