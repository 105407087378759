import { useState } from 'react';

import { API_URL } from 'src/settings';
import { fetchWithAuth } from 'src/utils/fetchWithAuth';

import { UploadFileModalProps } from './props';

const ALLOWED_IMAGE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/gif',
  'image/webp'
];

const useLogic = ({
  onClose,
  addNewFile,
  isRFQFinalConfirmation,
  rfqId,
  onUploadSuccess
}: UploadFileModalProps) => {
  const [file, setFile] = useState<File | File[] | null>(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const validateFiles = (files: File[]): boolean => {
    for (const file of files) {
      // If it's an image file, check if it's an allowed type
      if (file.type.startsWith('image/')) {
        if (!ALLOWED_IMAGE_TYPES.includes(file.type)) {
          setError('Images must be PNG, JPEG, GIF, or WEBP format');
          return false;
        }
      }
      // Non-image files are allowed without restriction
    }
    setError(null);
    return true;
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const fileArray = Array.from(selectedFiles);
      if (validateFiles(fileArray)) {
        setFile(fileArray);

        if (isRFQFinalConfirmation) {
          const formData = new FormData();
          fileArray.forEach((file) => {
            formData.append('files', file);
            formData.append('caption', file.name);
          });

          const response = await fetchWithAuth(
            `${API_URL}/api/rfqs/${rfqId}/files`,
            {
              method: 'POST',
              body: formData
            }
          );
          if (response.ok) {
            const data = await response.json();

            if (onUploadSuccess) {
              onUploadSuccess(data);
            }
            addNewFile(fileArray);
            onClose();
          } else {
            setError('Failed to upload files');
          }
        } else {
          addNewFile(fileArray);
          onClose();
        }
      }
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    if (droppedFiles.length > 0) {
      if (validateFiles(droppedFiles)) {
        setFile(droppedFiles);

        if (isRFQFinalConfirmation) {
          const formData = new FormData();
          droppedFiles.forEach((file) => {
            formData.append('files', file);
            formData.append('caption', file.name);
          });

          const response = await fetchWithAuth(
            `${API_URL}/api/rfqs/${rfqId}/files`,
            {
              method: 'POST',
              body: formData
            }
          );
          if (response.ok) {
            // Get the updated RFQ data from the response
            const data = await response.json();

            // Call onUploadSuccess with the updated RFQ data
            if (onUploadSuccess) {
              onUploadSuccess(data.rfq);
            }
            addNewFile(droppedFiles);
            onClose();
          } else {
            setError('Failed to upload files');
          }
        } else {
          // Default behavior for other cases
          addNewFile(droppedFiles);
          onClose();
        }
      }
    }
  };

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return {
    file,
    isDragOver,
    error,
    handleFileUpload,
    handleOutsideClick,
    handleDragOver,
    handleDragEnter,
    handleDragLeave,
    handleDrop
  };
};

export default useLogic;
