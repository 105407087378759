import { Organization, User } from 'src/shared/types/user';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type AuthState = {
  user: User | null;
  authToken: string | null;
  newToken: string | null;
  fallBackToken: string | null;
  selectedOrganization: Organization | null;
};

type AuthActions = {
  setAuthToken: (authToken: string | null) => void;
  setFallBackToken: (authToken: string | null) => void;
  setNewToken: (authToken: string | null) => void;
  setUser: (user: any) => void;
  setSelectedOrganization: (organization: Organization | null) => void;
  logout: () => void;
};

const initialState: AuthState = {
  user: null,
  authToken: null,
  selectedOrganization: null,
  fallBackToken: null,
  newToken: null
};

export const useAuthStore = create<AuthState & AuthActions>()(
  persist(
    (set) => ({
      ...initialState,
      logout: () => set(initialState),
      setAuthToken: (authToken) => set({ authToken }),
      setFallBackToken: (fallBackToken) => set({ fallBackToken }),
      setNewToken: (newToken) => set({ newToken }),
      setSelectedOrganization: (selectedOrganization: Organization | null) =>
        set({ selectedOrganization }),
      setUser: (user) => set({ user })
    }),
    {
      name: 'session',
      storage: createJSONStorage(() => localStorage)
    }
  )
);
