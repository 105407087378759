import React, { useState } from 'react';

import { API_URL } from 'src/settings';
import { fetchWithAuth } from 'src/utils/fetchWithAuth';

import AiImage from '../AiImage/AiImage';
import ImageUploader from './ImageUploader';
import useCoverArtLogic from './logic';

interface CoverArtProps {
  image: string | null;
  setImage: (image: string) => void;
  rfqId: number;
  buttonsOnRight: React.ReactNode;
}

export default function CoverArt({
  setImage,
  rfqId,
  buttonsOnRight
}: CoverArtProps) {
  const [error, setError] = useState<string | null>(null);
  const [useAiImage, setUseAiImage] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updatingImage, setUpdatingImage] = useState<string | null>(null);
  const [selectedImageId, setSelectedImageId] = useState<string | null>(null);
  const [localAdditionalImages, setLocalAdditionalImages] = useState<string[]>(
    []
  );

  const { isGeneratingImage, generateAiImage, additionalImages } =
    useCoverArtLogic({
      rfqId,
      setImage: (newImage: string) => {
        setImage(newImage);
        setSelectedImageId(newImage);
      },
      useAiImage
    });

  // Sync local additional images with ones from the hook
  React.useEffect(() => {
    if (additionalImages && additionalImages.length > 0) {
      setLocalAdditionalImages(additionalImages);
    }
  }, [additionalImages]);

  const handleSelectImage = async (selectedImage: string) => {
    if (isUpdating) return;

    // Set the selected image ID immediately for UI feedback
    setSelectedImageId(selectedImage);
    setIsUpdating(true);
    setUpdatingImage(selectedImage);
    setError(null);

    try {
      // Make the selected image the main cover art image
      const response = await fetchWithAuth(`${API_URL}/api/rfqs/${rfqId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ image: selectedImage })
      });

      if (!response.ok) {
        throw new Error('Failed to update cover art');
      }

      // Update the parent component's image state
      setImage(selectedImage);
    } catch (err) {
      // Revert the selected image ID on error
      setError('Failed to update cover art. Please try again.');
    } finally {
      setIsUpdating(false);
      setUpdatingImage(null);
    }
  };

  // Create array of loading placeholders - always show 4 total slots
  const totalSlots = 4;
  const loadingPlaceholders = isGeneratingImage
    ? Array(totalSlots - localAdditionalImages.length)
        .fill(null)
        .map((_, i) => i)
    : [];

  return (
    <div className="flex flex-col items-start gap-2">
      <div className="flex flex-row gap-3">
        {/* Loading placeholders */}
        {loadingPlaceholders.length > 0 &&
          loadingPlaceholders.map((index) => (
            <div
              key={`placeholder-${index}`}
              className="bg-[#D4C8B3] relative w-[120px] h-[120px] rounded-lg overflow-hidden flex items-center justify-center"
            >
              <div className="w-full h-full flex items-center justify-center">
                <AiImage image={null} isLoading={true} />
              </div>
            </div>
          ))}

        {localAdditionalImages.length > 0 &&
          localAdditionalImages.map((imageUrl, index) => (
            <div
              key={index}
              className="bg-[#D4C8B3] relative w-[120px] h-[120px] rounded-lg overflow-hidden flex items-center justify-center"
            >
              <AiImage
                image={imageUrl}
                isLoading={false}
                isSelectable={true}
                isSelected={imageUrl === selectedImageId}
                onClick={() => handleSelectImage(imageUrl)}
                isUpdating={isUpdating && updatingImage === imageUrl}
              />
            </div>
          ))}
      </div>

      <div className="flex gap-2 mt-4">
        <ImageUploader
          rfqId={rfqId}
          onImageUploaded={setImage}
          onUploadSuccess={(imageUrl) => {
            // Update the selected image ID for local UI
            setSelectedImageId(imageUrl);

            // Add to additional images if not already included
            if (!localAdditionalImages.includes(imageUrl)) {
              // Create a new array with the new image
              const newAdditionalImages = [...localAdditionalImages, imageUrl];
              setLocalAdditionalImages(newAdditionalImages);

              // Update the API with the new list of additional images
              try {
                fetchWithAuth(`${API_URL}/api/rfqs/${rfqId}`, {
                  method: 'PATCH',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    additional_images: newAdditionalImages
                  })
                });
              } catch (err) {
                console.error('Failed to update additional images', err);
              }
            }
          }}
          buttonText="Upload image"
          iconOnly={false}
        />
        {buttonsOnRight}
      </div>

      {error && <div className="text-xs text-red-500 mt-1">{error}</div>}
    </div>
  );
}
