import React from 'react';

const ErrorBox = ({ errorCode = 500 }: { errorCode?: number | string }) => {
  return (
    <div className="flex flex-col items-start justify-start mt-5 bg-red-100 p-4 rounded-lg animate-fadeIn">
      <div className="flex items-center justify-between mb-4">
        <p className="text-xl text-left text-red-500">
          <strong>Error</strong>
        </p>
        <p className="text-left text-sm ml-2 text-red-500 p-1 bg-red-200 rounded-lg">
          <strong>Code: {errorCode}</strong>
        </p>
      </div>
      <p className="text-left text-red-500">
        <strong>
          Oops! There is an error. Please try again. If the issue is not
          resolved, please contact help@cavela.com
        </strong>
      </p>
    </div>
  );
};

export default ErrorBox;
