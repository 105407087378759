import { extendVariants, Avatar as StyledAvatar } from '@heroui/react';
import React from 'react';

const Avatar = extendVariants(StyledAvatar, {
  variants: {
    color: {
      violet: {
        base: 'bg-[#7048E8] text-white'
      },
      transparent: {
        base: 'bg-transparent text-white'
      }
    },
    size: {
      lg: {
        base: 'size-10 text-[22px] font-semibold rounded-xl'
      },
      md: {
        base: 'size-7 text-[16px] font-semibold'
      },
      sm: {
        base: 'w-[22px] h-[22px] text-[15px] font-semibold'
      },
      xl: {
        base: 'size-24 text-[60px] font-semibold rounded-3xl'
      }
    }
  },
  defaultVariants: {
    color: 'violet',
    size: 'lg'
  }
});

interface OrganizationAvatarProps {
  size?: 'lg' | 'md' | 'sm' | undefined | 'xl';
  name?: string;
  image?: string;
}

const OrganizationAvatar: React.FC<OrganizationAvatarProps> = ({
  size,
  name,
  image
}) => {
  return (
    <Avatar
      classNames={{ base: 'uppercase' }}
      size={size}
      name={name?.charAt(0) ?? ''}
      src={image}
      color={image ? 'transparent' : 'violet'}
    />
  );
};

export default OrganizationAvatar;
