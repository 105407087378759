import {
  ArchiveBoxIcon,
  GlobeAmericasIcon,
  MapIcon,
  ShoppingCartIcon
} from '@heroicons/react/20/solid';
import { PencilIcon } from '@heroicons/react/24/solid';
import { useDisclosure } from '@heroui/react';
import { useState } from 'react';

import { format } from 'date-fns';
import { useAuthStore } from 'src/entities/user/store/useAuthStore';
import { SourcingStats } from 'src/shared/components';
import { Address, Organization } from 'src/shared/types/user';

import { ButtonV2, Tag, Typography } from 'src/shared/components/ui';

import OrganizationAdddressFormModal from '../../organization-address/ui/OrganizationAddressFormModal';
import OrganizationFormModal from '../../organization-edit/ui/OrganizationFormModal';
import UpdateOrganizationAvatar from '../../organization-edit/ui/UpdateOrganizationAvatar';

// needs to come from backend
const sourcingItems = [
  {
    title: 'Products created',
    value: 5,
    icon: <ArchiveBoxIcon className="size-5" />
  },
  {
    title: 'Orders created',
    value: 0,
    icon: <ShoppingCartIcon className="size-5" />
  },
  {
    title: 'Sourcing from',
    value: 2,
    icon: <GlobeAmericasIcon className="size-5" />
  }
];

const OrganizationProfile = () => {
  const selectedOrganization = useAuthStore(
    (state) => state.selectedOrganization
  );
  const user = useAuthStore((state) => state.user);
  const {
    isOpen: isOpenFormModal,
    onOpen: onOpenFormModal,
    onOpenChange: onOpenChangeFormModal
  } = useDisclosure();

  const {
    isOpen: isOpenAddress,
    onOpen: onOpenAddress,
    onOpenChange: onOpenChangeAddress
  } = useDisclosure();
  const [selectedAddress, setSelectedAddress] = useState<
    (Address & { index: number }) | null
  >(null);

  const renderAddresses = () => {
    if (selectedOrganization?.addresses?.length === 0) {
      return (
        <div className="border border-gray-4 rounded-xl p-4 flex flex-col gap-1 justify-center items-center relative">
          <MapIcon className="size-10" />
          <Typography
            variant="body-2"
            as="p"
            className="text-secondary-text"
            weight="medium"
          >
            No address available
          </Typography>
        </div>
      );
    }

    return selectedOrganization?.addresses?.map((address, index) => (
      <div className="border border-gray-4 rounded-xl p-4 flex flex-col gap-1 relative">
        <ButtonV2
          variant="flat"
          color="default"
          size="sm"
          className="absolute mobile:bottom-4 mobile:top-auto top-4 right-4"
          onPress={() => {
            setSelectedAddress({ ...address, index });
            onOpenChangeAddress();
          }}
        >
          Edit
        </ButtonV2>
        <div className="flex items-center">
          <Typography variant="body-1" weight="bold" as="span">
            {address.name}
          </Typography>
          {index === 0 && (
            <Tag color="default" className="ml-2 px-0 text-primary-text">
              Default
            </Tag>
          )}
        </div>
        <Typography variant="body-1" weight="medium" as="p">
          {address.line_1}, {address.line_2}, {address.city}, {address.state},{' '}
          {address.country}, {address.zip_code}
        </Typography>
        <Typography variant="body-1" weight="medium" as="p">
          {address.contact_name}
        </Typography>
        <Typography variant="body-1" weight="medium" as="p">
          {address.contact_phone}
        </Typography>
      </div>
    ));
  };

  return (
    <div className="flex flex-col gap-9">
      <div className="flex flex-col gap-5">
        <UpdateOrganizationAvatar selectedOrganization={selectedOrganization} />

        <div className="group flex w-fit items-center gap-2">
          <Typography variant="h2" as="h2" weight="bold">
            {selectedOrganization?.name}
          </Typography>
          <ButtonV2
            variant="flat"
            color="default"
            size="sm"
            className="group-hover:opacity-100 opacity-0"
            onPress={onOpenFormModal}
            startContent={<PencilIcon className="size-4" />}
          >
            Edit
          </ButtonV2>
        </div>

        <div className="mobile:grid grid-cols-2 mobile:items-start mobile:gap-4 flex items-center gap-12">
          <div>
            {/* needs backend help to know who created this organization */}
            <Typography variant="body-2" as="p" className="text-secondary-text">
              Created by
            </Typography>
            <Typography
              className="mt-[2px]"
              variant="body-2"
              as="p"
              weight="medium"
            >
              {(user?.firstName &&
                user?.lastName &&
                `${user?.firstName} ${user?.lastName}`) ||
                user?.username}
            </Typography>
          </div>

          <div>
            <Typography variant="body-2" as="p" className="text-secondary-text">
              Created on
            </Typography>
            <Typography
              className="mt-[2px]"
              variant="body-2"
              as="p"
              weight="medium"
            >
              {format(new Date(), 'MMM dd, yyyy')}
            </Typography>
          </div>

          <div>
            <Typography variant="body-2" as="p" className="text-secondary-text">
              Company ID
            </Typography>
            <Typography
              className="mt-[2px]"
              variant="body-2"
              as="p"
              weight="medium"
            >
              {selectedOrganization?.id}
            </Typography>
          </div>

          <div>
            <Typography variant="body-2" as="p" className="text-secondary-text">
              Members
            </Typography>
            <Typography
              className="mt-[2px]"
              variant="body-2"
              as="p"
              weight="medium"
            >
              {selectedOrganization?.members?.length}
            </Typography>
          </div>
        </div>
      </div>

      <SourcingStats items={sourcingItems} />

      <div>
        <Typography variant="h4" weight="bold">
          Profile
        </Typography>
        <div className="relative flex flex-col gap-5 mt-3 border border-gray-4 rounded-xl p-4">
          <div>
            <Typography
              variant="body-1"
              weight="medium"
              as="p"
              className="text-secondary-text"
            >
              Organization name
            </Typography>
            <Typography
              variant="body-1"
              weight="medium"
              className="mt-[2px]"
              as="p"
            >
              {selectedOrganization?.name}
            </Typography>
          </div>
        </div>
      </div>

      <div className="mb-10">
        <div className="flex items-center justify-between">
          <Typography variant="h4" weight="bold">
            Address
          </Typography>
          <ButtonV2
            onPress={() => {
              setSelectedAddress(null);
              onOpenAddress();
            }}
            color="dark"
          >
            Add new address
          </ButtonV2>
        </div>
        <div className="flex flex-col gap-3 mt-3">{renderAddresses()}</div>
      </div>

      <OrganizationFormModal
        isOpen={isOpenFormModal}
        onOpenChange={onOpenChangeFormModal}
        selectedOrganization={selectedOrganization}
      />

      <OrganizationAdddressFormModal
        isOpen={isOpenAddress}
        onOpenChange={onOpenChangeAddress}
        selectedAddress={selectedAddress}
        selectedOrganization={selectedOrganization as Organization}
      />
    </div>
  );
};

export default OrganizationProfile;
