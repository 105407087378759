import { MAX_FILE_UPLOAD_SIZE_MB } from '../settings';

export function bytesToMbs({ bytes }: { bytes: number }) {
  if (bytes < 0 || isNaN(bytes) || !isFinite(bytes)) {
    return 0;
  }
  const megabytes = bytes / (1024 * 1024);
  const roundedMegabytes = megabytes.toFixed(2);
  return Number(roundedMegabytes);
}

export function isOverLimitedMb({ bytes }: { bytes: number }) {
  if (bytes < 0 || isNaN(bytes) || !isFinite(bytes)) {
    return false;
  }

  return bytes > 1024 * 1024 * MAX_FILE_UPLOAD_SIZE_MB;
}

export function checkIsAnyFileOver({
  files,
  setError
}: {
  files: File[];
  setError: (error: string) => void;
}): boolean {
  const largeFilesNames = files
    .filter((file) => isOverLimitedMb({ bytes: file.size }))
    .map((file) => file.name);

  if (largeFilesNames.length > 0) {
    setError(
      `The following files are too large: ${largeFilesNames.join(', ')}. Please upload files smaller than 250MB.`
    );
    return true;
  }
  return false;
}
