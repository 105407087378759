import { Drawer, DrawerContent } from '@heroui/react';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router';

import { useMediaQuery } from 'src/shared/hooks';
import useMobileMenu from 'src/shared/store/useMobileMenu';
import useSidebar from 'src/shared/store/useSidebar';

import {
  PrivateContent,
  PrivateContentMobile,
  PrivateMobileHeader,
  PrivateSidebar
} from 'src/shared/components/PrivateLayout';

const PrivateLayout = () => {
  const [isHydrated, setIsHydrated] = useState(false);
  const isOpen = useSidebar((state) => state.isOpen);
  const isMobileMenuOpen = useMobileMenu((state) => state.isOpen);
  const isMobile = useMediaQuery();

  useEffect(() => {
    setIsHydrated(true);
  }, []);

  if (!isHydrated) {
    // Prevent flicker
    return null;
  }

  if (isMobile) {
    return (
      <div className="min-h-screen flex flex-col">
        <div className="flex flex-grow">
          <PrivateMobileHeader />
          <PrivateContentMobile>
            <Outlet />
          </PrivateContentMobile>
        </div>

        <Drawer hideCloseButton isOpen={isMobileMenuOpen} size="full">
          <DrawerContent>
            <PrivateSidebar isOpen isMobile={isMobile} />
          </DrawerContent>
        </Drawer>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex flex-grow">
        {!isMobile && <PrivateSidebar isOpen={isOpen} />}
        <PrivateContent isOpen={isOpen}>
          <Outlet />
        </PrivateContent>
      </div>
    </div>
  );
};

export default PrivateLayout;
