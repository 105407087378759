import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

const ErrorFallback = () => (
  <div>
    <h2>Oops! Something went wrong.</h2>
    <button onClick={() => window.location.reload()}>Refresh Page</button>
  </div>
);

const ErrorBoundaryLayout = () => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Outlet />
  </ErrorBoundary>
);

export default ErrorBoundaryLayout;
