import { InformationCircleIcon } from '@heroicons/react/20/solid';

import {
  AiAvatar,
  FabButton,
  FileAttachment,
  ImageAttachment,
  InfoTooltip,
  MatchTag,
  OrganizationAvatar,
  QuoteStatusTag,
  SampleStatusTag,
  SampleTypeTag,
  StageTag,
  TaskStatusTag,
  UserAvatar
} from 'src/shared/components';
import { ROUTE_PATHS } from 'src/shared/config/routes';

import {
  BreadcrumbV2,
  ButtonV2,
  Input,
  Link,
  Nav,
  Select,
  Tab,
  TabsV2,
  Typography
} from 'src/shared/components/ui';

const animals = [
  { key: 'cat', label: 'Cat' },
  { key: 'dog', label: 'Dog' },
  { key: 'elephant', label: 'Elephant' },
  { key: 'lion', label: 'Lion' },
  { key: 'tiger', label: 'Tiger' },
  { key: 'giraffe', label: 'Giraffe' },
  { key: 'dolphin', label: 'Dolphin' },
  { key: 'penguin', label: 'Penguin' },
  { key: 'zebra', label: 'Zebra' },
  { key: 'shark', label: 'Shark' },
  { key: 'whale', label: 'Whale' },
  { key: 'otter', label: 'Otter' },
  { key: 'crocodile', label: 'Crocodile' }
];

const ComponentsPage = () => {
  return (
    <div className="h-full p-6">
      <div className="flex flex-col gap-4">
        <Typography variant="h1" weight="bold">
          Components
        </Typography>

        <div>
          <Typography variant="h3" weight="medium">
            Button
          </Typography>

          <div className="mt-4 flex items-center gap-4">
            <ButtonV2
              startContent={<InformationCircleIcon className="size-4" />}
            >
              Button text
            </ButtonV2>

            <ButtonV2
              isDisabled
              startContent={<InformationCircleIcon className="size-4" />}
            >
              Button text
            </ButtonV2>

            <ButtonV2
              size="md"
              startContent={<InformationCircleIcon className="size-4" />}
            >
              Button text
            </ButtonV2>

            <ButtonV2 size="lg" isIconOnly>
              <InformationCircleIcon className="size-4" />
            </ButtonV2>

            <ButtonV2 size="md" isIconOnly>
              <InformationCircleIcon className="size-4" />
            </ButtonV2>
          </div>

          <div className="mt-4 flex items-center gap-4">
            <ButtonV2
              color="secondary"
              startContent={<InformationCircleIcon className="size-4" />}
            >
              Button text
            </ButtonV2>

            <ButtonV2
              color="secondary"
              isDisabled
              startContent={<InformationCircleIcon className="size-4" />}
            >
              Button text
            </ButtonV2>

            <ButtonV2
              color="secondary"
              size="md"
              startContent={<InformationCircleIcon className="size-4" />}
            >
              Button text
            </ButtonV2>

            <ButtonV2 color="secondary" size="lg" isIconOnly>
              <InformationCircleIcon className="size-4" />
            </ButtonV2>

            <ButtonV2 color="secondary" size="md" isIconOnly>
              <InformationCircleIcon className="size-4" />
            </ButtonV2>
          </div>

          <div className="mt-4 flex items-center gap-4">
            <ButtonV2
              color="tertiary"
              startContent={<InformationCircleIcon className="size-4" />}
            >
              Button text
            </ButtonV2>

            <ButtonV2
              color="tertiary"
              isDisabled
              startContent={<InformationCircleIcon className="size-4" />}
            >
              Button text
            </ButtonV2>

            <ButtonV2
              color="tertiary"
              size="md"
              startContent={<InformationCircleIcon className="size-4" />}
            >
              Button text
            </ButtonV2>

            <ButtonV2 color="tertiary" size="lg" isIconOnly>
              <InformationCircleIcon className="size-4" />
            </ButtonV2>

            <ButtonV2 color="tertiary" size="md" isIconOnly>
              <InformationCircleIcon className="size-4" />
            </ButtonV2>
          </div>

          <div className="mt-4 flex items-center gap-4">
            <ButtonV2
              color="fourth"
              startContent={<InformationCircleIcon className="size-4" />}
            >
              Button text
            </ButtonV2>

            <ButtonV2
              color="fourth"
              isDisabled
              startContent={<InformationCircleIcon className="size-4" />}
            >
              Button text
            </ButtonV2>

            <ButtonV2
              color="fourth"
              size="md"
              startContent={<InformationCircleIcon className="size-4" />}
            >
              Button text
            </ButtonV2>

            <ButtonV2 color="fourth" size="lg" isIconOnly>
              <InformationCircleIcon className="size-4" />
            </ButtonV2>

            <ButtonV2 color="fourth" size="md" isIconOnly>
              <InformationCircleIcon className="size-4" />
            </ButtonV2>
          </div>
        </div>

        <div>
          <Typography variant="h3" weight="medium">
            Link
          </Typography>

          <Link to={ROUTE_PATHS.LOGIN}>Link</Link>
          <Link isDisabled to={ROUTE_PATHS.LOGIN}>
            Link
          </Link>
        </div>

        <div>
          <Typography variant="h3" weight="medium">
            Nav
          </Typography>

          <Nav startContent={<InformationCircleIcon className="size-4" />}>
            Button Text
          </Nav>

          <Nav isIconOnly>
            <InformationCircleIcon className="size-4" />
          </Nav>
        </div>

        <div>
          <Typography variant="h3" weight="medium">
            Fab
          </Typography>
          <FabButton />
        </div>

        <div>
          <Typography variant="h3" weight="medium">
            Tabs
          </Typography>

          <TabsV2>
            <Tab key="sourcing" title="Sourcing" />
            <Tab key="drafts" title="Drafts" />
            <Tab key="archive" title="Archive" />
          </TabsV2>

          <TabsV2 color="dark" variant="light">
            <Tab key="suppliers" title="Suppliers" />
            <Tab key="quotes" title="Quotes" />
            <Tab key="archive" title="Archive" />
          </TabsV2>
        </div>

        <div>
          <Typography variant="h3" weight="medium">
            Breadcrumbs
          </Typography>

          <BreadcrumbV2
            paths={[
              { path: '/products', title: 'All products' },
              { path: '/products-sample', title: 'Product details' },
              { path: '/products-details', title: 'Sample details' }
            ]}
          />
        </div>

        <div>
          <Typography variant="h3" weight="medium">
            Info Tooltip
          </Typography>

          <InfoTooltip
            content={
              <Typography variant="body-1" weight="medium">
                Tooltip
              </Typography>
            }
          />
        </div>

        <div>
          <Typography variant="h3" weight="medium">
            Avatar
          </Typography>

          <div className="flex gap-4">
            <UserAvatar name="George" />
            <AiAvatar />
            <OrganizationAvatar />
          </div>

          <div className="flex gap-4 mt-2">
            <UserAvatar name="George" size="md" />
            <AiAvatar size="md" />
          </div>

          <div className="flex gap-4 mt-2">
            <UserAvatar name="George" size="sm" />
            <AiAvatar size="sm" />
          </div>
        </div>

        <div>
          <Typography variant="h3" weight="medium">
            Attachments
          </Typography>

          <div className="flex gap-4 mt-2 items-center">
            <ImageAttachment
              onRemove={() => console.log('REMOVE FILE')}
              image="https://images.unsplash.com/photo-1739582814657-10931286d7a5?q=80&w=1528&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
            <FileAttachment
              onRemove={() => console.log('REMOVE FILE')}
              file={new File(['Test file'], 'File.pdf', { type: 'text/plain' })}
            />
          </div>
        </div>

        <div>
          <Typography variant="h3" weight="medium">
            Stage
          </Typography>

          <div className="flex gap-4 mt-2 items-center">
            <StageTag type="quoting" />
            <StageTag type="sampling" />
            <StageTag type="quoting" />
            <StageTag type="ordering" />
            <StageTag type="archived" />
            <StageTag type="draft" />
          </div>
        </div>

        <div>
          <Typography variant="h3" weight="medium">
            Task Status
          </Typography>

          <div className="flex gap-4 mt-2 items-center">
            <TaskStatusTag type="update" />
            <TaskStatusTag type="task" />
            <TaskStatusTag type="none" />
          </div>
        </div>

        <div>
          <Typography variant="h3" weight="medium">
            Quote Status Tag
          </Typography>

          <div className="flex gap-4 mt-2 items-center">
            <QuoteStatusTag type="no_action" />
            <QuoteStatusTag type="sampled" />
            <QuoteStatusTag type="ordered" />
          </div>
        </div>

        <div>
          <Typography variant="h3" weight="medium">
            Match Tag
          </Typography>

          <div className="flex gap-4 mt-2 items-center">
            <MatchTag type="15" />
            <MatchTag type="50" />
            <MatchTag type="90" />
            <MatchTag type="100" />
            <MatchTag type={undefined} />
          </div>
        </div>

        <div>
          <Typography variant="h3" weight="medium">
            Sample Status Tag
          </Typography>

          <div className="flex gap-4 mt-2 items-center">
            <SampleStatusTag type="uploaded" />
            <SampleStatusTag type="updated" />
            <SampleStatusTag type="rejected" />
            <SampleStatusTag type="draft" />
          </div>
        </div>

        <div>
          <Typography variant="h3" weight="medium">
            Sample Type Tag
          </Typography>

          <div className="flex gap-4 mt-2 items-center">
            <SampleTypeTag type="Product sample" />
            <SampleTypeTag type="Reference sample" />
            <SampleTypeTag type="Pre-production sample" />
          </div>
        </div>

        <div>
          <Typography variant="h3" weight="medium">
            Inputs
          </Typography>

          <div className="flex gap-4 mt-2 items-center">
            <Input placeholder="Placeholder" label="Title" />
            <Input placeholder="Placeholder" label="Title" isDisabled />
          </div>
        </div>

        <div>
          <Typography variant="h3" weight="medium">
            Select
          </Typography>

          <div className="flex gap-4 mt-2 items-center">
            <Select label="Select" placeholder="Placeholder" items={animals} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComponentsPage;
