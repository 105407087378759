import { addToast, Checkbox } from '@heroui/react';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';

import { useAuthStore } from 'src/entities/user/store/useAuthStore';
import { Address, Organization } from 'src/shared/types/user';

import {
  ButtonV2,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Typography
} from 'src/shared/components/ui';

import {
  organizationAddressFormDefaultValues,
  useOrganizationAddressForm
} from '../hooks/useOrganizationAddressForm';
import { useUpdateOrganizationAddress } from '../hooks/useUpdateOrganizationAddress';

interface OrganizationAdddressFormModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  selectedAddress: (Address & { index: number }) | null;
  selectedOrganization: Organization;
}

const OrganizationAdddressFormModal: React.FC<
  OrganizationAdddressFormModalProps
> = ({ isOpen, onOpenChange, selectedAddress, selectedOrganization }) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useOrganizationAddressForm(
    selectedAddress
      ? {
          ...selectedAddress,
          is_default: selectedAddress.index === 0 ? true : false
        }
      : undefined
  );

  const setSelectedOrganization = useAuthStore(
    (state) => state.setSelectedOrganization
  );
  const organizationAddressMutation = useUpdateOrganizationAddress();

  useEffect(() => {
    if (isOpen) {
      if (selectedAddress) {
        reset({
          ...selectedAddress,
          is_default: selectedAddress.index === 0 ? true : false
        });
      } else {
        reset(organizationAddressFormDefaultValues);
      }
    }
  }, [reset, selectedAddress, isOpen]);

  const onSubmit = (data: Address) => {
    let addresses: Address[] = [];

    // if new address
    if (!selectedAddress) {
      addresses = [
        ...(selectedOrganization.addresses as Address[]),
        { ...data }
      ];

      if (data.is_default) {
        addresses = [
          { ...data },
          ...(selectedOrganization.addresses as Address[])
        ];
      }
    }

    // if update address
    if (selectedAddress) {
      addresses = selectedOrganization.addresses?.map((address, i) => {
        if (i === selectedAddress.index) {
          return { ...data };
        }
        return address;
      }) as Address[];

      if (data.is_default) {
        const updatedAddress = addresses[selectedAddress.index];

        addresses.splice(selectedAddress.index, 1);

        addresses.unshift(updatedAddress);
      }
    }

    organizationAddressMutation.mutate(
      { addresses, id: selectedOrganization.id },
      {
        onError: () => {
          addToast({
            color: 'danger',
            title: `Address could not be ${selectedAddress ? 'updated' : 'added'}.`
          });
        },
        onSuccess: (data: Organization) => {
          onOpenChange(false);
          setSelectedOrganization({
            ...selectedOrganization,
            addresses: data.addresses
          });
          addToast({
            color: 'success',
            title: `Address has been successfully ${
              selectedAddress ? 'updated' : 'added'
            }.`
          });
        }
      }
    );
  };

  const onRemoveAddress = () => {
    if (selectedOrganization.addresses) {
      let addresses: Address[] = [];
      addresses = selectedOrganization.addresses.filter(
        (_, i) => i !== selectedAddress?.index
      );

      organizationAddressMutation.mutate(
        { addresses, id: selectedOrganization.id },
        {
          onError: () => {
            addToast({
              color: 'danger',
              title: 'Address could not be removed.'
            });
          },
          onSuccess: (data: Organization) => {
            onOpenChange(false);
            setSelectedOrganization({
              ...selectedOrganization,
              addresses: data.addresses
            });
            addToast({
              color: 'success',
              title: 'Address has been successfully removed.'
            });
          }
        }
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      size="lg"
      scrollBehavior="inside"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1 p-5 border-b border-gray-4">
              {selectedAddress ? 'Edit address' : 'Add address'}
            </ModalHeader>
            <ModalBody className="p-5">
              <form
                className="flex flex-col gap-9"
                id="organization-address-form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div>
                  <Input
                    label="Name"
                    errorMessage={errors.name?.message}
                    isInvalid={!!errors.name}
                    {...register('name')}
                  />
                  <Controller
                    name="is_default"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Checkbox
                        isSelected={value}
                        onValueChange={onChange}
                        className="mt-2"
                      >
                        <Typography variant="body-1" as="span" weight="medium">
                          Set as default
                        </Typography>
                      </Checkbox>
                    )}
                  />
                </div>

                <div>
                  <Typography variant="h6" as="span" weight="bold">
                    Address
                  </Typography>

                  <div className="grid grid-cols-2 mt-3 gap-5">
                    <Input
                      className="col-span-2"
                      label="Address line 1"
                      errorMessage={errors.line_1?.message}
                      isInvalid={!!errors.line_1}
                      {...register('line_1')}
                    />
                    <Input
                      className="col-span-2"
                      label="Address line 2"
                      errorMessage={errors.line_2?.message}
                      isInvalid={!!errors.line_2}
                      {...register('line_2')}
                    />
                    <Input
                      label="City"
                      errorMessage={errors.city?.message}
                      isInvalid={!!errors.city}
                      {...register('city')}
                    />
                    <Input
                      label="State"
                      errorMessage={errors.state?.message}
                      isInvalid={!!errors.state}
                      {...register('state')}
                    />
                    <Input
                      label="Zipcode"
                      errorMessage={errors.zip_code?.message}
                      isInvalid={!!errors.zip_code}
                      {...register('zip_code')}
                    />
                    <Input
                      label="Country"
                      errorMessage={errors.country?.message}
                      isInvalid={!!errors.country}
                      {...register('country')}
                    />
                  </div>
                </div>

                <div>
                  <Typography variant="h6" as="span" weight="bold">
                    Contact
                  </Typography>

                  <div className="grid grid-cols-2 mt-3 gap-5">
                    <Input
                      className="col-span-2"
                      label="Contact name"
                      errorMessage={errors.contact_name?.message}
                      isInvalid={!!errors.contact_name}
                      {...register('contact_name')}
                    />
                    <Input
                      className="col-span-2"
                      label="Contact number"
                      errorMessage={errors.contact_phone?.message}
                      isInvalid={!!errors.contact_phone}
                      {...register('contact_phone')}
                    />
                  </div>
                </div>
              </form>
            </ModalBody>
            <ModalFooter className="justify-start border-t border-gray-4 p-5">
              <ButtonV2
                form="organization-address-form"
                type="submit"
                size="sm"
                color="dark"
                isDisabled={organizationAddressMutation.isPending}
                isLoading={organizationAddressMutation.isPending}
              >
                Save
              </ButtonV2>
              {selectedAddress && (
                <ButtonV2
                  size="sm"
                  color="default"
                  variant="flat"
                  onPress={onRemoveAddress}
                  isDisabled={organizationAddressMutation.isPending}
                >
                  Delete address
                </ButtonV2>
              )}
              <ButtonV2
                size="sm"
                color="default"
                variant="flat"
                onPress={onClose}
                isDisabled={organizationAddressMutation.isPending}
              >
                Close
              </ButtonV2>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default OrganizationAdddressFormModal;
