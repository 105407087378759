import React from 'react';

const variants = {
  default: {
    backgroundColor: 'white',
    color: 'black'
  },
  caution: {
    backgroundColor: '#FEF3C7',
    color: '#D97706'
  },
  success: {
    backgroundColor: '#DAF8E6',
    color: '#1A8245'
  },
  alert: {
    backgroundColor: '#D0F0FD',
    color: '#0B76B7'
  },
  context: {
    backgroundColor: 'transparent',
    color: '#6E747D'
  }
};

interface LabelProps {
  small?: boolean;
  variant?: keyof typeof variants;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const Label: React.FC<LabelProps> = ({
  small = false,
  variant = 'default',
  children,
  style = {}
}) => (
  <div
    className="rounded-lg overflow-hidden"
    style={{
      display: 'inline-block',
      fontWeight: 500,

      ...variants[variant],

      ...style
    }}
  >
    <div
      className="font-display text-sm flex items-center justify-center gap-2 mx-auto px-2 py-1"
      style={{
        ...(!small
          ? {}
          : {
              fontSize: '.69em',
              padding: '.1rem .4rem'
            })
      }}
    >
      {children}
    </div>
  </div>
);

export default Label;
