import { Spinner } from '@heroui/react';
import { Key, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { formatDate } from 'date-fns';
import { StageTag } from 'src/shared/components';
import { ROUTE_PATHS } from 'src/shared/config/routes';

import { RFQ } from 'src/components/RfqDisplay/props';
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Typography
} from 'src/shared/components/ui';

import productDraftColumns from '../constants/productDraftColumns';
import { useProductDrafts } from '../hooks/useProductDrafts';
import ProductNameCell from './ProductNameCell';

const ProductDraftsTable = () => {
  const { isLoading, data } = useProductDrafts();
  const navigate = useNavigate();

  const total = data?.length;

  const renderCell = useCallback((item: RFQ, columnKey: Key) => {
    switch (columnKey) {
      case 'name':
        return <ProductNameCell name={item.document.name} image={item.image} />;

      case 'stage':
        return <StageTag type="draft" />;

      case 'questions_answered':
        return (
          <Typography variant="body-1" weight="medium">
            {item.questions.length ?? '0'} answers
          </Typography>
        );

      case 'created':
        return (
          <Typography variant="body-1" weight="medium">
            {formatDate(item.created_at, 'MMM dd, yyyy')}
          </Typography>
        );

      default:
        return '';
    }
  }, []);

  const onClickRow = (id: number) => {
    navigate(`${ROUTE_PATHS.NEW_RFQ}?rfq_id=${id}`);
  };

  return (
    <div className="flex flex-col">
      <div>
        <Typography variant="h4" weight="bold">
          {total} Drafts
        </Typography>
      </div>

      <Table aria-label="Products table">
        <TableHeader columns={productDraftColumns}>
          {(column) => (
            <TableColumn key={column.key}>{column.label}</TableColumn>
          )}
        </TableHeader>
        <TableBody
          items={data ?? []}
          emptyContent={isLoading ? <Spinner /> : 'No drafts'}
        >
          {(item) => (
            <TableRow onClick={() => onClickRow(item.id)} key={item.id}>
              {(columnKey) => (
                <TableCell>{renderCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default ProductDraftsTable;
