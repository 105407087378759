const quotesColumns = [
  {
    key: 'sampleImages',
    label: 'Sample images'
  },
  {
    key: 'type',
    label: 'Type'
  },
  {
    key: 'unitCost',
    label: 'Unit Cost'
  },
  {
    key: 'quantity',
    label: 'Quantity'
  },
  {
    key: 'supplier',
    label: 'Supplier'
  },
  {
    key: 'match',
    label: 'Match'
  },
  {
    key: 'status',
    label: 'Status'
  },
  {
    key: 'uploaded',
    label: 'Uploaded'
  }
];

export default quotesColumns;
