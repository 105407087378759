import axios from 'axios';
import { captureException } from 'src/services/reporting';

const handleAxiosError = (error: unknown): Error => {
  if (axios.isAxiosError(error)) {
    const extra = {
      statusCode: error.response?.status,
      message: error.response?.data?.detail
    };

    captureException({ error, extra });

    if (error?.response?.data?.detail) {
      return new Error(error.response?.data?.detail);
    }

    if (error?.response?.data) {
      return new Error(JSON.stringify(error.response?.data));
    }

    return new Error('Failed to fetch data');
  }
  return new Error('An unexpected error occurred');
};

export default handleAxiosError;
