import { Image } from '@heroui/react';
import React from 'react';

import { Typography } from 'src/shared/components/ui';

interface ProductNameCellProps {
  name: string;
  image: string | null;
}

const ProductNameCell: React.FC<ProductNameCellProps> = ({ name, image }) => {
  return (
    <div className="flex gap-2 items-center">
      <Image
        className="size-12 min-w-12 rounded-xl bg-gray-2 border border-gray-4"
        src={image ? image : '/images/no-image.svg'}
        alt={name}
      />
      <Typography variant="body-1" weight="bold">
        {name}
      </Typography>
    </div>
  );
};

export default ProductNameCell;
