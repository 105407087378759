import RFQImagesCarousel from '../../RFQImagesCarousel/RFQImagesCarousel';
import LMLoading from '../LMLoading/LMLoading';

export default function RFQFinalTransition() {
  return (
    <>
      <div
        className="absolute w-screen h-screen bg-black z-1 opacity-40"
        style={{ zIndex: 1 }}
      ></div>
      <div className="w-screen h-screen flex items-center justify-center">
        <div
          className="absolute w-screen h-screen text-white text-l font-body text-center flex flex-col items-center justify-center"
          style={{ zIndex: 2 }}
        >
          <div>Rendering</div>
          <div className="mt-4">
            <LMLoading small={false} messageOnLongWait={true} />
          </div>
        </div>
        <RFQImagesCarousel />
      </div>
    </>
  );
}
