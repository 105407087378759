import { Button, extendVariants, Spinner } from '@heroui/react';

const ButtonV2 = extendVariants(Button, {
  variants: {
    color: {
      primary: 'bg-primary text-white hover:opacity-70',
      secondary: 'bg-gray-2 text-primary-text hover:opacity-70',
      tertiary:
        'bg-white text-primary-text hover:bg-gray-2 border border-gray-4',
      fourth: 'bg-white text-primary-text hover:opacity-70',
      dark: 'text-white bg-primary-text',
      default: 'text-primary-text bg-gray-2 hover:opacity-70'
    },
    isDisabled: {
      true: '!bg-gray-4 !text-secondary-text cursor-not-allowed opacity-100'
    },
    size: {
      xl: 'px-4 h-12 font-medium tracking-[-0.16px] gap-[6px]',
      lg: 'px-4 h-10 font-medium tracking-[-0.16px] gap-[6px]',
      md: 'px-4 h-9 font-medium tracking-[-0.16px] gap-[6px]',
      sm: 'px-2 h-9 font-medium tracking-[-0.16px] gap-[6px]',
      xs: 'h-5 font-medium tracking-[-0.16px] gap-[6px]'
    },
    isIconOnly: {
      true: '' // Placeholder to be used in compoundVariants
    },
    radius: {
      lg: 'rounded-xl',
      md: 'rounded-xl'
    }
  },
  compoundVariants: [
    {
      isIconOnly: true,
      size: 'lg',
      class:
        '!w-[40px] !h-[40px] !min-h-[40px] !min-w-[40px] !p-0 flex items-center justify-center'
    },
    {
      isIconOnly: true,
      size: 'md',
      class:
        '!w-[36px] !h-[36px] !min-h-[36px] !min-w-[36px] !p-0 flex items-center justify-center'
    },
    {
      isIconOnly: true,
      size: 'sm',
      class:
        '!w-[24px] !h-[24px] !min-h-[24px] !min-w-[24px] !p-0 flex items-center justify-center'
    },
    {
      isDisabled: true,
      color: 'tertiary',
      class: '!text-secondary-text !bg-white'
    },
    {
      isDisabled: true,
      color: 'fourth',
      class: '!text-secondary-text !bg-white'
    }
  ],
  defaultVariants: {
    color: 'primary',
    size: 'lg',
    radius: 'lg',
    // used any bug in typing of spinner from HeroUI
    spinner: (<Spinner variant="gradient" color="current" size="sm" />) as any
  }
});

export default ButtonV2;
