import Tag from '../ui/Tag';

const SAMPLE_STATUS_TAG_COLORS = {
  uploaded: 'green',
  updated: 'green',
  rejected: 'red',
  draft: 'no_action'
} as const;

interface SampleStatusTagProps {
  type: keyof typeof SAMPLE_STATUS_TAG_COLORS;
}

const SampleStatusTag: React.FC<SampleStatusTagProps> = ({
  type = 'draft'
}) => {
  return (
    <Tag className="capitalize" color={SAMPLE_STATUS_TAG_COLORS[type]}>
      {type}
    </Tag>
  );
};

export default SampleStatusTag;
