import { LinkIcon } from '@heroicons/react/20/solid';
import { Button, extendVariants } from '@heroui/react';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTE_PATHS } from 'src/shared/config/routes';

const StyledLink = extendVariants(Button, {
  variants: {
    color: {
      link: 'bg-white text-primary tracking-[-0.14px] hover:!opacity-50'
    },
    isDisabled: {
      true: '!text-secondary-text cursor-not-allowed opacity-100'
    },
    size: {
      lg: '!p-[4px] max-h-[30px] font-medium gap-[4px] !min-w-max'
    }
  },

  defaultVariants: {
    color: 'link',
    size: 'lg',
    radius: 'lg',
    as: 'a'
  }
});

interface LinkProps {
  to: (typeof ROUTE_PATHS)[keyof typeof ROUTE_PATHS];
  children: React.ReactNode;
  isDisabled?: boolean;
}

const Link: React.FC<LinkProps> = ({ to, children, isDisabled, ...props }) => {
  const navigate = useNavigate();

  const onNavigate = useCallback(() => {
    navigate(to);
  }, [navigate, to]);

  return (
    <StyledLink
      isDisabled={isDisabled}
      onPress={!isDisabled ? onNavigate : undefined}
      startContent={<LinkIcon className="size-4 pointer-events-none" />}
      {...props}
    >
      {children}
    </StyledLink>
  );
};

export default Link;
