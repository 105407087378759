import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { Tooltip } from '@heroui/react';

interface InfoTooltipProps {
  content: React.ReactNode;
}

const InfoTooltip: React.FC<InfoTooltipProps> = ({ content }) => {
  return (
    <Tooltip classNames={{ content: 'bg-white' }} content={content}>
      <InformationCircleIcon className="size-4 text-secondary-text" />
    </Tooltip>
  );
};

export default InfoTooltip;
