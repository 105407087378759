import React from 'react';

import { FileItem } from 'src/pages/NewRfqFlow/props';

import AddFile from 'src/components/AddFile/AddFile';
import PreviewFile from 'src/components/PreviewFile/PreviewFile';

interface FilesListProps {
  files: FileItem[];
  removeFile: (index: number) => void;
  updateFile: (index: number, file: FileItem) => void;
  aiThinking: boolean;
  withCaption: boolean;
}

const FilesList: React.FC<FilesListProps> = ({
  files,
  removeFile,
  updateFile,
  aiThinking,
  withCaption
}) => {
  return (
    <>
      {files.map((file: FileItem, index: number) => {
        if (file.file && (file.caption || !withCaption)) {
          return (
            <PreviewFile
              key={index}
              file={file}
              removeFile={removeFile}
              index={index}
              disabled={aiThinking}
              updateFile={updateFile}
              fromProductSide={false}
              withCaption={withCaption}
            />
          );
        }
        return (
          <AddFile
            key={index}
            file={file}
            handleAddFile={updateFile}
            removeFile={removeFile}
            index={index}
            fromProductSide={false}
            setIsEditing={() => {}}
          />
        );
      })}
    </>
  );
};

export default FilesList;
