// TO DELETE - /v2/colors

export const ROUTE_PATHS = {
  HOME: '/v2/products',
  LOGIN: '/v2/login',
  PRODUCTS: '/v2/products',
  PRODUCT_DETAIL: '/v2/products/:id',
  ORGANIZATION: '/v2/organization',
  ACCOUNT: '/v2/account',
  EXPIRED: '/v2/expired',
  REGISTER: '/v2/register',
  // CLARIFICATIONS: '/products'
  // TESTING PURPOSES BELOW:
  COLORS: '/v2/colors',
  COMPONENTS: '/v2/components',

  // FROM V1
  NEW_RFQ: '/v2/new-rfq'
} as const;
