import React from 'react';

import { FileItem, Link } from 'src/pages/NewRfqFlow/props';

import RFQBuilderFilesList from './RFQBuilderFilesList';
import RFQBuilderLinksList from './RFQBuilderLinksList';

interface FilesAndLinksListProps {
  files: FileItem[];
  links: Link[];
  removeFile: (index: number) => void;
  updateFile: (index: number, file: FileItem) => void;
  updateLink: (index: number, link: Link) => void;
  removeLink: (index: number) => void;
  aiThinking: boolean;
  withCaption?: boolean;
}

const FilesAndLinksList: React.FC<FilesAndLinksListProps> = ({
  files,
  links,
  removeFile,
  updateFile,
  updateLink,
  removeLink,
  aiThinking,
  withCaption = true
}) => {
  if (files.length === 0 && links.length === 0) {
    return null;
  }
  return (
    <div className="mb-4 w-full space-y-3">
      <RFQBuilderFilesList
        files={files}
        removeFile={removeFile}
        updateFile={updateFile}
        aiThinking={aiThinking}
        withCaption={withCaption}
      />
      <RFQBuilderLinksList
        links={links}
        updateLink={updateLink}
        removeLink={removeLink}
        withCaption={withCaption}
      />
    </div>
  );
};

export default FilesAndLinksList;
