import { Text } from 'src/shared/components/ui';

interface Props {
  date: Date;
}

const Timestamp = ({ date }: Props) => (
  <div className="min-w-40 flex-1">
    <Text small color="black" style={{ textAlign: 'right', fontWeight: 600 }}>
      {new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      }).format(date)}
    </Text>
  </div>
);

export default Timestamp;
