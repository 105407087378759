import React, { useEffect, useState } from 'react';

import { StorefrontRounded } from '@mui/icons-material';

import BadgeNotification from '../BadgeNotification';

interface TabsProps {
  tabs: Record<string, { count?: number; element?: React.ReactNode }>;
  active?: string;
  setActive?: (label: string) => void;
}

const Tabs: React.FC<TabsProps> = ({ tabs, active, setActive }) => {
  const [activeTab, setActiveTab] = useState(active || Object.keys(tabs)[0]);

  useEffect(() => {
    setActiveTab(active || Object.keys(tabs)[0]);
  }, [active, tabs]);

  const onClickTab = (label: string) => () =>
    setActive ? setActive(label) : setActiveTab(label);

  const { element = '' } = tabs[activeTab] || {};

  const tabButtons = Object.keys(tabs).map((label, index) => {
    const { count = 0 } = tabs[label] || {};

    const isZero = count === 0;
    const isFirstTab = index === 0;

    const iconTabStyle = !isZero
      ? {
          paddingRight: '3rem'
        }
      : {};

    return label === activeTab ? (
      <button
        key={`${index}-${label}`}
        className="flex relative font-display text-black px-4 py-3 min-w-24 max-w-[30%] overflow-hidden text-left truncate"
        onClick={onClickTab(label)}
        style={{
          borderBottom: '2px solid #000EEF',

          ...iconTabStyle
        }}
      >
        {!isFirstTab && (
          <>
            <StorefrontRounded fontSize="small" color="black" />
            &nbsp;
          </>
        )}
        {label}
        {!isZero && <BadgeNotification>{count}</BadgeNotification>}
      </button>
    ) : (
      <button
        key={`${index}-${label}`}
        className="flex relative font-display text-[#6E747D] px-4 min-w-24 max-w-[30%] overflow-hidden text-left truncate"
        onClick={onClickTab(label)}
        style={{
          borderBottom: '2px solid transparent',

          ...iconTabStyle
        }}
      >
        {!isFirstTab && (
          <>
            <StorefrontRounded fontSize="small" color="#6E747D" />
            &nbsp;
          </>
        )}
        <span className="max-w-[100%] truncate text-left ">{label}</span>
        {!isZero && <BadgeNotification>{count}</BadgeNotification>}
      </button>
    );
  });

  return (
    <>
      <div className="flex items-center justify-start gap-4 w-full max-w-[75%]">
        {tabButtons}
      </div>
      <div className="flex flex-col mt-4 w-full">{element}</div>
    </>
  );
};

export default Tabs;
