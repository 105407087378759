import { extendVariants, Table as StyledTable } from '@heroui/react';

import './style.css';

export const Table = extendVariants(StyledTable, {
  variants: {
    color: {
      primary: {
        table: 'table-auto',
        td: 'whitespace-nowrap'
      }
    }
  },
  defaultVariants: {
    color: 'primary',
    removeWrapper: 'true',
    className: 'heroui-table'
  }
});

export default Table;
