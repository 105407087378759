import { UserIcon, XMarkIcon } from '@heroicons/react/16/solid';
import { Divider } from '@heroui/react';
import { useState } from 'react';

import { format } from 'date-fns';
import { AiIcon, MatchTag, StageTag } from 'src/shared/components';

import AiCardBorder from 'src/shared/components/AiCardBorder';
import {
  ButtonV2,
  Modal,
  ModalBody,
  ModalContent,
  Tab,
  TabsV2,
  Typography
} from 'src/shared/components/ui';

import QuoteDetailTabContent from './QuoteDetailTabContent';
import SupplierTabContent from './SupplierTabContent';

interface QuoteDetailModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  selectedQuote: any;
}

const QuoteDetailModal: React.FC<QuoteDetailModalProps> = ({
  isOpen,
  onOpenChange,
  selectedQuote
}) => {
  const [selectedTab, setSelectedTab] = useState('details');

  const renderTabContent = () => {
    switch (selectedTab) {
      case 'details':
        return <QuoteDetailTabContent selectedQuote={selectedQuote} />;

      case 'supplier':
        return <SupplierTabContent selectedQuote={selectedQuote} />;

      default:
        return '';
    }
  };

  return (
    <Modal
      hideCloseButton
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      size="3xl"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalBody className="p-0 flex flex-col gap-6 pb-5">
              <div className="flex justify-between items-start p-5 pb-2">
                <div className="flex flex-col gap-2">
                  <div className="flex items-center">
                    <Typography
                      variant="h7"
                      weight="bold"
                      className="text-secondary-text"
                    >
                      Quote
                    </Typography>
                    <div className="size-5 flex items-center justify-center">
                      <div className="size-1 bg-gray-4 rounded-full" />
                    </div>
                    <Typography
                      variant="h7"
                      weight="bold"
                      className="text-secondary-text"
                    >
                      {format(selectedQuote?.uploaded, 'MMM dd, yyyy')}
                    </Typography>
                  </div>

                  <div className="flex items-center gap-2">
                    <Typography variant="h2" weight="bold">
                      {selectedQuote.type}
                    </Typography>
                    <MatchTag type={selectedQuote.match} />
                  </div>
                </div>
                <ButtonV2 onPress={onClose} isIconOnly color="tertiary">
                  <XMarkIcon className="size-4" />
                </ButtonV2>
              </div>

              <div className="flex gap-5 items-center px-5 h-[72px]">
                <div className="flex flex-col items-center justify-center flex-1">
                  <Typography
                    variant="body-1"
                    as="p"
                    className="text-secondary-text"
                    weight="medium"
                  >
                    Unit Price
                  </Typography>
                  <Typography variant="h5" as="span" weight="bold">
                    ${selectedQuote.unitCost}
                  </Typography>
                </div>
                <Divider orientation="vertical" />
                <div className="flex flex-col items-center justify-center flex-1">
                  <Typography
                    variant="body-1"
                    as="p"
                    className="text-secondary-text"
                    weight="medium"
                  >
                    Quantity
                  </Typography>
                  <Typography variant="h5" as="span" weight="bold">
                    {selectedQuote.quantity}
                  </Typography>
                </div>
                <Divider orientation="vertical" />
                <div className="flex flex-col items-center justify-center flex-1">
                  <Typography
                    variant="body-1"
                    as="p"
                    className="text-secondary-text"
                    weight="medium"
                  >
                    Supplier
                  </Typography>
                  <div className="flex items-center gap-1">
                    <UserIcon className="size-4" />
                    <Typography variant="h5" as="span" weight="bold">
                      {selectedQuote.supplier}
                    </Typography>
                  </div>
                </div>
                <Divider orientation="vertical" />
                <div className="flex flex-col items-center justify-center flex-1">
                  <Typography
                    variant="body-1"
                    as="p"
                    className="text-secondary-text"
                    weight="medium"
                  >
                    Stage
                  </Typography>
                  <StageTag type="quoting" />
                </div>
              </div>

              <div className="flex items-center justify-start gap-2 px-5">
                <ButtonV2>Buy sample</ButtonV2>
                <ButtonV2 color="secondary">Bulk order</ButtonV2>
                <ButtonV2 color="secondary">Support</ButtonV2>
              </div>

              <div className="relative mx-5">
                <div className="flex items-start gap-2 rounded-xl bg-white relative z-10 p-4">
                  <AiIcon isColored />
                  <div>
                    <Typography variant="h6" weight="bold">
                      Your material is this supplier's expertise
                    </Typography>
                    <Typography
                      variant="body-1"
                      weight="medium"
                      className="text-secondary-text"
                    >
                      This supplier is our top pick for your product.
                    </Typography>
                  </div>
                </div>
                <AiCardBorder />
              </div>

              <div>
                <div className="px-3">
                  <TabsV2
                    selectedKey={selectedTab}
                    onSelectionChange={(key) => setSelectedTab(key as string)}
                  >
                    <Tab key="details" title="Quote details" />
                    <Tab key="supplier" title="Supplier" />
                  </TabsV2>
                </div>
                <Divider className="w-full h-[1px] m-0 relative top-[-4px]" />
              </div>

              <div>{renderTabContent()}</div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default QuoteDetailModal;
