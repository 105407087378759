import { ChevronLeftIcon } from '@heroicons/react/16/solid';
import { DocumentIcon } from '@heroicons/react/24/outline';
import { Image, Spinner, Tab } from '@heroui/react';
import { useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useProductById } from 'src/features/product/product-detail/hooks/useProductById';
import ProductDetailQuotes from 'src/features/product/product-detail/ui/ProductDetailQuotes';
import ProductDetailUpdates from 'src/features/product/product-detail/ui/ProductDetailUpdates';
import getStageFromLifecycle from 'src/features/product/product-list/utils/getStageFromLifecycle';
import { StageTag } from 'src/shared/components';
import { ROUTE_PATHS } from 'src/shared/config/routes';
import { ProductById, ProductLifecycleStage } from 'src/shared/types/product';

import { ButtonV2, TabsV2, Typography } from 'src/shared/components/ui';

const ProductDetailPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { data, isLoading } = useProductById(params['id'] as string);
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = useMemo(() => {
    return searchParams.get('detailTab') || 'updates';
  }, [searchParams]);

  const renderTabContent = () => {
    switch (activeTab) {
      case 'updates':
        return <ProductDetailUpdates product={data as ProductById} />;
      case 'timeline':
        return 'Timeline';
      case 'quotes':
        return <ProductDetailQuotes />;
      case 'samples':
        return 'Samples';
      case 'orders':
        return 'Orders';

      default:
        return null;
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <div data-testid="products-display" className="h-full py-4 flex flex-col">
      <div
        aria-label="test"
        className="flex items-center justify-between mb-4 px-6"
      >
        <div className="flex items-center">
          <ButtonV2
            onPress={() => navigate(ROUTE_PATHS.PRODUCTS)}
            isIconOnly
            color="fourth"
          >
            <ChevronLeftIcon className="size-6" />
          </ButtonV2>
          <Image
            className="size-10 min-w-10 rounded-xl bg-gray-2 border border-gray-4 mr-3 ml-2"
            src={data?.image ?? '/images/no-image.svg'}
            alt={data?.detail?.name}
          />
          <Typography className="mr-2" as="h1" variant="h2" weight="bold">
            {data?.detail?.name}
          </Typography>
          <StageTag
            type={getStageFromLifecycle(
              data?.detail.lifecycle as ProductLifecycleStage[]
            )}
          />
        </div>
        <ButtonV2
          size="md"
          color="tertiary"
          startContent={<DocumentIcon className="size-4" />}
        >
          Product Spec
        </ButtonV2>
      </div>

      <div className="px-3">
        <TabsV2
          selectedKey={activeTab}
          onSelectionChange={(key) => setSearchParams({ detailTab: `${key}` })}
        >
          <Tab key="updates" title="Updates" />
          <Tab key="timeline" title="Timeline" />
          <Tab key="quotes" title="Quotes" />
          <Tab key="samples" title="Samples" />
          <Tab key="orders" title="Orders" />
        </TabsV2>
      </div>
      <div className="w-full h-[1px] bg-gray-4 relative top-[-4px]" />
      <div className="py-4 px-6 flex-grow">{renderTabContent()}</div>
    </div>
  );
};

export default ProductDetailPage;
