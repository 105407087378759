import { XMarkIcon } from '@heroicons/react/20/solid';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { LinkIcon, PaperClipIcon } from '@heroicons/react/24/solid';
import React, { useMemo } from 'react';

import { s } from 'framer-motion/dist/types.d-6pKw1mTI';
import { SupplierCloaks } from 'src/shared/types/product';

import {
  ButtonV2,
  Select,
  Textarea,
  Typography
} from 'src/shared/components/ui';

interface ProductUpdatesInputProps {
  onClose: () => void;
  suppliers: SupplierCloaks[];
}

const ProductUpdatesInput: React.FC<ProductUpdatesInputProps> = ({
  onClose,
  suppliers
}) => {
  const suppliersList = useMemo(() => {
    const parsed = suppliers.map((supplier) => ({
      key: supplier.id.toString(),
      label: `${supplier.cloak}-${supplier.id}`
    }));

    return [{ key: 'all', label: 'All suppliers' }, ...parsed];
  }, [suppliers]);

  return (
    <div className="relative">
      <Textarea
        classNames={{ inputWrapper: 'pb-12 pr-10' }}
        placeholder="Ask a question"
      />

      <div className="absolute bottom-2 left-2 right-2 flex items-center justify-between gap-2">
        <div className="flex items-center gap-2 flex-grow">
          <ButtonV2 size="md" isIconOnly color="tertiary">
            <LinkIcon className="size-4" />
          </ButtonV2>
          <ButtonV2 size="md" isIconOnly color="tertiary">
            <PaperClipIcon className="size-4" />
          </ButtonV2>
        </div>

        <div className="flex items-center gap-2 flex-1">
          <Typography
            variant="body-2"
            weight="medium"
            as="span"
            className="text-secondary-text"
          >
            About
          </Typography>
          <Select
            disallowEmptySelection
            defaultSelectedKeys={['all']}
            label=""
            size="md"
            items={suppliersList}
          />
          <ButtonV2 size="md" isIconOnly color="primary">
            <PaperAirplaneIcon className="size-4" />
          </ButtonV2>
        </div>
      </div>

      <div className="absolute top-2 right-2">
        <ButtonV2
          radius="full"
          size="sm"
          onPress={onClose}
          isIconOnly
          color="tertiary"
        >
          <XMarkIcon className="size-3" />
        </ButtonV2>
      </div>
    </div>
  );
};

export default ProductUpdatesInput;
