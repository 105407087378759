import { extendVariants, Avatar as StyledAvatar } from '@heroui/react';
import React from 'react';

const Avatar = extendVariants(StyledAvatar, {
  variants: {
    color: {
      violet: {
        base: 'bg-[#7048E8] text-white'
      },
      transparent: {
        base: 'bg-transparent text-white'
      }
    },
    size: {
      lg: {
        base: 'size-9 text-[22px] font-semibold'
      },
      md: {
        base: 'size-7 text-[16px] font-semibold'
      },
      sm: {
        base: 'w-[22px] h-[22px] text-[15px] font-semibold'
      },
      xl: {
        base: 'size-24 text-[60px] font-semibold'
      }
    }
  },
  defaultVariants: {
    color: 'violet',
    size: 'lg',
    radius: 'full'
  }
});

interface UserAvatarProps {
  size?: 'lg' | 'md' | 'sm' | undefined | 'xl';
  name: string;
  image?: string;
  radius?: 'full' | 'lg' | 'md' | 'sm' | 'none';
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  size,
  name,
  image,
  radius
}) => {
  return (
    <Avatar
      classNames={{ base: 'uppercase' }}
      size={size}
      name={name?.charAt(0) ?? ''}
      src={image}
      color={image ? 'transparent' : 'violet'}
      radius={radius}
    />
  );
};

export default UserAvatar;
