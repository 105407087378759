import {
  extendVariants,
  SelectItem,
  Select as StyledSelect
} from '@heroui/react';

interface SelectProps {
  label: string;
  items: { key: string; label: string }[];
  placeholder?: string;
  isInvalid?: boolean;
  isDisabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

const ExtendedSelect = extendVariants(StyledSelect, {
  variants: {
    color: {
      default: {
        label: '!text-secondary-text',
        trigger: [
          'shadow-none',
          '!bg-white border-1 border-gray-4',
          'hover:!bg-white',
          'focus-within:!bg-white focus-within:border-foreground',
          '!cursor-text',
          'rounded-lg',
          'font-medium'
        ]
      }
    },
    isInvalid: {
      true: {
        base: ''
      }
    },
    isDisabled: {
      true: {
        base: '!opacity-100'
      }
    },
    size: {
      md: {
        trigger: '!h-[36px] !min-h-[36px]'
      }
    }
  },
  defaultVariants: {
    color: 'default',
    textSize: 'base',
    placeholder: ' ',
    labelPlacement: 'outside',
    variant: 'bordered'
  }
});

const Select: React.FC<SelectProps | any> = ({
  label,
  items = [],
  size = 'lg',
  ...props
}) => {
  return (
    <ExtendedSelect fullWidth label={label} size={size} {...props}>
      {items.map((item: any) => (
        <SelectItem
          classNames={{ base: 'hover:!bg-gray-2 font-medium' }}
          key={item.key}
          id={item.key}
          textValue={item.label}
        >
          {item.label}
        </SelectItem>
      ))}
    </ExtendedSelect>
  );
};

export default Select;
