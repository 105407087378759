import { ChevronDownIcon } from '@heroicons/react/16/solid';
import {
  cn,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger
} from '@heroui/react';
import { useQueryClient } from '@tanstack/react-query';

import { motion } from 'framer-motion';
import { useAuthStore } from 'src/entities/user/store/useAuthStore';

import OrganizationAvatar from '../../OrganizationAvatar';
import { Typography } from '../../ui';

interface OrganizationMenuProps {
  isOpen: boolean;
  isMobile?: boolean;
}

const OrganizationMenu: React.FC<OrganizationMenuProps> = ({
  isMobile,
  isOpen = true
}) => {
  const user = useAuthStore((state) => state.user);
  const selectedOrganization = useAuthStore(
    (state) => state.selectedOrganization
  );
  const setSelectedOrganization = useAuthStore(
    (state) => state.setSelectedOrganization
  );

  const queryClient = useQueryClient();

  const renderOrganizations = () => {
    if (user?.organizations && user?.organizations?.length > 0) {
      return user?.organizations?.map((org) => (
        <DropdownItem
          onPress={() => {
            queryClient.invalidateQueries();
            setSelectedOrganization(org);
          }}
          key={org.id}
          classNames={{
            base: [org.id === selectedOrganization?.id && 'bg-gray-4']
          }}
        >
          <div className="flex gap-2 items-center">
            <OrganizationAvatar name={org.name} image={org?.avatar} />
            <Typography variant="body-1" weight="medium">
              {org.name}
            </Typography>
          </div>
        </DropdownItem>
      ));
    }

    return null;
  };

  return (
    <Dropdown>
      <DropdownTrigger>
        <div
          className={cn(
            'pb-4 flex gap-2 items-center justify-between hover:opacity-70 transition-opacity cursor-pointer',
            isMobile && 'justify-start'
          )}
        >
          <div className="flex gap-2 items-center">
            <OrganizationAvatar
              name={selectedOrganization?.name}
              image={selectedOrganization?.avatar}
            />
            <motion.div
              initial={!isOpen && { maxWidth: 0, opacity: 0 }}
              animate={{
                maxWidth: isOpen ? '100%' : 0,
                opacity: isOpen ? 1 : 0
              }}
              transition={{ duration: 0.3, ease: 'easeInOut' }}
            >
              <Typography variant="body-1" weight="bold" className="leading-4">
                {selectedOrganization?.name}
              </Typography>
            </motion.div>
          </div>
          <ChevronDownIcon className="size-4 text-secondary-text mr-3" />
        </div>
      </DropdownTrigger>
      <DropdownMenu
        aria-label="Organization List"
        classNames={{ list: 'gap-2' }}
        itemClasses={{ base: 'data-[hover]:bg-gray-4' }}
      >
        {renderOrganizations()}
      </DropdownMenu>
    </Dropdown>
  );
};

export default OrganizationMenu;
