export const columns = [
  {
    key: 'name',
    label: 'Name'
  },
  // {
  //   key: 'update',
  //   label: 'Update'
  // },
  {
    key: 'stage',
    label: 'Stage'
  },
  {
    key: 'status',
    label: 'Status'
  },

  // {
  //   key: 'active_suppliers',
  //   label: 'Suppliers'
  // },
  // {
  //   key: 'samples',
  //   label: 'Samples'
  // },
  // {
  //   key: 'orders',
  //   label: 'Orders'
  // },
  // {
  //   key: 'total_spend',
  //   label: 'Total spend'
  // },
  {
    key: 'created',
    label: 'Created'
  }
];
