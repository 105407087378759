import { UserIcon } from '@heroicons/react/16/solid';
import { Image, useDisclosure } from '@heroui/react';
import { Key, useCallback, useState } from 'react';

import { format } from 'date-fns';
import { MatchTag, QuoteStatusTag } from 'src/shared/components';

import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Typography
} from 'src/shared/components/ui';

import quotesColumns from '../constants/quotesColumns';
import sampleQuotes from '../constants/sampleQuotes';
import QuoteDetailModal from './QuoteDetailModal';

const QuotesTable = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  // look - change to proper quote type
  const [selectedQuote, setSelectedQuote] = useState<any>(null);
  const onClickRow = (quote: any) => {
    setSelectedQuote(quote);
    onOpen();
  };

  const renderCell = useCallback((item: any, columnKey: Key) => {
    switch (columnKey) {
      case 'sampleImages':
        return (
          <div className="flex items-center gap-2">
            {item.sampleImages.map((image: string, index: number) => (
              <Image
                key={index}
                className="size-12 min-w-12 rounded-xl bg-gray-2 border border-gray-4"
                src="/images/no-image.svg"
                alt="Sample image"
              />
            ))}
          </div>
        );

      case 'type':
        return (
          <Typography variant="body-1" weight="medium">
            {item.type}
          </Typography>
        );

      case 'unitCost':
        return (
          <Typography variant="body-1" weight="medium">
            ${item.unitCost}
          </Typography>
        );

      case 'quantity':
        return (
          <Typography variant="body-1" weight="medium">
            {item.quantity}
          </Typography>
        );

      case 'supplier':
        return (
          <Typography
            variant="body-1"
            weight="medium"
            className="flex items-center"
          >
            <UserIcon className="size-4 mr-1" /> {item.supplier}
          </Typography>
        );

      case 'match':
        return <MatchTag type={item.match} />;

      case 'status':
        return <QuoteStatusTag type={item.status} />;

      case 'uploaded':
        return (
          <Typography variant="body-1" weight="medium">
            {format(item.uploaded, 'MMM dd, yyyy')}
          </Typography>
        );

      default:
        return '';
    }
  }, []);

  return (
    <div className="flex flex-col">
      <Table aria-label="Quote table">
        <TableHeader columns={quotesColumns}>
          {(column) => (
            <TableColumn key={column.key}>{column.label}</TableColumn>
          )}
        </TableHeader>
        <TableBody items={sampleQuotes ?? []}>
          {(item) => (
            <TableRow onClick={() => onClickRow(item)} key={item.id}>
              {(columnKey) => (
                <TableCell>{renderCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>

      <QuoteDetailModal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        selectedQuote={selectedQuote}
      />
    </div>
  );
};

export default QuotesTable;
