import apiService from 'src/shared/config/apiService';
import handleAxiosError from 'src/shared/utils/handleAxiosError';

import { RFQ } from 'src/components/RfqDisplay/props';

export const getProductDrafts = async () => {
  try {
    const response = await apiService.get<RFQ[]>('/rfqs?status=draft');

    return response;
  } catch (error) {
    throw handleAxiosError(error);
  }
};
