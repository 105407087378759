import { XMarkIcon } from '@heroicons/react/16/solid';
import { useState } from 'react';

import ButtonV2 from '../ui/Button/v2';

interface ImageAttachmentProps {
  image: string;
  onRemove: () => void;
}

const ImageAttachment: React.FC<ImageAttachmentProps> = ({
  image,
  onRemove
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative size-12 cursor-pointer"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ButtonV2
        size="xl"
        color="tertiary"
        className="!size-12 !max-w-12 !p-0 !bg-transparent flex items-center justify-center bg-cover bg-center"
        style={{
          backgroundImage: `url(${image})`
        }}
      />

      {isHovered && (
        <ButtonV2
          onPress={onRemove}
          size="xs"
          className="bg-black absolute -right-2 -top-2 w-5"
          isIconOnly
        >
          <XMarkIcon className="size-4 text-white" />
        </ButtonV2>
      )}
    </div>
  );
};

export default ImageAttachment;
