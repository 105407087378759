import { ChevronDownIcon } from '@heroicons/react/16/solid';
import { ArrowLeftStartOnRectangleIcon } from '@heroicons/react/20/solid';
import {
  cn,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger
} from '@heroui/react';
import { useNavigate } from 'react-router-dom';

import { motion } from 'framer-motion';
import { useAuthStore } from 'src/entities/user/store/useAuthStore';

import { Typography } from '../../ui';
import UserAvatar from '../../UserAvatar';

interface UserMenuProps {
  isOpen: boolean;
  isMobile?: boolean;
}

const UserMenu: React.FC<UserMenuProps> = ({ isOpen = true, isMobile }) => {
  const user = useAuthStore((state) => state.user);
  const logout = useAuthStore((state) => state.logout);
  const navigate = useNavigate();

  const onLogout = async () => {
    logout();
    navigate('/v2/login');
  };

  return (
    <Dropdown>
      <DropdownTrigger>
        <div
          className={cn(
            'p-4 flex gap-2 items-center justify-between hover:opacity-70 transition-opacity cursor-pointer',
            isMobile && 'p-0 justify-end'
          )}
        >
          <div className="flex gap-2 items-center">
            <UserAvatar
              size="lg"
              name={(user?.username || user?.firstName) as string}
              image={user?.avatar}
            />
            {!isMobile && (
              <motion.div
                initial={!isOpen && { maxWidth: 0, opacity: 0 }}
                animate={{
                  maxWidth: isOpen ? '100%' : 0,
                  opacity: isOpen ? 1 : 0
                }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
              >
                <Typography variant="body-1" weight="medium">
                  {user?.username || user?.firstName}
                </Typography>
              </motion.div>
            )}
          </div>
          {!isMobile && (
            <ChevronDownIcon className="size-4 text-secondary-text" />
          )}
        </div>
      </DropdownTrigger>
      <DropdownMenu
        itemClasses={{ base: 'data-[hover]:bg-gray-4' }}
        aria-label="User Actions"
      >
        <DropdownSection showDivider>
          <DropdownItem className="pointer-events-none" key="copy">
            {user?.email}
          </DropdownItem>
        </DropdownSection>
        <DropdownItem
          startContent={<ArrowLeftStartOnRectangleIcon className="size-4" />}
          onPress={onLogout}
          key="logout"
        >
          Logout
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default UserMenu;
