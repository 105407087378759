import { Organization, User } from 'src/shared/types/user';

function normalizeUserData(data: any): User {
  const uniqueOrganizations =
    data?.organizations?.reduce((acc: Organization[], org: Organization) => {
      const existingOrgIndex = acc.findIndex((o) => o.id === org.id);
      if (existingOrgIndex === -1) {
        // Organization not found, add it
        acc.push(org);
      } else if (
        org.role === 'admin' &&
        acc[existingOrgIndex].role === 'member'
      ) {
        // Replace member role with admin role
        acc[existingOrgIndex] = org;
      }
      return acc;
    }, []) || [];

  // Sort organizations by name
  uniqueOrganizations.sort((a: Organization, b: Organization) =>
    a.name.localeCompare(b.name)
  );

  return {
    id: data.id,
    email: data.email,
    emailNotifications: data.email_notifications,
    username: data.username,
    firstName: data.first_name,
    lastName: data.last_name,
    featureFlags: data.featureflags,
    impersonating: data.impersonating,
    settings: data.settings || {},
    isStaff: data.is_staff || false,
    language: data?.language || 'en',
    company_name: data?.company_name || '',
    addresses: data?.addresses || [],
    avatar: data?.avatar || '',
    organizations: uniqueOrganizations
  };
}

export default normalizeUserData;
