import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { API_URL } from 'src/settings';
import { fetchWithAuth } from 'src/utils/fetchWithAuth';

const create_rfq_url = `${API_URL}/api/products/`;
const update_rfq_status_url = `${API_URL}/api/rfqs/`;

const fixed_document_keys = [
  'number_of_units_per_variant',
  'colors',
  'materials',
  'sizes',
  'technical_details',
  'extra_information'
];

const useLogic = ({
  rfqId,
  rfqName,
  setError,
  setShowFinalScreen,
  setProductId
}: {
  rfqId: number;
  rfqName: string;
  setError: (error: string) => void;
  setShowFinalScreen: (showFinalScreen: boolean) => void;
  setProductId: (productId: string) => void;
}) => {
  const navigate = useNavigate();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(rfqName);
  const [isCreating, setIsCreating] = useState(false);
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);

  const createRfq = async () => {
    setIsCreating(true);
    const response = await fetchWithAuth(create_rfq_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        rfq: rfqId,
        name: rfqName
      })
    });

    if (response.ok === false) {
      setError('Error creating RFQ');
      return;
    }

    const data = await response.json();
    setProductId(data.id);
    await updateRfqStatus(rfqId);
  };

  const updateRfqStatus = async (rfqId: number) => {
    const response = await fetchWithAuth(`${update_rfq_status_url}${rfqId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        status: 'published'
      })
    });

    if (response.ok === false) {
      setError('Error updating RFQ status');
      return;
    }

    setShowFinalScreen(true);
  };

  const fillFixedDocumentKeys = (document: any) => {
    const orderedDocument: any = {};

    fixed_document_keys.forEach((key) => {
      orderedDocument[key] = document[key];
    });

    return orderedDocument;
  };

  const getRfqFiles = (attrFiles: any, files: any, key: string) => {
    const fileIds = attrFiles[key];
    if (!fileIds) return [];

    const rfqFiles = files
      .filter((file: any) => fileIds.includes(file.id))
      .map((f: any) => {
        const fileElement: File = new File([], f.name);

        return {
          file: fileElement,
          id: f.id,
          caption: f.description,
          url: f.url
        };
      });

    return rfqFiles;
  };

  const saveNewName = async () => {
    await fetchWithAuth(`${update_rfq_status_url}${rfqId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        document: {
          name: newName
        }
      })
    });
  };

  useEffect(() => {
    setNewName(rfqName);
  }, [rfqName]);

  return {
    createRfq,
    fixed_document_keys,
    showConfirmModal,
    setShowConfirmModal,
    fillFixedDocumentKeys,
    getRfqFiles,
    isHovered,
    setIsHovered,
    isEditing,
    setIsEditing,
    newName,
    setNewName,
    saveNewName,
    navigate,
    isCreating,
    isGeneratingImage,
    setIsGeneratingImage
  };
};

export default useLogic;
