import { useMutation } from '@tanstack/react-query';

import { getUserToken, GetUserTokenPayload } from '../api/getUserToken';

export const useLogin = () => {
  return useMutation({
    mutationFn: async ({ email }: GetUserTokenPayload) => {
      return await getUserToken({ email });
    }
  });
};
