import { ReactNode, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import AuthContext from 'src/context/Auth';

import useFeatureFlag from '../hooks/useFeatureFlag';

/* This component is used to protect routes that require the user to be logged in. It redirects the user to the home */
export default function ProtectedRoute({ children }: { children: ReactNode }) {
  const { isLoggedIn, isLoading, userInfo, error } = useContext(AuthContext);

  const isReadOnly = userInfo?.settings?.read_only || false;
  const { isFeatureEnabled, isLoading: featureFlagLoading } = useFeatureFlag(
    'redirect_to_new_flow'
  );
  let location = useLocation();
  const isFirstProductCreated = userInfo?.settings?.first_product_created;

  if (!isLoggedIn && !isLoading) {
    if (error === 'expiredCredentials') {
      return <Navigate to="/expired" state={{ from: location }} replace />;
    }

    if (error === 'noAuthAvailable') {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Redirect user to new-product when they first log in
  if (
    isFeatureEnabled &&
    !featureFlagLoading &&
    location.pathname === '/' &&
    !isReadOnly &&
    !isLoading
  ) {
    if (isFirstProductCreated !== true) {
      return <Navigate to="/new-product" state={{ from: location }} replace />;
    }
  }
  return <>{children}</>;
}
